// ##### 24-06-19 Komarov
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import parse from "html-react-parser";
import uniqueId from "lodash.uniqueid";
import { CartGallery } from "../../../Library/PageDevices/CartGallery/CartGallery.js";
import { fetchGallery } from "../../../../Redux/actions/index.js";

export const ShowGallery = ({ innerText, id, componentKey }) => {
  // console.log('AAA', id)
  const lang = useSelector((state) => state.language);
  // ##### 23-07-28 Komarov
  const deviceType = useSelector((state) => state.deviceType);

  const [clicked, setClicked] = useState(false);
  const [innerPage, setInnerPage] = useState(undefined);
  
  const dispatch = useDispatch();
  const gallery = useSelector(state => state.gallery);
  const { items } = gallery;
  // const items = useSelector(state => state.gallery)?.[id];
  useEffect(() => {
    dispatch(fetchGallery(id, lang, deviceType));
  }, [dispatch, id, lang, deviceType]);

  // ##### 24-09-12 Komarov: поменял в массиве зависимостей id на items, так как id здесь не изменяется (он один для экземпляра компонента Галереи), а items обновляются с момента загрузки этого компонента на странице в браузере
  useEffect(() => {
    setInnerPage(items);
  }, [items]);

  const MakeVisible = () => {
    setClicked(!clicked);
  };

  // ##### 23-11-09 Komarov
  return (
    <React.Fragment key={componentKey}>
      <h3
        data-component="ShowGallery"
        className={clicked === false ? "inner" : "innerClicked"}
        onClick={MakeVisible}
        // ##### 24-03-21 Komarov
        onKeyDown={(e) => {
          if (e.key === "Enter" || e.key === " ") {
            MakeVisible();
          }
        }}
      >
        {innerText}
      </h3>

      <div className={clicked === false ? "galleryNotActive" : "galleryActive"}>
        {
          // ##### 24-03-21 Komarov
          innerPage?.[id]?.map((page) => {
            if (page.content_name === "Image") {
              return (
                <CartGallery
                  photos={page}
                  photoHeight={"65vh"}
                  key={uniqueId()} />
              );
            }

            if (page.content_name === "Body") {
              // ##### 23-07-19 Komarov
              return (
                // ##### 23-04-12 Komarov
                <div
                  key={uniqueId()}
                  style={{ overflow: "hidden", order: "1" }}
                >
                  {parse(page.text)}
                </div>
              );
            }
          })}
      </div>
    </React.Fragment>
  );
};
