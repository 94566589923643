// ##### 23-12-27 Komarov
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import uniqueId from "lodash.uniqueid";
// ##### 23-09-14 Komarov
// import '../../PageComponents/ResponsiveHeader/header.css'
// import './TopToursCSS.css'
import Slider from "../../../Library/Slider/Slider";
import { Search } from "../../FirstPageofSearchModule/SearchResizersAndSwitchers/SearchFront";
import { getPurePage } from "../../../../Redux/actions/pages";
// ##### 24-06-14 Komarov
import { TopTours } from "./TopTours/TopTours";
import { TopHotels } from "./TopHotels/TopHotels";
import { BodyPage } from "./BodyPage";
// ##### 24-04-23 Komarov
import { useMessages } from "../../../../helpers/messages";

//create your forceUpdate hook
/* function useForceUpdate(){
  // console.log('src/Components/Pages/PageComponents/HomePage.js - slider updated');
  const [value, setValue] = useState(0); // integer state
  return () => setValue(value => value + 1); // update the state to force render
} */

export const HomePage = () => {
  // ##### 24-06-27 Komarov
  /* useEffect(() => {
    window.scrollTo(0, 0);
  }, []); */
  // ##### 23-06-19 Komarov
  // SetAppData('purePagesSsr');
  /* const forceUpdate = useForceUpdate();
    const [render, rerender] = useState(false); */
  // ##### 23-12-27 Komarov
  const locale = useSelector((state) => state.language);

  // ##### 23-10-17 Komarov
  // const promoCode = useSelector(state => state.promocode.promocode)
  const deviceType = useSelector((state) => state.deviceType);
  // ##### 23-02-21 Komarov: Оказывается, чтобы отображались ТопТуры, состояние toggler_type нужно брать именно из state
  // ##### 24-09-10 Komarov
  // const toggler_type = useSelector((state) => state.tophotels.toggler_type);

  const dispatch = useDispatch();
  const purePagesSsr = useSelector((state) => state.pages.purePagesSsr);

  // 199 - id SitePage, которая относится к Слайдеру
  // $$$$$ 22-09-26 Komarov: Исправление отсутствующей Главной страницы при переключении на неё с другой
  useEffect(() => {
    /* if (purePagesSsr === undefined) {
        dispatch (getPurePage (199,locale,deviceType));
      } */
    if (purePagesSsr) {
      return;
    }
    // ##### 24-03-21 Komarov
    process.env.NODE_ENV === "development" &&
      console.log(
        "axios:src/Components/Pages/PageComponents/HomePage/HomePage.js:getPurePage(199,locale,deviceType)"
      );
    // ##### 23-08-18 Komarov
    dispatch(
      getPurePage({
        id: 199,
        lang: locale,
        deviceType,
      })
    );
    // ##### 23-04-12 Komarov
  }, [purePagesSsr, dispatch, locale, deviceType]);

  // parseInt(sitepage_region_id) === 13 - HomePage, а для sitepage_id = 199 sitepage_region_id = 5 - Body
  // Данный фильтр на 22-08-19 выдаёт полезные sitepage_id 209, 210
  // ##### 24-04-23 Komarov
  // const body_messages = useMessages.filter((item)=>parseInt(item.sitepage_region_id) === 13)
  // ##### 23-10-04 Komarov
  const body_messages = Object.values(useMessages()).filter(
    (item) => parseInt(item.sitepage_region_id) === 13
  );

  // ##### 24-03-21 Komarov
  // let purePage;
  // ##### 24-07-25 Komarov
  /* const purePage = (purePagesSsr && purePagesSsr[199][locale]) || localStorage.getItem("purePagesSsr")
      ? JSON.parse(localStorage.getItem("purePagesSsr"))[199]
      : // ##### 24-03-21 Komarov
      JSON.parse(localStorage.getItem("pages")).purePagesSsr[199]; */
  const purePage = purePagesSsr[199][locale];

  return (
    <div
      style={{ display: "flex", flexDirection: "column" }}
      data-component="HomePage"
    >
      {/* ##### 22-11-19 Komarov - start */}
      <>
        {purePage
          ? purePage.map((item) => {
            if (item.content_name === "Image") {
              return (
                <Slider
                  style={{ order: "1" }}
                  slides={item.text}
                  key={() => uniqueId()}
                />
              );
            }
          }) /* :
                  Array.from(localStorage.getItem('pages')['purePagesSsr']).length > 0 ? JSON.parse(localStorage.getItem('pages'))['purePagesSsr'][199].map((item) => {
                    if (item.content_name === 'Image') {
                      return (
                        <Slider style={{ order: '1' }} slides={item.text} key={() => uniqueId()} />
                      )
                    }
                  }
                  ) */
          : JSON.parse(localStorage.getItem("purePagesSsr"))[199].map(
            (item) => {
              if (item.content_name === "Image") {
                return (
                  <Slider
                    style={{ order: "1" }}
                    slides={item.text}
                    key={() => uniqueId()}
                  />
                );
              }
            }
          )}
      </>
      {/* ##### 22-11-19 Komarov - end */}
      <Search style={{ order: "2" }} />
      {body_messages.length > 0
        ? body_messages.map((item) => {
          return (
            <BodyPage
              className="BodyPageClass"
              sidePageID={item.id}
              sitePageTypeID={item.sitepage_type_id}
              sitePageName={item.name}
              key={uniqueId()}
            />
          );
        })
        : null}
        {/* ##### 24-09-10 Komarov */}
      {/* {toggler_type === "TOURS" ? <TopTours /> : <TopHotels />} */}
      <TopHotels />
    </div>
  );
};
