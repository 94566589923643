import React, { useEffect } from "react";
// ##### 24-07-22 Komarov
import { useSelector, useDispatch, connect } from "react-redux";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import cards from "../../Library/Images/visamastercard.jpg";
// ##### 23-09-14 Komarov
// import './footer.css'
import uniqueId from "lodash.uniqueid";
// ##### 24-04-23 Komarov
import { useMessages } from "../../../helpers/messages";
// ##### 24-03-06 Komarov
import parse from "html-react-parser";
import { setSitePageListSection } from "../../../Redux/actions";
// ##### 24-07-22 Komarov
import { makeArray } from "../../../../server/helpers/isArrayObject";
import { LocalizationNavLink } from "../../Library/Localization/LocalizationNavLink";
import { makeParamsString } from "../Helpers/helper";
import moment from "moment";
import dateFormats from "../../../helpers/dateFormats";
import { setOthersName } from "../../../Redux/actions/setOthersName";
// ##### 24-09-05 Komarov
import { getCitiesOverview, getOccupiedCities, getHotelsOverview } from "../../../Redux/actions";

// ##### 24-07-31 Komarov
function mapStateToProps(state) {
  return { 
    cities_overview_top: state.cities.cities_overview_top, 
    // ##### 24-09-05 Komarov
    occupied_cities_top: state.cities.occupied_cities_top, 
    // ##### 24-08-16 Komarov
    attractions_top: state.cities.attractions_top, 
    hotels_overview_top: state.hotels.hotels_overview_top, 
  };
}

// ##### 24-09-05 Komarov
export const Footer = connect(mapStateToProps)(({ cities_overview_top, occupied_cities_top, attractions_top, hotels_overview_top }) => {
  // ##### 23-12-27 Komarov
  const locale = useSelector((state) => state.language);
  // ##### 24-09-05 Komarov
  const isWhiteLabelSet = useSelector((state) => state.whitelabel.set);
  // ##### 24-07-22 Komarov
  const companyCipher = useSelector((state) => state.whitelabel.company_cipher);
  // ##### 24-03-14 Komarov
  const isWhiteLabelDemo = useSelector((state) => state.whitelabel.isDemo);
  // ##### 24-04-23 Komarov
  const WeAccept = useMessages("WeAccept");
  const Developer = parse(useMessages("Developer"));
  // ##### 24-04-23 Komarov
  const DemoPayment = parse(useMessages("DemoPayment"));

  // ##### 24-07-22 Komarov
  const sitePages = Object.values(useMessages());

  const dispatch = useDispatch();

  // ##### 24-07-22 Komarov
  const footerSectionsList = sitePages.filter((item) => {
    if (item.title !== null) {
      // if (item.id > 222 && item.id < 231) // Old condition for choosing the names of Footer Sections through ID
      // ##### 24-03-07 Komarov
      if (!isWhiteLabelSet) {
        if (
          item.sitepage_region_name.includes("Footer") &&
          (item.name === item.sitepage_type_name ||
          item.sitepage_type_name === "For_VIEW" ||
          item.sitepage_type_name === "Occupied")
        ) {
          return item.title;
        }
      } else {
        if (
          item.sitepage_region_name.includes("Footer") &&
          item.name === item.sitepage_type_name &&
          [
            "BEACH HOTELS",
            "WEEKEND BREAK HOTELS",
            "HOTELS IN INDUSTRIAL HUBS",
          ].indexOf(item.sitepage_type_name) === -1
        ) {
          return item.title;
        }
      }
    }
  })
  // ##### 24-08-06 Komarov
    .sort((a, b) => a.s_order - b.s_order)
    .map((item2) => {
      return {
        // ##### 24-03-21 Komarov
        title: item2.title,
        name: item2.name,
        sitepage_type_id: item2.sitepage_type_id,
        sitepage_type_name: item2.sitepage_type_name,
        sitepage_region_id: item2.sitepage_region_id,
        sitepage_region_name: item2.sitepage_region_name,
      };
    });
  // ##### 24-07-22 Komarov
  // console.log("footerSectionsList @==@ ", footerSectionsList);
  // %%%%% Krat 23-06-02 End

  // %%%%% Krat-Komarov 23-08-11 Begin
  // ##### 24-03-04 Komarov: Исключаем страницы "ATTRACTIONS" и "CITIES"
  // ##### 24-07-22 Komarov
  const sitePageListSection = sitePages.filter((item) => {
    // ##### 24-09-05 Komarov
    if (
      item.title !== null &&
      ["ATTRACTIONS", "CITIES Overview", "Occupied CITIES", "HOTELS Overview"].indexOf(item.sitepage_type_name) === -1
    ) {
      if (
        item.sitepage_region_name.includes("Footer") &&
        item.name !== item.sitepage_type_name
      ) {
        if (!isWhiteLabelSet) {
          return item.title;
        }
        // ##### 24-03-21 Komarov
        if (
          ["Terms and Conditions", "Privacy Policy"].indexOf(item.name) !== -1
        ) {
          return item.title;
        }
      }
    }
  })
    .map((itemList) => {
      return {
        // ##### 24-03-21 Komarov
        id: itemList.id,
        title: itemList.title,
        name: itemList.name,
        sitepage_type_id: itemList.sitepage_type_id,
        sitepage_type_name: itemList.sitepage_type_name,
        urlPart: `/sitepages_hotels/?name=${itemList.name
          .replace(/\s/g, "-")
          .toLowerCase()}&id=${itemList.id}`,
      };
    });

  useEffect(() => {
    dispatch(setSitePageListSection(sitePageListSection));
  }, []);

  // ##### 24-07-25 Komarov
  // const sitepagecity = useSelector(state => state.cities.sitepagecity);
  const cities = useSelector(state => state.cities.cities);

  // ##### 24-07-22 Komarov
  const sitePageRegionsList = sitePages.filter((item, index, array) => {
    if (item.sitepage_region_id !== null) {
      return (
        array.findIndex(
          (t) =>
            parseInt(t.sitepage_region_id) ===
            parseInt(item.sitepage_region_id)
        ) === index
      );
    }
  })
    .map((item1) => {
      return {
        // ##### 24-03-21 Komarov
        sitepage_region_id: item1.sitepage_region_id,
        sitepage_region_name: item1.sitepage_region_name,
      };
    });

  // ##### 24-07-22 Komarov
  const localeUpper = locale.toUpperCase();

  const hotelsTransFromState = useSelector((state) => state.hotels.hotelstrans);

  const generalHotelsFromState = useSelector(
    (state) => state.hotels.general_hotels
  );

  const general_smart_hotels = makeArray(generalHotelsFromState);

  const smart_translated_hotels = makeArray(hotelsTransFromState[locale]).sort((a, b) => a.city - b.city);

  const footerColumns = {};

  footerSectionsList?.map((footerSection) => {
    return (
      sitePageRegionsList?.map((sitePageRegion) => {
        if (parseInt(footerSection.sitepage_region_id) ===
          parseInt(sitePageRegion.sitepage_region_id) &&
          sitePageRegion.sitepage_region_name.includes("Footer") &&
          (isWhiteLabelSet && footerSection.name !== "CITY HOTELS" // В режиме While Label исключаем секцию "CITY HOTELS"
            ||
            true) && !footerColumns[footerSection.name]) {
          let itemList = [];
          if (footerSection.name === 'CITY HOTELS') {
            itemList = sitePages.filter(sitePage => {
              const title = cities.find(item => item.name === sitePage.name.split(' ', 1)[0])?.title.find(item => item.language_name === localeUpper).text;

              // ##### 24-06-19 Komarov: Если такого title нет (он undefined), то не отображаем ссылку на соотв. страницу (например, Гостиницы Ужгорода)
              if (!title) return false;
              const filteredHotels = smart_translated_hotels.filter(
                (item) => item.name === title
              );

              const filtered_hotelcity_id =
                general_smart_hotels.find((item) => {
                  if (parseInt(item.hotel_id) === parseInt(filteredHotels[0]?.id)) {
                    return parseInt(item.city_id);
                  }
                  return parseInt(item.city_id) === parseInt(filteredHotels[0]?.id);
                })?.city_id;

              // ##### 24-06-28 Komarov: Если такого city_id нет (он undefined), то не отображаем ссылку на соотв. страницу (например, Гостиницы Винницы)
              if (!filtered_hotelcity_id) return;

              /* return (sitePage.sitepage_type_name === footerSection.name &&
                sitePage.name !== footerSection.name &&
                // Оставляем в массиве только те города, которые есть в массиве cities
                !!cities.find(city => city.name === sitePage.name.split(' ', 1)[0])) */
              return (sitePage.sitepage_type_name === footerSection.name &&
                sitePage.name !== footerSection.name &&
                // Оставляем в массиве только те города, которые есть в массиве cities
                !!cities.find(city => city.name === sitePage.name.split(' ', 1)[0]))
            });
          } else if (footerSection.name === 'CITIES Overview') {
            itemList = cities_overview_top[0].data.object_list;
          } 
          // ##### 24-09-05 Komarov
          else if (footerSection.name === 'Occupied CITIES') {
            itemList = occupied_cities_top[0].data.object_list;
          } else if (footerSection.name === 'HOTELS Overview') {
            itemList = hotels_overview_top[0].data.object_list;
          } else if (footerSection.name === 'ATTRACTIONS') {
            // ##### 24-08-16 Komarov
            // itemList = sitePageCityListSection.filter(sitePageCity => sitePageCity.sitepage_type_name === footerSection.name && sitePageCity.name !== footerSection.name);
            itemList = attractions_top[0].data.object_list;
          } else { // когда footerSection.name !== 'CITY HOTELS' && footerSection.name !== 'CITIES' && footerSection.name !== 'ATTRACTIONS'
            itemList = sitePages.filter(sitePage => sitePage.sitepage_type_name === footerSection.name && sitePage.name !== footerSection.name);
          }
          footerColumns[footerSection.name] = {
            title: footerSection.title,
            itemList
          };
        } else return false;
      })
    )
  });
  
  // ##### 24-08-05 Komarov
  const company_id = useSelector(state => state.auth.user.company_id);

  useEffect(() => {
    const params = {
      user_id: currentUser.user_id,
      // ##### 24-08-05 Komarov
      refpartner: company_id,
      language: locale,
      primary_objects_list: 1,
      section_name: 'CITIES Overview'
    };
    dispatch(getCitiesOverview(params));
    // ##### 24-07-31 Komarov
    // dispatch(setOthersName(footerColumns[params.section_name].title));
  }, [locale]);

  // ##### 24-09-05 Komarov
  useEffect(() => {
    const params = {
      user_id: currentUser.user_id,
      refpartner: company_id,
      language: locale,
      primary_objects_list: 1,
      section_name: 'Occupied CITIES'
    };
    dispatch(getOccupiedCities(params));
  }, [locale]);

  useEffect(() => {
    const params = {
      user_id: currentUser.user_id,
      // ##### 24-08-05 Komarov
      refpartner: company_id,
      language: locale,
      primary_objects_list: 1,
      section_name: 'HOTELS Overview'
    };
    dispatch(getHotelsOverview(params));
  }, [locale]);

  // ##### 24-07-24 Komarov
  const countryLocalized = useSelector(state => state.country)[locale];

  const { user: currentUser } = useSelector((state) => state.auth);
  // const onOthersClick = (othersName) => dispatch(setOthersName(othersName));
  // ##### 24-09-05 Komarov
  const onOthersClick = ({ /* footerColumnName,  */othersTitle }) => {
    // Если othersSectionName === 'CITIES', то в section_name записываем 'CITIES Overview'
    // const section_name = footerColumnName === 'CITIES' && 'CITIES Overview';
    const params = {
      user_id: currentUser.user_id,
      // ##### 24-08-05 Komarov
      refpartner: company_id,
      language: locale,
      primary_objects_list: 0,
      // section_name
    };
    dispatch(getCitiesOverview(params));
    dispatch(setOthersName(othersTitle));
  };

  const PagesLinks = () => {

    // ##### 24-07-25 Komarov
    const pickedcurrencyFromState = useSelector(state => state.currency.pickedcurrency);
    // ##### 24-09-05 Komarov
    // const start = moment().add(1, 'days').format(dateFormats.en);
    // const end = moment().add(2, 'days').format(dateFormats.en);

    return <>
      {
        Object.keys(footerColumns)?.map((footerColumnName) => {
          const columnTitle = footerColumns[footerColumnName].title || footerColumns[footerColumnName].langname;
          const columnPages = footerColumns[footerColumnName].itemList || footerColumns[footerColumnName].object_ist;
          switch (footerColumnName) {
            case 'INFORMATION':
              return (
                <Col lg={true} key={uniqueId()}>
                  <div className="footerLinks">
                    <h3>
                      {columnTitle}
                    </h3>
                    <ul>
                      {columnPages.map(page => <li key={uniqueId()}>
                        <h2>
                          <LocalizationNavLink
                            to={
                              (isWhiteLabelSet &&
                                `/ref${companyCipher}/sitepages/?name=${page.name
                                  .replace(/\s/g, "-")
                                  .toLowerCase()}&id=${page.id}`) ||
                              `/sitepages/?name=${page.name
                                .replace(/\s/g, "-")
                                .toLowerCase()}&id=${page.id}`
                            }
                          >
                            {page.title}
                          </LocalizationNavLink>
                        </h2>
                      </li>)}
                    </ul>
                  </div>
                </Col>
              );

            case 'CITY HOTELS':
              return (
                <Col lg={true} key={uniqueId()}>
                  <div className="footerLinks">
                    <h3>
                      {columnTitle}
                    </h3>
                    <ul>
                      {columnPages.map(page => {
                        const title = cities.find(item => item.name === page.name.split(' ', 1)[0])?.title.find(item => item.language_name === localeUpper).text;
                        const filteredHotels = smart_translated_hotels.filter(
                          (item) => item.name === title
                        );
                        const filtered_hotelcity_id =
                          general_smart_hotels.find((item) => {
                            if (parseInt(item.hotel_id) === parseInt(filteredHotels[0]?.id)) {
                              return parseInt(item.city_id);
                            }
                            return parseInt(item.city_id) === parseInt(filteredHotels[0]?.id);
                          })?.city_id;
                        const params_string = makeParamsString({
                          selected_currency:
                            localStorage.getItem("current_currency") || pickedcurrencyFromState,
                          title,
                          start: moment().add(1, 'days').format(dateFormats.en),
                          end: moment().add(2, 'days').format(dateFormats.en),
                          id: filtered_hotelcity_id,
                          city_id: filtered_hotelcity_id,
                          adults: 1,
                          children: 0,
                          rooms: 1,
                        });
                        return <li key={uniqueId()}>
                          <h2>
                            <LocalizationNavLink
                              to={(isWhiteLabelSet && `/ref${companyCipher}/search_results_hotels/?${params_string}`) || (`/search_results_hotels/?${params_string}`)}
                            >
                              {page.title}
                            </LocalizationNavLink>
                          </h2>
                        </li>
                      })}
                    </ul>
                  </div>
                </Col>
              );

            case 'ATTRACTIONS':
              return (
                <Col lg={true} key={uniqueId()}>
                  <div className="footerLinks">
                    <h3>
                      {columnTitle}
                    </h3>
                    <ul>
                      {columnPages.map(page =>
                        <li key={uniqueId()}>
                          <h2>
                            {/* ##### 24-08-16 Komarov */}
                            <LocalizationNavLink
                              to={`/city_overview/?name=${page.object_name
                                .replace(/\s/g, "-")
                                .toLowerCase()}&id=${page.sitepageobject_id}&sptid=${page.type_id
                                }`}
                            >
                              {/* ##### 24-09-11 Komarov */}
                              {/* {page.object_langname}, {countryLocalized} */}
                              {page.object_langname}{page.object_name !== "Others" && `, ${countryLocalized}`}
                            </LocalizationNavLink>
                          </h2>
                        </li>)}
                    </ul>
                  </div>
                </Col>
              );

            case 'CITIES Overview':
              return (
                <Col lg={true} key={uniqueId()}>
                  <div className="footerLinks">
                    <h3>
                      {columnTitle}
                    </h3>
                    <ul>
                      {columnPages.map(page => {
                        return <li key={uniqueId()}>
                          <h2>
                            <LocalizationNavLink
                              to={`/city_overview/?name=${page.object_name.toLowerCase()
                                .replace(/\s/g, "-")
                                .toLowerCase()}&id=${page.sitepageobject_id}&sptid=${page.type_id
                                }`}
                              onClick={() => (page.object_name === "Others" && onOthersClick({ footerColumnName, othersTitle: footerColumns[footerColumnName].title })) || null}
                            >
                              {/* ##### 24-09-11 Komarov */}
                              {/* {page.object_langname}, {countryLocalized} */}
                              {page.object_langname}{page.object_name !== "Others" && `, ${countryLocalized}`}
                            </LocalizationNavLink>
                          </h2>
                        </li>
                      })}
                    </ul>
                  </div>
                </Col>
              );

            // ##### 24-09-05 Komarov
            case 'Occupied CITIES':
              return (
                <Col lg={true} key={uniqueId()}>
                  <div className="footerLinks">
                    <h3>
                      {columnTitle}
                    </h3>
                    <ul>
                      {columnPages.map(page => {
                        return <li key={uniqueId()}>
                          <h2>
                            <LocalizationNavLink
                              to={`/city_overview/?name=${page.object_name.toLowerCase()
                                .replace(/\s/g, "-")
                                .toLowerCase()}&id=${page.sitepageobject_id}&sptid=${page.type_id
                                }`}
                              onClick={() => (page.object_name === "Others" && onOthersClick({ footerColumnName, othersTitle: footerColumns[footerColumnName].title })) || null}
                            >
                              {/* ##### 24-09-11 Komarov */}
                              {/* {page.object_langname}, {countryLocalized} */}
                              {page.object_langname}{page.object_name !== "Others" && `, ${countryLocalized}`}
                            </LocalizationNavLink>
                          </h2>
                        </li>
                      })}
                    </ul>
                  </div>
                </Col>
              );

            // ##### 24-07-31 Komarov
            case 'HOTELS Overview':
              return (
                <Col lg={true} key={uniqueId()}>
                  <div className="footerLinks">
                    <h3>
                      {columnTitle}
                    </h3>
                    <ul>
                      {columnPages.map(page => {
                        return <li key={uniqueId()}>
                          <h2>
                            {/* ##### 24-08-05 Komarov */}
                            {/* <LocalizationNavLink
                              to={`/city_overview/?name=${page.object_name.toLowerCase()
                                .replace(/\s/g, "-")
                                .toLowerCase()}&id=${page.sitepageobject_id}&sptid=${page.type_id
                                }`}
                              onClick={() => (page.object_name === "Others" && onOthersClick({ footerColumnName, othersTitle: footerColumns[footerColumnName].title })) || null}
                            >
                              {page.object_langname}
                            </LocalizationNavLink> */}
                            {page.object_name === "Others" ? <LocalizationNavLink
                              to={`/city_overview/?name=${page.object_name.toLowerCase()
                                .replace(/\s/g, "-")
                                .toLowerCase()}&id=${page.sitepageobject_id}&sptid=${page.type_id
                                }`}
                              onClick={() => (onOthersClick({ footerColumnName, othersTitle: footerColumns[footerColumnName].title }))}
                            >
                              {page.object_langname}
                            </LocalizationNavLink>
                            :
                            // ##### 24-08-23 Komarov
                            // <LocalizationNavLink
                            //   to={`/hotel_details/?selected_currency=${pickedcurrencyFromState}&start=${start}&end=${end}&id=${page.object_id}&city_id=${page.city_id}&adults=1&children=0&rooms=1&hotel_name=${page.object_name.toLowerCase().replace(/\s/g, "-").toLowerCase()}&hotel_id=${page.object_id}`}
                            // >
                            //   {page.object_langname}
                            // </LocalizationNavLink>}
                            <LocalizationNavLink
                              to={`/hotel_overview/?id=${page.object_id}&city_id=${page.city_id}&hotel_name=${page.object_name.toLowerCase().replace(/\s/g, "-").toLowerCase()}&hotel_id=${page.object_id}`}
                            >
                              {page.object_langname}
                            </LocalizationNavLink>}
                          </h2>
                        </li>
                      })}
                    </ul>
                  </div>
                </Col>
              );

            default:
              return <>Unexpected {footerColumnName}</>
          }
        })
      }
    </>
  };

  // ##### 24-07-22 Komarov
  return (
    <footer className="footer" data-component="Footer">
      <Container
        fluid="xs,sm,md,lg,xl"
        style={{ marginLeft: "auto", marginRight: "auto", width: "80%" }}
      >
        <Row
          xs={1}
          // ##### 23-03-30 Komarov
          // sm={1}
          sm={2}
          md={2}
          lg={3}
        >
          <PagesLinks />
        </Row>
        {/* ##### 24-03-06 Komarov */}
        <Row>
          <Col>
            <div style={{ marginTop: "2vw" }}>
              <span
                style={{
                  marginRight: "1vw",
                  color: "white",
                  fontSize: "10px",
                  fontFamily: "Arial",
                  fontStyle: "italic",
                }}
              >
                {WeAccept}
              </span>
              <img
                src={cards}
                // ##### 24-03-18 Komarov: TODO: Взять значения атрибута alt из Смарта
                alt="Cards accepted"
                // ##### 24-03-04 Komarov
                style={{
                  /* width: '5vw',  */ height: "2vw",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              />
            </div>
          </Col>
          <Col>
            <div style={{ marginTop: "2vw" }}>
              <span
                style={{
                  marginRight: "1vw",
                  color: "white",
                  fontSize: "10px",
                  fontFamily: "Arial",
                  fontStyle: "italic",
                }}
              >
                {Developer}
              </span>
            </div>
            {/* ##### 24-03-14 Komarov */}
            {isWhiteLabelDemo && (
              <div style={{ marginTop: "2vw" }}>
                <span
                  style={{
                    marginRight: "1vw",
                    color: "white",
                    fontSize: "10px",
                    fontFamily: "Arial",
                    fontStyle: "italic",
                  }}
                >
                  {DemoPayment}
                </span>
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </footer>
  );
});
