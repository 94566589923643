// ##### 23-08-17 Komarov
import axios from "axios";
import {
  GET_LOCALE_REQ,
  GET_LOCALE_RES /* , GET_LOCALE_ERR */,
} from "../constants";
import { GET_LANG_REQ, GET_LANG_RES /* , GET_LANG_ERR */ } from "../constants";
// ##### 23-08-17 Komarov
import { GET_LANGS_REQ, GET_LANGS_RES, GET_LANGS_ERR } from "../constants";

export const changeLangResponce = (res) => ({
  type: GET_LOCALE_RES,
  payload: res,
});

export const changeLang = (locale) => (dispatch) => {
  dispatch({ type: GET_LOCALE_REQ });
  dispatch(changeLangResponce(locale));
};

export const langRepsonse = (res) => ({
  type: GET_LANG_RES,
  payload: res,
});

// ##### 24-03-21 Komarov
export const getLangResponse = (param) => (dispatch) => {
  dispatch({ type: GET_LANG_REQ });
  dispatch(langRepsonse(param));
};

// ##### 24-03-21 Komarov
export const getLanguages = () => (dispatch) => {
  // ##### 24-09-24 Komarov
  const url = `${process.env.REACT_APP_SMART_URL}interface/classifier?classifier=language`;
  process.env.NODE_ENV === "development" &&
    console.log(
      "axios:src/Redux/actions/locale.js:getLanguages, url:",
      url
    );

  dispatch({ type: GET_LANGS_REQ });

  axios
    .get(
      url,
      {}
    )
    .then((res) => {
      const langs = res.data.map((lang) => {
        return {
          id: lang.id,
          name: lang.name.toLowerCase(),
        };
      });
      dispatch(getLanguagesResponse(langs));
    })
    .catch((err) => {
      dispatch({ type: GET_LANGS_ERR, error: err });
    });
};

const getLanguagesResponse = (res) => ({
  type: GET_LANGS_RES,
  payload: res,
});
