// ##### 24-09-09 Komarov
import React, { useState, useEffect, createContext, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPax } from "../../../../../Redux/actions/paxchoice.js";
// ##### 24-04-23 Komarov
import { useMessages } from "../../../../../helpers/messages.js";
// ##### 23-11-03 Komarov
import { Media } from "../../../../../helpers/MediaConfig.js";
// ##### 24-05-13 Komarov
import {
  setCounterAdultsOuter,
  setCounterChildOuter,
  setCounterRoomsOuter,
} from "../../../../../Redux/actions/index.js";
// ##### 24-05-17 Komarov
import { useLocation } from "react-router-dom";
import { ValidateQuery } from "../../../Helpers/helper.js";
import { HotelsPaxChoiceDiv } from "./HotelsPaxChoiceDiv.js";

export const HotelsPaxChoiceContext = createContext();

// ##### 24-05-17 Komarov
export const HotelsPaxChoice = ({ isOnModalDiv = false }) => {
  const Confirm = useMessages("Confirm");
  const twelve_plus_years = useMessages("twelve_plus_years");
  const two_eleven_years = useMessages("two_eleven_years");

  // ##### 23-12-27 Komarov
  const [paxListOpen, setPaxListOpen] = useState(false);
  const toggleContextPaxListOpen = (value) => setPaxListOpen(value);

  // ##### 24-05-17 Komarov
  const location = useLocation();
  const searchProps = ValidateQuery(location);
  // ##### 24-09-10 Komarov
  // const totalPaxRedux = useSelector((state) => state.paxchoice.pax);
  const totalPaxReduxAdults = useSelector((state) => state.paxchoice.pax.adults);
  const totalPaxReduxChildren = useSelector((state) => state.paxchoice.pax.children);
  const totalPaxReduxRooms = useSelector((state) => state.paxchoice.pax.rooms);

  // ##### 24-09-10 Komarov
  /* const [counterAdults, setCounterAdults] = useState(
    searchProps && searchProps.adults
      ? parseInt(searchProps.adults)
      : totalPaxRedux?.adults || 1
  );
  const [counterChild, setCounterChild] = useState(
    searchProps && searchProps.children
      ? parseInt(searchProps.children)
      : totalPaxRedux?.children || 0
  );
  const [counterRooms, setCounterRooms] = useState(
    searchProps && searchProps.rooms
      ? parseInt(searchProps.rooms)
      : totalPaxRedux?.rooms || 1
  ); */
  const [counterAdults, setCounterAdults] = useState(
    searchProps && searchProps.adults
      ? parseInt(searchProps.adults)
      : totalPaxReduxAdults || 1
  );
  const [counterChild, setCounterChild] = useState(
    searchProps && searchProps.children
      ? parseInt(searchProps.children)
      : totalPaxReduxChildren || 0
  );
  const [counterRooms, setCounterRooms] = useState(
    searchProps && searchProps.rooms
      ? parseInt(searchProps.rooms)
      : totalPaxReduxRooms || 1
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPax(counterAdults, counterChild, counterRooms));
    // ##### 23-04-12 Komarov
  }, [counterAdults, counterChild, counterRooms, dispatch]);

  const TotalPax = () => {
    dispatch(getPax(counterAdults, counterChild, counterRooms));
  };

  const addAdults = () => {
    setCounterAdults(counterAdults + 1);
    // ##### 23-08-07 Komarov
    dispatch(setCounterAdultsOuter(counterAdults + 1));
  };

  const deductAdults = () => {
    counterAdults > 0
      ? setCounterAdults(counterAdults - 1)
      : setCounterAdults(0);
    // ##### 24-05-13 Komarov
    counterAdults > 0
      ? dispatch(setCounterAdultsOuter(counterAdults - 1))
      : dispatch(setCounterAdultsOuter(0));
  };

  const addChild = () => {
    // counterChild<2? setCounterChild(counterChild+1) : alert('This tour allows upto 2 Children')
    setCounterChild(counterChild + 1);
    // ##### 23-08-07 Komarov
    setCounterChildOuter(counterChild + 1);
  };
  const deductChild = () => {
    counterChild > 0 ? setCounterChild(counterChild - 1) : setCounterChild(0);
    // ##### 23-08-07 Komarov
    counterChild > 0
      ? setCounterChildOuter(counterChild - 1)
      : setCounterChildOuter(0);
  };

  const addRooms = () => {
    // counterInfant<1? setCounterInfant(counterInfant+1) : alert('This tour allows upto 1 Infant')
    setCounterRooms(counterRooms + 1);
    // ##### 23-08-07 Komarov
    setCounterRoomsOuter(counterRooms + 1);
  };
  const deductRooms = () => {
    counterRooms > 0 ? setCounterRooms(counterRooms - 1) : setCounterRooms(1);
    // ##### 23-08-07 Komarov
    counterRooms > 0
      ? setCounterRoomsOuter(counterRooms - 1)
      : setCounterRoomsOuter(1);
  };

  // ##### 24-09-09 Komarov
  const hotelsPaxChoiceContextValue = useMemo(() => ({
    paxListOpen,
    toggleContextPaxListOpen,
    isOnModalDiv,
    TotalPax,
    Confirm,
    twelve_plus_years,
    two_eleven_years,
    addAdults,
    deductAdults,
    addChild,
    deductChild,
    addRooms,
    deductRooms,
    counterAdults,
    setCounterAdults,
    counterChild,
    setCounterChild,
    counterRooms,
    setCounterRooms,
  }), [
    // ##### 24-09-11 Komarov
    paxListOpen,
    toggleContextPaxListOpen,
    isOnModalDiv,
    TotalPax,
    Confirm,
    twelve_plus_years,
    two_eleven_years,
    addAdults,
    deductAdults,
    addChild,
    deductChild,
    addRooms,
    deductRooms,
    counterAdults,
    setCounterAdults,
    counterChild,
    setCounterChild,
    counterRooms,
    setCounterRooms,
  ]); // value is cached by useMemo

  return (
    // ##### 22-10-26 Komarov - start
    <HotelsPaxChoiceContext.Provider
      value={hotelsPaxChoiceContextValue}
    >
      {/* ##### 23-11-03 Komarov */}
      <Media lessThan="Mobile-L-425-768">
        <HotelsPaxChoiceDiv
          className="FrontHotelPaxChoiceWrapper"
          style={{ marginTop: "-30px" }}
        />
      </Media>
      {/* ##### 23-11-13 Komarov */}
      <Media at="Mobile-L-425-768">
        <HotelsPaxChoiceDiv
          className="FrontHotelPaxChoiceWrapper_SmallLandscape"
          style={{ transform: "translate3d(-5vw, 50vw, 0vw)" }}
        />
      </Media>
      <Media at="Tablet-768-1024">
        <HotelsPaxChoiceDiv
          className="FrontHotelPaxChoiceWrapper_SmallLandscape"
          // ##### 24-02-08 Komarov
          // style={{transform: 'translate3d(-18vw, 28vw, 0vw)'}}
          style={{ transform: "translate3d(-3vw, 28vw, 0vw)" }}
        />
      </Media>
      <Media greaterThanOrEqual="Laptop-1024-1440">
        <HotelsPaxChoiceDiv className="FrontHotelPaxChoiceWrapper" />
      </Media>
    </HotelsPaxChoiceContext.Provider>
    // ##### 22-10-26 Komarov - end
  );
};
