// ##### 23-10-04 Komarov
import { Input, Select, Alert } from "antd";
// ##### 23-12-27 Komarov
import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import parse from "html-react-parser";
import { DestinationsBlock } from "./DestinationsBlock";
import { ServicesBlock } from "./ServicesBlock";
// ##### 24-09-09 Komarov
import { /* TravelServiceTypes, */ TravelServiceTypesObj } from "../../../../StaticJsonData/TravelServiceTypes";
import LodgingTypes from "../../../../StaticJsonData/LodgingTypes.json";
import { PlaceHolderStrings } from "../../../../Localization/placeholders.js";
import { SendGroupTravelForm } from "../SendGroupTravelForm";
// ##### 23-10-24 Komarov
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
// ##### 24-04-23 Komarov
import { useMessages } from "../../../../../../helpers/messages";
// ##### 23-09-14 Komarov
// import '../GroupTravelFormCSS.css'

export const GroupTravelForm = ({ text }) => {
  const placeholder = PlaceHolderStrings();

  // ##### 23-10-02 Komarov: сохраняю начальные значения переменных для обязательных полей в переменные, чтобы сравнивать значения обязательных полей и так определять, изменились ли они.
  const countInitialState = 0;
  // ##### 24-09-09 Komarov
  // const multServicesInitialState = [];
  const multServicesInitialState = {};
  const telInputInitialState = "";
  const propertyNameInitialState = undefined;
  const ownerNameInitialState = undefined;
  const positionInitialState = "";
  const emailInitialState = "";
  // ##### 23-10-14 Komarov
  const accomTypeInitialState = "";
  const starRatingInitialState = undefined;
  const vehicleTypesInitialState = undefined;
  const mealTypeInitialState = undefined;
  const excursionTypesInitialState = undefined;
  const guideTypesTypesInitialState = undefined;
  // ##### 23-10-17 Komarov
  const excursionCommentsInitialState = undefined;
  // const guideAssistantCommentsInitialState = undefined;
  const flightTixCommentsInitialState = undefined;
  const trainTixCommentsInitialState = undefined;
  const conferenceFacilitiesCommentsInitialState = undefined;
  const avEquipmentCommentsInitialState = undefined;
  const visaSupportCommentsInitialState = undefined;
  const busTransferCommentsInitialState = undefined;
  const riverCruiseCommentsInitialState = undefined;
  const seaCruiseCommentsInitialState = undefined;
  const packageTourCommentsInitialState = undefined;
  const otherServiceCommentsInitialState = undefined;
  const charterCommentsInitialState = undefined;

  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  // ##### 24-03-21 Komarov
  // const [countryChanged, setCountryChanged] = useState(false)
  const [destination, setDestination] = useState([]);
  // ##### 24-03-21 Komarov
  // const [stayDates, setStayDates] = useState([]);
  const [stayBlock, setStayBlock] = useState([]);
  // ##### 23-10-04 Komarov
  const [multServices, setMultServices] = useState(multServicesInitialState);
  const [accomType, setAccomType] = useState(accomTypeInitialState); // TravelServiceTypes: id: 1
  const [starRating, setStarRating] = useState(starRatingInitialState); // to TravelServiceTypes: id: 1
  const [amountSingleRooms, setAmountSingleRooms] = useState(1);
  const [amountTwinRooms, setAmountTwinRooms] = useState(1);
  // ##### 23-10-11 Komarov
  const [touristsNumber, setTouristsNumber] = useState(2);
  const [vehicleTypes, setVehicleTypes] = useState(vehicleTypesInitialState); // TravelServiceTypes: id: 2
  const [mealType, setMealType] = useState(mealTypeInitialState); // TravelServiceTypes: id: 3
  const [excursionTypes, setExcursionTypes] = useState(
    excursionTypesInitialState
  ); // TravelServiceTypes: id: 6
  // ##### 24-03-21 Komarov
  // const [tailorProgram, setTailorProgram] = useState();
  const [guideTypes, setGuideTypes] = useState(guideTypesTypesInitialState); // TravelServiceTypes: id: 7
  // ##### 23-10-04 Komarov
  const [telInput, setTelInput] = useState(telInputInitialState);
  const [adrressInput, setAddressInput] = useState(""); // не обязательное поле
  // const [pickedStartDate, setPickedStartDate] = useState(false)
  // const [pickedEndDate, setPickedEndDate] = useState(false)
  // ##### 23-10-04 Komarov
  const [propertyName, setPropertyName] = useState(propertyNameInitialState);
  const [ownerName, setOwnerName] = useState(ownerNameInitialState);
  const [position, setPosition] = useState(positionInitialState);
  const [website, setWebSite] = useState(""); // не обязательное поле
  const [email, setEmail] = useState(emailInitialState);
  const [list, setList] = useState();
  const [clicked, setClicked] = useState(false);
  // ##### 23-10-04 Komarov
  const [count, setCount] = useState(countInitialState);
  const [comments, setComments] = useState(); // не обязательное поле
  // ##### 23-10-17 Komarov
  const [excursionComments, setExcursionComments] = useState(
    excursionCommentsInitialState
  );
  // const [guideAssistantComments, setGuideAssistantComments] = useState(guideAssistantCommentsInitialState);
  const [flightTixComments, setFlightTixComments] = useState(
    flightTixCommentsInitialState
  );
  const [trainTixComments, setTrainTixComments] = useState(
    trainTixCommentsInitialState
  );
  const [conferenceFacilitiesComments, setConferenceFacilitiesComments] =
    useState(conferenceFacilitiesCommentsInitialState);
  const [avEquipmentComments, setAvEquipmentComments] = useState(
    avEquipmentCommentsInitialState
  );
  const [visaSupportComments, setVisaSupportComments] = useState(
    visaSupportCommentsInitialState
  );
  const [busTransferComments, setBusTransferComments] = useState(
    busTransferCommentsInitialState
  );
  const [riverCruiseComments, setRiverCruiseComments] = useState(
    riverCruiseCommentsInitialState
  );
  const [seaCruiseComments, setSeaCruiseComments] = useState(
    seaCruiseCommentsInitialState
  );
  const [packageTourComments, setPackageTourComments] = useState(
    packageTourCommentsInitialState
  );
  const [otherServiceComments, setOtherServiceComments] = useState(
    otherServiceCommentsInitialState
  );
  const [charterComments, setCharterComments] = useState(
    charterCommentsInitialState
  );
  // ##### 23-10-24 Komarov
  const [captchaNotSolved, setCaptchaNotSolved] = useState(false);
  const captchaRef = useRef(null);
  const { user: currentUser } = useSelector((state) => state.auth);

  // ##### 23-10-02 Komarov: вынес вычисление значений для рендеринга (вызовы функций) в код перед рендерингом, иначе возникают ошибки при рендеринге компонентов, скрытых до нажатия кнопки.
  // ##### 24-04-23 Komarov
  const companyName = useMessages("Company name");
  const SupplierForm_ContactName = useMessages("SupplierForm_ContactName");
  const SupplierForm_ContactPosition = useMessages(
    "SupplierForm_ContactPosition"
  );
  const CompanyWebSite = useMessages("CompanyWebSite");
  const SupplierForm_Email = useMessages("SupplierForm_Email");
  const SupplierForm_Address = useMessages("SupplierForm_Address");
  const SupplierTel = useMessages("SupplierTel");
  const Comments = useMessages("Comments");
  const Submit = useMessages("Submit");
  const Group_request_destination = useMessages("Group_request_destination");
  const Group_request_services = parse(useMessages("Group_request_services"));

  // ##### 23-10-02 Komarov: отметки заполненности обязательных полей
  const [isCountSet, setIsCountSet] = useState("first rendered");
  const [isDestinationSet, setIsDestinationSet] = useState("first rendered");
  const [isMultServicesSet, setIsMultServicesSet] = useState("first rendered");
  const [isTelInputSet, setIsTelInputSet] = useState("first rendered");
  // ##### 24-03-21 Komarov
  // const [isPropertyNameSet, setIsPropertyNameSet] = useState('first rendered');
  const [isOwnerNameSet, setIsOwnerNameSet] = useState("first rendered");
  const [isPositionSet, setIsPositionSet] = useState("first rendered");
  const [isEmailSet, setIsEmailSet] = useState("first rendered");

  // ##### 23-10-03 Komarov: Функции для проверки правильности значения поля: если оно не отличается от начального значения, - поле заполнено неправильно и его значение отмечается ниже (в useEffect) не установленным
  // ##### 24-09-09 Komarov
  const isDestinationRight = () => {
    // ##### 24-03-21 Komarov
    if (stayBlock.length === 0) {
      return false;
    }
    // ##### 24-03-21 Komarov
    for (const item of stayBlock) {
      if (item.stay_dates[0] === "" || item.stay_dates[1] === "") {
        return false;
      }
    }
    return true;
  };
  // ##### 24-03-21 Komarov
  const isCountRight = () => count !== countInitialState;
  // ##### 24-09-09 Komarov
  // const isMultServicesRight = () => multServices.length !== 0;
  const isMultServicesRight = () => Object.keys(multServices).length !== 0;
  const isTelInputRight = () => telInput !== telInputInitialState;
  // ##### 23-10-11 Komarov
  // const isPropertyNameRight = () => propertyName != propertyNameInitialState;
  // ##### 24-03-21 Komarov
  const isOwnerNameRight = () => ownerName !== ownerNameInitialState;
  const isPositionRight = () => position !== positionInitialState;
  const isEmailRight = () => email !== emailInitialState;

  // ##### 23-10-02 Komarov: при изменении поля на значение, отличное от начального (отличия проверяют соотв. функции), устанавливаем отметку заполненности для соответствующего поля
  // ##### 24-03-21 Komarov
  /* useEffect(() => {
    isCountRight() && setIsCountSet(true);
  }, [count]); */
  useEffect(() => {
    isCountRight() && setIsCountSet(true);
  }, [isCountRight]);
  /* useEffect(() => {
    isDestinationRight() && setIsDestinationSet(true);
  }, [stayBlock]); */
  useEffect(() => {
    isDestinationRight() && setIsDestinationSet(true);
  }, [isDestinationRight]);
  /* useEffect(() => {
    isMultServicesRight() && setIsMultServicesSet(true);
  }, [multServices]); */
  useEffect(() => {
    isMultServicesRight() && setIsMultServicesSet(true);
  }, [isMultServicesRight]);
  /* useEffect(() => {
    isTelInputRight() && setIsTelInputSet(true);
  }, [telInput]); */
  useEffect(() => {
    isTelInputRight() && setIsTelInputSet(true);
  }, [isTelInputRight]);
  // ##### 23-10-11 Komarov
  /* useEffect(() => {
    isPropertyNameRight() && setIsPropertyNameSet(true);
  }, [propertyName]); */
  // ##### 24-03-21 Komarov
  /* useEffect(() => {
    isOwnerNameRight() && setIsOwnerNameSet(true);
  }, [ownerName]); */
  useEffect(() => {
    isOwnerNameRight() && setIsOwnerNameSet(true);
  }, [isOwnerNameRight]);
  /* useEffect(() => {
    isPositionRight() && setIsPositionSet(true);
  }, [position]); */
  useEffect(() => {
    isPositionRight() && setIsPositionSet(true);
  }, [isPositionRight]);
  /* useEffect(() => {
    isEmailRight() && setIsEmailSet(true);
  }, [email]); */
  useEffect(() => {
    isEmailRight() && setIsEmailSet(true);
  }, [isEmailRight]);

  // ##### 23-10-04 Komarov: функция для помечания полей обязательными для заполнения (она добавляет звёздочку к названию поля)
  const markReuired = (labelText) => `${labelText} *`;

  // ##### 23-12-27 Komarov
  const locale = useSelector((state) => state.language);
  //  ##### 23-09-26 Komarov
  const { Option } = Select;
  const { TextArea } = Input;

  const children = []; //for miltiple cities selection + the below array function
  for (let i = 10; i < 36; i++) {
    children.push(
      <Option key={i.toString(36) + i}>{i.toString(36) + i}</Option>
    );
  }

  const selectCountry = (value) => {
    setCountry(value);
    // ##### 24-03-21 Komarov
    // setCountryChanged(true)
  };

  const onChangeCity = (value) => {
    setCity(value);
    // ##### 24-03-21 Komarov
    // setCountryChanged(false)
  };

  const onSearchCity = (val) => {
    console.log("search:", val);
  };

  // ##### 24-03-21 Komarov
  const AddDestination = (/* e */) => {
    setCount(count + 1);

    const newObj = {
      country: country,
      city: city,
      ID: `${
        !country + city + count
          ? country + city + count
          : country + city + count
      }`,
    };
    setDestination([...destination, newObj]);
  };

  // ##### 24-03-21 Komarov
  const removeDestination = (ID) => (/* e */) => {
    // ##### 23-10-16 Komarov
    setCount(count - 1);

    const shortObj = [];

    // ##### 24-03-21 Komarov
    for (const item of destination) {
      if (parseInt(item.ID) !== parseInt(ID)) {
        return shortObj.push(item);
      }
    }

    setDestination(shortObj);
  };

  // ##### 24-03-21 Komarov
  const onChangeDates = (item) => (_dates, dateStrings) => {
    // setStayDates(dateStrings);

    const datesObj = {
      country: item.country,
      city: item.city,
      ID: item.ID,
      stay_dates: dateStrings,
    };

    // ##### 23-10-04 Komarov: определяем, есть ли такой объект в массиве дат на данный момент (момент изменения конкретного блочка с датами)
    // ##### 24-03-21 Komarov
    const foundDatesObj = stayBlock.filter(
      (unit) => parseInt(unit.ID) === parseInt(item.ID)
    )[0];
    console.log("foundDatesObj", foundDatesObj);

    // ##### 23-10-03 Komarov: Если изменяется существующий объект в массиве дат, то заменяем существующий объект, иначе - добавляем в массив новый
    if (foundDatesObj) {
      // ##### 24-03-21 Komarov
      const datesBefore = [];
      const datesAfter = [];
      let isBeforeChangedItem = true;
      // ##### 24-03-21 Komarov
      for (const unit of stayBlock) {
        if (parseInt(unit.ID) === parseInt(item.ID)) {
          isBeforeChangedItem = false;
          continue;
        }
        if (isBeforeChangedItem) {
          datesBefore.push(unit);
        } else {
          datesAfter.push(unit);
        }
      }
      setStayBlock([...datesBefore, datesObj, ...datesAfter]);
    } else {
      setStayBlock([...stayBlock, datesObj]);
    }
  };

  // ##### 23-10-13 Komarov: функция для определения, какие сервисы удалили
  const removedItems = ({ oldServices, newServices }) => {
    // ##### 24-03-21 Komarov
    const restServices = [];
    // ##### 23-10-13 Komarov: если длина нового массива сервисов больше или равна длине старого, - значит, ни один из сервисов не удалили.
    if (newServices.length >= oldServices.length) return false;
    // ##### 23-10-13 Komarov: отфильтровываем старые сервисы, которых нету в новых: их и удалили
    // ##### 24-03-21 Komarov
    for (const oldService of oldServices) {
      if (
        newServices.filter((newService) => newService === oldService).length ===
        0
      ) {
        restServices.push(oldService);
      }
    }

    return restServices;
  };

  // ##### 24-09-09 Komarov
  const chooseMultServices = (value) => {
    // ##### 24-03-21 Komarov
    const chosenServices = {};

    // ##### 24-03-21 Komarov: Добавление выбранных сервисов
    for (const serviceName of value) {
      chosenServices[serviceName] = TravelServiceTypesObj[locale][serviceName];
    }

    // ##### 23-10-13 Komarov: [...multServices.map(({id}) => id)] - создаём массив чисто id-шек из массива multServices
    const userRemovedItems = removedItems({
      oldServices: Object.keys(multServices),
      newServices: value,
    });

    // ##### 23-10-13 Komarov: при удалении сервиса очищаем и его state в текущем компоненте (GroupTravelForm)
    if (userRemovedItems) {
      // ##### 24-03-21 Komarov
      for (const userRemovedItem of userRemovedItems) {
        switch (userRemovedItem) {
          case "Accommodation":
            setAccomType(accomTypeInitialState);
            setStarRating(starRatingInitialState);
            break;
          case "Transfers":
            setVehicleTypes(vehicleTypesInitialState);
            break;
          case "Meal":
            setMealType(mealTypeInitialState);
            break;
          case "Excursion":
            // ##### 23-10-17 Komarov
            // setExcursionTypes(excursionTypesInitialState);
            setExcursionComments(excursionTypesInitialState);
            break;
          case "Guide-assistant":
            setGuideTypes(guideTypesTypesInitialState);
            break;
          // ##### 23-10-17 Komarov
          /* case 8:
            setExcursionComments(excursionCommentsInitialState);
            break; */
          case "Flight tix":
            setFlightTixComments(flightTixCommentsInitialState);
            break;
          case "Train tix":
            setTrainTixComments(trainTixCommentsInitialState);
            break;
          case "Conference facilities":
            setConferenceFacilitiesComments(
              conferenceFacilitiesCommentsInitialState
            );
            break;
          case "A/v equipment":
            setAvEquipmentComments(avEquipmentCommentsInitialState);
            break;
          case "Visa Support":
            setVisaSupportComments(visaSupportCommentsInitialState);
            break;
          case "Bus Transfer":
            setBusTransferComments(busTransferCommentsInitialState);
            break;
          case "River Cruise":
            setRiverCruiseComments(riverCruiseCommentsInitialState);
            break;
          case "Sea Cruise":
            setSeaCruiseComments(seaCruiseCommentsInitialState);
            break;
          case "Package Tour":
            setPackageTourComments(packageTourCommentsInitialState);
            break;
          case "Other Service":
            setOtherServiceComments(otherServiceCommentsInitialState);
            break;
          case "Charter":
            setCharterComments(charterCommentsInitialState);
            break;
          default:
            console.error(
              "Possible, some error happened: it is an impossible case. Maybe, this information will be useful:"
            );
            console.error("userRemovedItem", userRemovedItems);
            break;
        }
      }
    }
    setMultServices(chosenServices);
  }

  // const chooseMultServices = (value) => {
  //   // ##### 24-03-21 Komarov
  //   const array = [];

  //   // ##### 24-03-21 Komarov
  //   for (const item of TravelServiceTypes(locale)) {
  //     for (const item2 of value) {
  //       if (parseInt(item.id) === parseInt(item2)) {
  //         array.push(item);
  //       }
  //     }
  //   }

  //   // ##### 23-10-13 Komarov: [...multServices.map(({id}) => id)] - создаём массив чисто id-шек из массива multServices
  //   const userRemovedItems = removedItems({
  //     oldServices: [...multServices.map(({ id }) => id)],
  //     newServices: value,
  //   });

  //   // ##### 23-10-13 Komarov: при удалении сервиса очищаем и его state в текущем компоненте (GroupTravelForm)
  //   if (userRemovedItems) {
  //     // ##### 24-03-21 Komarov
  //     for (const userRemovedItem of userRemovedItems) {
  //       switch (userRemovedItem) {
  //         case 1:
  //           setAccomType(accomTypeInitialState);
  //           setStarRating(starRatingInitialState);
  //           break;
  //         case 2:
  //           setVehicleTypes(vehicleTypesInitialState);
  //           break;
  //         case 3:
  //           setMealType(mealTypeInitialState);
  //           break;
  //         case 6:
  //           // ##### 23-10-17 Komarov
  //           // setExcursionTypes(excursionTypesInitialState);
  //           setExcursionComments(excursionTypesInitialState);
  //           break;
  //         case 7:
  //           setGuideTypes(guideTypesTypesInitialState);
  //           break;
  //         // ##### 23-10-17 Komarov
  //         /* case 8:
  //           setExcursionComments(excursionCommentsInitialState);
  //           break; */
  //         case 8:
  //           setFlightTixComments(flightTixCommentsInitialState);
  //           break;
  //         case 9:
  //           setTrainTixComments(trainTixCommentsInitialState);
  //           break;
  //         case 10:
  //           setConferenceFacilitiesComments(
  //             conferenceFacilitiesCommentsInitialState
  //           );
  //           break;
  //         case 11:
  //           setAvEquipmentComments(avEquipmentCommentsInitialState);
  //           break;
  //         case 12:
  //           setVisaSupportComments(visaSupportCommentsInitialState);
  //           break;
  //         case 13:
  //           setBusTransferComments(busTransferCommentsInitialState);
  //           break;
  //         case 14:
  //           setRiverCruiseComments(riverCruiseCommentsInitialState);
  //           break;
  //         case 15:
  //           setSeaCruiseComments(seaCruiseCommentsInitialState);
  //           break;
  //         case 16:
  //           setPackageTourComments(packageTourCommentsInitialState);
  //           break;
  //         case 17:
  //           setOtherServiceComments(otherServiceCommentsInitialState);
  //           break;
  //         case 18:
  //           setCharterComments(charterCommentsInitialState);
  //           break;
  //         default:
  //           console.error(
  //             "Possible, some error happened: it is an impossible case. Maybe, this information will be useful:"
  //           );
  //           console.error("userRemovedItem", userRemovedItems);
  //           break;
  //       }
  //     }
  //   }
  //   setMultServices(array);
  // };

  const selectingAccomTypes = (value) => {
    setAccomType(value);
  };

  const SelectSingleRoomsAmount = (value) => {
    setAmountSingleRooms(value);
  };

  const SelectTwinRoomsAmount = (value) => {
    setAmountTwinRooms(value);
  };

  // ##### 23-10-11 Komarov
  const SelectTouristsNumber = (value) => {
    setTouristsNumber(value);
  };

  const selectStarRating = (value) => {
    setStarRating(value);
  };

  const selectVehicleTypes = (value) => {
    setVehicleTypes(value);
  };

  // ##### 23-10-14 Komarov
  const selectMealType = (value) => {
    setMealType(value);
  };
  const selectExcursionTypes = (value) => {
    setExcursionTypes(value);
  };

  // ##### 24-03-21 Komarov
  /* const inputTailorProgram = (value) => {
    setTailorProgram(value)
  } */

  const selectGuideTypes = (value) => {
    setGuideTypes(value);
  };

  const selectPostion = (value) => {
    setPosition(value);
  };

  const SelNameProperty = (e) => {
    setPropertyName(e.target.value);
  };

  const SelOwnerName = (e) => {
    setOwnerName(e.target.value);
  };

  const SelTelephone = (e) => {
    setTelInput(e.target.value);
  };

  const SelAddress = (e) => {
    setAddressInput(e.target.value);
  };

  const InputEmail = (e) => {
    setEmail(e.target.value);
  };

  const SelWebSite = (e) => {
    setWebSite(e.target.value);
  };

  const AddComments = (e) => {
    setComments(e.target.value);
  };
  // ##### 23-10-17 Komarov
  const AddExcursionComments = (e) => {
    setExcursionComments(e.target.value);
  };

  /* const AddGuideAssistantComments = (e) => {
    setGuideAssistantComments(e.target.value)
  } */

  const AddFlightTixComments = (e) => {
    setFlightTixComments(e.target.value);
  };

  const AddTrainTixComments = (e) => {
    setTrainTixComments(e.target.value);
  };

  const AddConferenceFacilitiesComments = (e) => {
    setConferenceFacilitiesComments(e.target.value);
  };

  const AddAvEquipmentComments = (e) => {
    setAvEquipmentComments(e.target.value);
  };

  const AddVisaSupportComments = (e) => {
    setVisaSupportComments(e.target.value);
  };

  const AddBusTransferComments = (e) => {
    setBusTransferComments(e.target.value);
  };

  const AddRiverCruiseComments = (e) => {
    setRiverCruiseComments(e.target.value);
  };

  const AddSeaCruiseComments = (e) => {
    setSeaCruiseComments(e.target.value);
  };

  const AddPackageTourComments = (e) => {
    setPackageTourComments(e.target.value);
  };

  const AddOtherServiceComments = (e) => {
    setOtherServiceComments(e.target.value);
  };

  const AddCharterComments = (e) => {
    setCharterComments(e.target.value);
  };

  const Complete = () => {
    // ##### 23-10-02 Komarov: при нажатии на кнопку "Submit" проверяем значения обязательных полей и, если значение не отличается от начального, устанавливаем признак заполненности этого поля в false, чтобы использовать этот признак для установки статуса соотв. поля на странице в error (поле становится обведено красной полоской). При этом функцию отправки электронного письма не выполняем, а выходим из неё (return).
    if (!isCountRight()) {
      setIsCountSet(false);
      return;
    }
    if (!isDestinationRight()) {
      setIsDestinationSet(false);
      return;
    }
    if (!isMultServicesRight()) {
      setIsMultServicesSet(false);
      return;
    }
    if (!isTelInputRight()) {
      setIsTelInputSet(false);
      return;
    }
    // ##### 23-10-11 Komarov
    /* if (!isPropertyNameRight()) {
      setIsPropertyNameSet(false);
      return;
    } */
    if (!isOwnerNameRight()) {
      setIsOwnerNameSet(false);
      return;
    }
    if (!isPositionRight()) {
      setIsPositionSet(false);
      return;
    }
    if (!isEmailRight()) {
      setIsEmailSet(false);
      return;
    }

    // ##### 23-10-25 Komarov: Если приложение работает онлайн, установлены соотв. переменные окружения, тогда проверяем успешное прохождение CAPTCHA, иначе действуем без учёта прохождения CAPTCHA
    // ##### 24-03-21 Komarov
    if (
      process.env.REACT_APP_IS_RECAPTCHA_ONLINE &&
      process.env.REACT_APP_IS_RECAPTCHA_ONLINE === "true" &&
      process.env.REACT_APP_RECAPTCHA_SITE &&
      process.env.REACT_APP_RECAPTCHA_SERVER
    ) {
      // ##### 23-10-24 Komarov
      const token = captchaRef.current.getValue();
      captchaRef.current.reset();
      setCaptchaNotSolved(true);

      // ##### 23-10-20 Komarov
      axios
        .post(
          `${process.env.REACT_APP_URL}/token_check`,
          { token },
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        )
        .then((res) => {
          if (res.data === "Human 👨 👩") {
            setCaptchaNotSolved(false);
            const newList = {
              // country:country,
              // city:city,
              itinerary_with_dates: stayBlock,
              // ##### 24-09-09 Komarov: TODO: Проверить отправку списка выбранных сервисов на email
              // included_services: multServices,
              included_services: Object.values(multServices),
              accom_type: accomType,
              star_rating: starRating,
              single_rooms: amountSingleRooms,
              twin_rooms: amountTwinRooms,
              // ##### 23-10-11 Komarov
              tourists_number: touristsNumber,
              vehicle_type: vehicleTypes,
              // ##### 23-10-14 Komarov
              meal_type: mealType,
              excursion_type: excursionTypes === "" ? "none" : excursionTypes,
              tailor_made: "",
              guide_lang: guideTypes,
              company_name: propertyName,
              contact_name: ownerName,
              position: position,
              website: website,
              tel: telInput,
              email: email,
              postal_address: adrressInput,
              // ##### 23-10-17 Komarov
              comments: comments,
              excursionComments,
              // guideAssistantComments,
              flightTixComments,
              trainTixComments,
              conferenceFacilitiesComments,
              avEquipmentComments,
              visaSupportComments,
              busTransferComments,
              riverCruiseComments,
              seaCruiseComments,
              packageTourComments,
              otherServiceComments,
              charterComments,
            };
            // ##### 23-10-04 Komarov
            setList(newList);
            setClicked(true);
          } else {
            setCaptchaNotSolved(true);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      const newList = {
        // country:country,
        // city:city,
        itinerary_with_dates: stayBlock,
        // ##### 24-09-09 Komarov: TODO: Проверить отправку списка выбранных сервисов на email
        // included_services: multServices,
        included_services: Object.values(multServices),
        accom_type: accomType,
        star_rating: starRating,
        single_rooms: amountSingleRooms,
        twin_rooms: amountTwinRooms,
        // ##### 23-10-11 Komarov
        tourists_number: touristsNumber,
        vehicle_type: vehicleTypes,
        // ##### 23-10-14 Komarov
        meal_type: mealType,
        excursion_type: excursionTypes === "" ? "none" : excursionTypes,
        tailor_made: "",
        guide_lang: guideTypes,
        company_name: propertyName,
        contact_name: ownerName,
        position: position,
        website: website,
        tel: telInput,
        email: email,
        postal_address: adrressInput,
        // ##### 23-10-17 Komarov
        comments: comments,
        excursionComments,
        // guideAssistantComments,
        flightTixComments,
        trainTixComments,
        conferenceFacilitiesComments,
        avEquipmentComments,
        visaSupportComments,
        busTransferComments,
        riverCruiseComments,
        seaCruiseComments,
        packageTourComments,
        otherServiceComments,
        charterComments,
      };
      // ##### 23-10-04 Komarov
      setList(newList);
      setClicked(true);
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <form
      className="groupTravelWrapper"
      onSubmit={onSubmit}
      data-component="GroupTravelForm"
    >
      <h3>{parse(text)}</h3>

      <DestinationsBlock
        destination={destination}
        selectCountry={selectCountry}
        onChangeCity={onChangeCity}
        onSearchCity={onSearchCity}
        AddDestination={AddDestination}
        removeDestination={removeDestination}
        country={country}
        onChangeDates={onChangeDates}
        // ##### 23-10-04 Komarov
        stayBlock={stayBlock}
        // ##### 23-10-02 Komarov: если поле не заполнено, оно обводится красной полоской
        status={!isDestinationSet && "error"}
        // ##### 23-10-16 Komarov
        count={count}
      />
      {/* ##### 23-10-02 Komarov: оповещение пользователя о необходимости заполнить это поле */}
      {/* ##### 24-03-21 Komarov */}
      {!(isDestinationSet && isCountSet) && (
        <Alert
          message={Group_request_destination}
          type="error"
          showIcon
          closable
          onClose={() => {
            setIsDestinationSet("first rendered");
            setIsCountSet("first rendered");
          }}
        />
      )}

      {/* ##### 23-10-14 Komarov */}
      <ServicesBlock
        chooseMultServices={chooseMultServices}
        selectingAccomTypes={selectingAccomTypes}
        accomType={accomType}
        accomTypeInitialState={accomTypeInitialState}
        SelectSingleRoomsAmount={SelectSingleRoomsAmount}
        SelectTwinRoomsAmount={SelectTwinRoomsAmount}
        //  ##### 23-10-11 Komarov
        SelectTouristsNumber={SelectTouristsNumber}
        selectStarRating={selectStarRating}
        starRating={starRating}
        starRatingInitialState={starRatingInitialState}
        multServices={multServices}
        selectVehicleTypes={selectVehicleTypes}
        vehicleTypes={vehicleTypes}
        vehicleTypesInitialState={vehicleTypesInitialState}
        selectMealType={selectMealType}
        mealType={mealType}
        mealTypesInitialState={mealTypeInitialState}
        selectExcursionTypes={selectExcursionTypes}
        excursionTypes={excursionTypes}
        excursionTypesInitialState={excursionTypesInitialState}
        selectGuideTypes={selectGuideTypes}
        guideTypes={guideTypes}
        guideTypesTypesInitialState={guideTypesTypesInitialState}
        // ##### 24-03-21 Komarov
        // inputTailorProgram={inputTailorProgram}
        // ##### 23-10-03 Komarov: если поле не заполнено, оно обводится красной полоской
        status={!isMultServicesSet && "error"}
        // ##### 23-10-17 Komarov
        AddExcursionComments={AddExcursionComments}
        // AddGuideAssistantComments={AddGuideAssistantComments}
        AddFlightTixComments={AddFlightTixComments}
        AddTrainTixComments={AddTrainTixComments}
        AddConferenceFacilitiesComments={AddConferenceFacilitiesComments}
        AddAvEquipmentComments={AddAvEquipmentComments}
        AddVisaSupportComments={AddVisaSupportComments}
        AddBusTransferComments={AddBusTransferComments}
        AddRiverCruiseComments={AddRiverCruiseComments}
        AddSeaCruiseComments={AddSeaCruiseComments}
        AddPackageTourComments={AddPackageTourComments}
        AddOtherServiceComments={AddOtherServiceComments}
        AddCharterComments={AddCharterComments}
        excursionComments={excursionComments}
        // guideAssistantComments={guideAssistantComments}
        flightTixComments={flightTixComments}
        trainTixComments={trainTixComments}
        conferenceFacilitiesComments={conferenceFacilitiesComments}
        avEquipmentComments={avEquipmentComments}
        visaSupportComments={visaSupportComments}
        busTransferComments={busTransferComments}
        riverCruiseComments={riverCruiseComments}
        seaCruiseComments={seaCruiseComments}
        packageTourComments={packageTourComments}
        otherServiceComments={otherServiceComments}
        charterComments={charterComments}
      />
      {/* ##### 23-10-03 Komarov: оповещение пользователя о необходимости заполнить это поле */}
      {!isMultServicesSet && (
        <Alert
          message={Group_request_services}
          type="error"
          showIcon
          closable
          onClose={() => setIsMultServicesSet("first rendered")}
        />
      )}

      <div className="groupTravelblocks_3">
        <span>
          {
            // ##### 23-10-11 Komarov
            // markReuired(companyName)
            companyName
          }
        </span>
        <TextArea
          rows={1}
          // ##### 23-10-16 Komarov
          // style={{ marginTop: '1vh', marginBottom: '1vh' }}
          style={{ marginTop: "0.5vh", marginBottom: "0.5vh" }}
          onChange={SelNameProperty}
          value={propertyName}
          // ##### 23-10-03 Komarov: если поле не заполнено, оно обводится красной полоской
          // ##### 23-10-11 Komarov
          // status={!isPropertyNameSet && 'error'}
        />
        {/* ##### 23-10-03 Komarov: оповещение пользователя о необходимости заполнить это поле */}
        {/* ##### 23-10-11 Komarov */}
        {/* {(!isPropertyNameSet) && <Alert
            message={companyName}
            type="error"
            showIcon
            closable
            onClose={() => setIsPropertyNameSet('first rendered')}
          />}  */}

        <span>
          {
            // ##### 23-10-04 Komarov
            markReuired(SupplierForm_ContactName)
          }
        </span>
        <TextArea
          rows={1}
          // ##### 23-10-16 Komarov
          // style={{ marginTop: '1vh', marginBottom: '1vh' }}
          style={{ marginTop: "0.5vh", marginBottom: "0.5vh" }}
          onChange={SelOwnerName}
          value={ownerName}
          // ##### 23-10-03 Komarov: если поле не заполнено, оно обводится красной полоской
          status={!isOwnerNameSet && "error"}
        />
        {/* ##### 23-10-03 Komarov: оповещение пользователя о необходимости заполнить это поле */}
        {!isOwnerNameSet && (
          <Alert
            message={companyName}
            type="error"
            showIcon
            closable
            onClose={() => setIsOwnerNameSet("first rendered")}
          />
        )}
      </div>

      <div className="groupTravelblocks_5">
        <span>
          {
            // ##### 23-10-04 Komarov
            markReuired(SupplierForm_ContactPosition)
          }
        </span>
        <Select
          onChange={selectPostion}
          bordered={true}
          placeholder={placeholder.placeHolderSelect}
          style={{ width: "13vw" }}
          // ##### 23-10-03 Komarov: если поле не заполнено, оно обводится красной полоской
          status={!isPositionSet && "error"}
        >
          <>
            {
              // ##### 24-03-21 Komarov
              LodgingTypes?.map((item) => {
                if (item.lang === locale) {
                  return item.ownership_types.map((item1) => {
                    return (
                      <Option value={item1} key={item1}>
                        {item1}
                      </Option>
                    );
                  });
                }
              })
            }
          </>
        </Select>
        {/* ##### 23-10-03 Komarov: оповещение пользователя о необходимости заполнить это поле */}
        {!isPositionSet && (
          <Alert
            message={SupplierForm_ContactPosition}
            type="error"
            showIcon
            closable
            onClose={() => setIsPositionSet("first rendered")}
          />
        )}

        <span>
          {
            // ##### 23-10-04 Komarov
            CompanyWebSite
          }
        </span>
        <Input
          style={{ width: "80%", background: "white" }}
          onChange={SelWebSite}
          value={website}
        />

        <span>
          {
            // ##### 23-10-04 Komarov
            markReuired(SupplierForm_Email)
          }
        </span>
        <Input
          style={{ width: "80%", background: "white" }}
          onChange={InputEmail}
          value={email}
          // ##### 23-10-03 Komarov: если поле не заполнено, оно обводится красной полоской
          status={!isEmailSet && "error"}
        />
        {/* ##### 23-10-03 Komarov: оповещение пользователя о необходимости заполнить это поле */}
        {!isEmailSet && (
          <Alert
            message={SupplierForm_Email}
            type="error"
            showIcon
            closable
            onClose={() => setIsEmailSet("first rendered")}
          />
        )}
      </div>

      <div className="groupTravelblocks_3">
        <span>
          {
            // ##### 23-10-04 Komarov
            SupplierForm_Address
          }
        </span>
        <TextArea
          // ##### 23-09-06 Komarov
          // rows={3}
          rows={1}
          // ##### 23-10-16 Komarov
          // style={{ marginTop: '1vh', marginBottom: '1vh' }}
          style={{ marginTop: "0.5vh", marginBottom: "0.5vh" }}
          onChange={SelAddress}
          value={adrressInput}
        />

        <span>
          {
            // ##### 23-10-04 Komarov
            markReuired(SupplierTel)
          }
        </span>
        <Input
          style={{ width: "100%", background: "white" }}
          onChange={SelTelephone}
          value={telInput}
          // ##### 23-10-03 Komarov: если поле не заполнено, оно обводится красной полоской
          status={!isTelInputSet && "error"}
        />
        {/* ##### 23-10-03 Komarov: оповещение пользователя о необходимости заполнить это поле */}
        {!isTelInputSet && (
          <Alert
            message={SupplierTel}
            type="error"
            showIcon
            closable
            onClose={() => setIsTelInputSet("first rendered")}
          />
        )}
      </div>

      {/* ##### 23-10-16 Komarov */}
      {/* <div className='groupTravelblocks_3'> */}
      <div className="groupTravelFormComments">
        <span>
          {
            // ##### 23-10-04 Komarov
            Comments
          }
        </span>
        <TextArea
          // ##### 23-09-06 Komarov
          // rows={3}
          rows={1}
          // ##### 23-10-16 Komarov
          // style={{ marginTop: '1vh', marginBottom: '1vh' }}
          style={{ marginTop: "0.5vh", marginBottom: "0.5vh" }}
          onChange={AddComments}
          value={comments}
        />
      </div>
      {/* ##### 23-10-24 Komarov: TODO: взять перевод из Смарта */}
      {/* ##### 23-10-24 Komarov: Если пользователь не зарегистрирован, и в переменных окружения есть переменная REACT_APP_IS_RECAPTCHA_ONLINE=true, то отображаем тест reCAPTCHA */}
      {!currentUser ||
        (currentUser &&
          // ##### 24-03-21 Komarov
          parseInt(currentUser.user_id) === 1426 &&
          process.env.REACT_APP_IS_RECAPTCHA_ONLINE &&
          process.env.REACT_APP_IS_RECAPTCHA_ONLINE === "true" &&
          process.env.REACT_APP_RECAPTCHA_SITE &&
          process.env.REACT_APP_RECAPTCHA_SERVER && (
            <div className="groupTravelFormComments">
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_RECAPTCHA_SITE}
                // onChange={handleRecaptchaChange}
                ref={captchaRef}
              />
              {/* ##### 23-10-24 Komarov: TODO: взять перевод из Смарта */}
              {captchaNotSolved && (
                <Alert variant={"danger"}>Please solve a CAPTCHA!</Alert>
              )}
            </div>
          ))}
      {/* ##### 24-03-21 Komarov */}
      <button
        type="submit"
        className="groupTravelSubmitButton"
        onClick={Complete}
      >
        {
          // ##### 23-10-04 Komarov
          Submit
        }
      </button>
      {clicked === true ? <SendGroupTravelForm list={list} /> : null}
    </form>
  );
};
