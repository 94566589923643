// ##### 24-06-14 Komarov
import React, { useContext } from "react";
// ##### 24-08-10 Komarov
// import ArkturCollection from "../../../../Library/Images/ArkturCollection.jpg";
import { LargeScreenTopToursContext } from "./LargeScreenTopTours";
// ##### 24-08-10 Komarov
import { TopToursTitle } from "../../ResponsiveHeader/Header/TopToursTitle";

// ##### 23-11-08 Komarov
export const LargeScreenTopToursDiv = ({ width }) => {
  const { TopToursContents, GetTourDetails, TourTypes } = useContext(
    LargeScreenTopToursContext
  );
  // ##### 23-11-08 Komarov
  return (
    <div>
      <div
        style={{
          padding: "19px",
          backgroundColor: "#005AEB",
          marginTop: "2vw",
          borderRadius: "5px",
          width: `${width * 0.65}px`,
        }} />
      <div style={{ textAlign: "center" }}>
        {/* ##### 24-03-18 Komarov: TODO: Брать перевод значения атрибута alt из Смарта */}
        {/* ##### 24-08-10 Komarov */}
        {/* <img
          src={ArkturCollection}
          // ##### 24-03-21 Komarov
          alt="Arktur Collection" /> */}
        <TopToursTitle />
      </div>

      <div>
        <ul>
          {TourTypes.length > 0 ? (
            TourTypes.map((type) => {
              return (
                <li className="TopToursWrapper" key={type}>
                  <h3
                    style={{
                      fontFamily: "Arial Narrow",
                      fontSize: "24px",
                      fontWeight: "bold",
                      color: "white",
                      textAlign: "center",
                      backgroundColor: "#005AEB",
                      borderRadius: "5px",
                      lineHeight: "6vh",
                      width: `${width * 0.65}px`,
                    }}
                  >
                    {type}
                  </h3>
                  <ul
                    style={{
                      textAlign: "center",
                      display: "grid",
                      gridTemplateColumns: `repeat(3, ${(width * 0.65) / 3}px)`,
                      listStyle: "none",
                    }}
                  >
                    {TopToursContents.length > 0
                      ? TopToursContents.map((tour, index) => {
                        // console.log('PHOTO',tour.main_photo[0])
                        if (index < 12 && type === tour.subservices.name) {
                          return (
                            <li
                              key={tour.tour_id}
                              onClick={GetTourDetails}
                              // ##### 24-03-21 Komarov
                              onKeyDown={(e) => {
                                (e.key === "Enter" || e.key === " ") &&
                                  GetTourDetails();
                              }}
                            >
                              {/* <TopToursDetails
                                                            tour_id={tour.tour_id}
                                                          />  */}
                              <div id={tour.tour_id}>
                                {tour.main_photo[0] ? (
                                  <img
                                    id={tour.tour_id}
                                    // ##### 24-03-21 Komarov
                                    alt={tour.tour_name}
                                    className="TopToursImage"
                                    width={`${(width * 0.6) / 3}px`}
                                    src={(process.env.REACT_APP_PROTOCOL ||
                                      "https://") + tour.main_photo[0]} />
                                ) : tour.main_photo[1] ? (
                                  <img
                                    id={tour.tour_id}
                                    // ##### 24-03-21 Komarov
                                    alt={tour.tour_name}
                                    className="TopToursImage"
                                    width={`${(width * 0.6) / 3}px`}
                                    src={(process.env.REACT_APP_PROTOCOL ||
                                      "https://") + tour.main_photo[1]} />
                                ) : null
                                  // (<LoadingMessage itemWidth={`${width*0.6/3}px`}/>)
                                }
                              </div>
                              {tour.main_photo[0] || tour.main_photo[1] ? (
                                // ##### 23-03-30 Komarov
                                <div
                                  className="TopToursTitle"
                                  style={{ width: `${(width * 0.6) / 3}px` }}
                                  id={tour.tour_id}
                                >
                                  <h4
                                    id={tour.tour_id}
                                    style={{
                                      width: `${(width * 0.6) / 3}px`,
                                    }}
                                  >
                                    {tour.tour_name.toUpperCase()}
                                  </h4>
                                </div>
                              ) : null}
                            </li>
                          );
                        }
                      })
                      : null}
                  </ul>
                </li>
              );
            })
          ) : (
            <div>{null}</div>
          )}
        </ul>
      </div>
    </div>
  );
};
