// ##### 23-03-20 Komarov: Функционал для определения ширины полосы прокрутки
// создадим элемент с прокруткой
// ##### 24-03-21 Komarov
const div = document.createElement("div");

div.style.overflowY = "scroll";
div.style.width = "50px";
div.style.height = "50px";

// мы должны вставить элемент в документ, иначе размеры будут равны 0
document.body.append(div);
// ##### 24-03-21 Komarov
export const scrollWidth = div.offsetWidth - div.clientWidth;

div.remove();

// alert(scrollWidth);
