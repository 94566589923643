// ##### 24-06-14 Komarov
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { DatePicker, Space, Alert } from "antd";
import { HotelsPaxChoice } from "../../../../Library/PageDevices/HotelsPaxChoice/HotelsPaxChoice";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { ConfigProvider } from "antd";
import dateFormats from "../../../../../helpers/dateFormats";
import { useMessages } from "../../../../../helpers/messages";
import { PlaceHolderStrings } from "../../../../Library/Localization/placeholders";
import { makeParamsString } from "../../../Helpers/helper";
import { resetHotelrates } from "../../../../../Redux/actions";
import { dateLocales } from "./InnerSearchBlock";

// ##### 23-09-14 Komarov
// import 'antd/dist/antd.css';
// import './HotelDetailsCSS.css';
// ##### 23-05-05 Komarov
export const InnerSearchBlockDiv = ({
  divClassName, buttonClassName, setNewList, setClicked, search_data,
}) => {
  // ##### 23-10-05 Komarov: код для возможности обработки позиции прокрутки окна - start
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = () => {
    setScrollPosition(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
    // ##### 24-03-21 Komarov
  }, [handleScroll, scrollPosition]);
  // ##### 23-10-05 Komarov: код для обработки позиции прокрутки окна - end
  // ##### 24-01-09 Komarov: Устанавливаем положение календарика с выбором даты в зависимости от положения прокрутки окна
  const [startDatePickerPlacement, setStartDatePickerPlacement] = useState("topLeft");
  const [endDatePickerPlacement, setEndDatePickerPlacement] = useState("topLeft");
  // ##### 24-01-09 Komarov: Высота календарика (посмотрел в Консоли разработчика)
  const calendarHeight = 308;

  function getDistanceToBottom(element) {
    // Get the element's position relative to the viewport
    const rect = element.getBoundingClientRect();

    // Get the viewport height
    const viewportHeight = window.innerHeight;

    // Calculate the distance by subtracting element bottom from viewport bottom
    const distance = viewportHeight - rect.bottom;

    return Math.max(distance, 0); // Ensure non-negative distance
  }

  // ##### 24-06-13 Komarov: Управление положением календарика на экране mobile
  useEffect(() => {
    if (divClassName === 'SearchDetailsSmallScreen') {
      const startDateBottomEdgeDistance = getDistanceToBottom(document.querySelector("#DatepickerStartDate"));
      const endDateBottomEdgeDistance = getDistanceToBottom(document.querySelector("#DatepickerEndDate"));
      if (startDateBottomEdgeDistance > calendarHeight) {
        setStartDatePickerPlacement("bottomLeft");
      } else {
        setStartDatePickerPlacement("topLeft");
      }
      if (endDateBottomEdgeDistance > calendarHeight) {
        setEndDatePickerPlacement("bottomLeft");
      } else {
        setEndDatePickerPlacement("topLeft");
      }
    }
  }, [scrollPosition]);
  // ##### 24-06-13 Komarov: Управление положением календарика на экране desktop
  useEffect(() => {
    if (divClassName !== 'SearchDetailsSmallScreen') {
      if (window.scrollY > calendarHeight) {
        setStartDatePickerPlacement("bottomLeft");
        setEndDatePickerPlacement("bottomLeft");
      } else {
        setStartDatePickerPlacement("topLeft");
        setEndDatePickerPlacement("topLeft");
      }
    }
  }, [window.scrollY]);

  // ##### 23-12-27 Komarov
  const locale = useSelector((state) => state.language);

  // ##### 24-01-05 Komarov
  const emptyStartDate = "";
  const emptyEndDate = "";
  const [startDate, setStartDate] = useState(
    search_data.start || emptyStartDate
  );
  const [endDate, setEndDate] = useState(search_data.end || emptyEndDate);
  const [paxListOpen, setPaxListOpen] = useState(false);
  // ##### 24-01-05 Komarov
  const [searchClicked, setSearchClicked] = useState(false);
  // ##### 24-01-05 Komarov
  // const [pickedStartValue, setPickedStartValue] = useState(search_data.start ? true : false);
  // const [pickedEndValue, setPickedEndValue] = useState(search_data.end ? true : false);
  const [pickedStartValue, setPickedStartValue] = useState(!!startDate);
  const [pickedEndValue, setPickedEndValue] = useState(!!endDate);
  // ##### 23-09-04 Komarov
  const history = useHistory();
  // ##### 23-03-29 Komarov
  // ##### 24-03-21 Komarov
  // const dateFormat = 'YYYY-MM-DD';
  // console.log('SEARCH_DATA',search_data)
  // ##### 24-03-21 Komarov
  function StartDateChange(_date, dateString) {
    // ##### 24-01-05 Komarov
    // setStartDate(dateString);
    setStartDate(
      (dateString &&
        moment(dateString, dateFormats[locale]).format(dateFormats.en)) ||
      emptyStartDate
    );
    // setPickedStartValue(true);
    setPickedStartValue(!!dateString);
    // ##### 24-02-10 Komarov
    if (endDate) {
      const datesDifference = daysDifference(
        new Date(endDate),
        new Date(
          dateString &&
          moment(dateString, dateFormats[locale]).format(dateFormats.en)
        )
      );
      if (datesDifference <= 0) {
        const endDate = moment(dateString, dateFormats[locale])
          .add(1, "day")
          .format(dateFormats.en);
        setEndDate(endDate);
      }
    }
  }

  // ##### 24-01-05 Komarov
  useEffect(() => {
    setSearchClicked(false);
  }, []);

  // ##### 24-03-21 Komarov
  function EndDateChange(_date, dateString) {
    // ##### 24-01-05 Komarov
    // setEndDate(dateString);
    setEndDate(
      (dateString &&
        moment(dateString, dateFormats[locale]).format(dateFormats.en)) ||
      emptyEndDate
    );
    // setPickedEndValue(true);
    setPickedEndValue(!!dateString);
  }

  // ##### 23-09-11 Komarov
  // ##### 24-01-03 Komarov: TODO: если нужно, сделать правильную функцию прибавления разницы в днях к дате заезда для вычисления значения даты выезда по умолчанию
  /* useEffect(() => {
    if (startDate != "") {
      let dateStr = startDate;
      let date = new Date(dateStr);
 
      // Add one day
      date.setDate(date.getDate() + 1);
 
      // Format the date back to a string
      let nextDayStr = date.toISOString().split("T")[0];
      EndDateChange(null, nextDayStr);
    }
  }, [startDate]); */
  // +++++
  const MakeVisible = (value) => {
    value === false || value === true
      ? setPaxListOpen(value)
      : setPaxListOpen(!paxListOpen);
  };

  /* const MakeVisible = () =>{
    setPaxListOpen(!paxListOpen)
  } */
  // ##### 24-02-10 Komarov
  const disabledDateStart = (current) => {
    return current < moment().subtract(1, "days");
  };

  // ##### 24-03-21 Komarov
  const daysDifference = (secondDate, firstDate) => {
    const difference = secondDate.getTime() - firstDate.getTime();
    const totalDays = Math.ceil(difference / (1000 * 3600 * 24));
    return totalDays;
  };

  const disabledDateEnd = (current) => {
    // ##### 24-02-10 Komarov
    return (
      current <
      moment().add(daysDifference(new Date(startDate), new Date()), "days")
    );
  };

  // ##### 23-09-04 Komarov
  const adults = String(useSelector((state) => state.paxchoice.pax).adults);
  const children = String(useSelector((state) => state.paxchoice.pax).children);
  const rooms = String(useSelector((state) => state.paxchoice.pax).rooms);

  // ##### 24-04-23 Komarov
  const CheckInDate = useMessages("CheckInDate");
  const CheckOutDate = useMessages("CheckOutDate");
  const InnerSearch_1 = useMessages("InnerSearch_1");
  const Change_Search_Details = useMessages("Change Search Details");
  // ##### 24-04-23 Komarov
  const ArrivalDateMissing = useMessages("ArrivalDateMissing");
  const DepartureDateMissing = useMessages("DepartureDateMissing");
  // ##### 24-03-01 Komarov
  const whitelabel = useSelector((state) => state.whitelabel);
  const isWhiteLabelSet = whitelabel.set;
  const company_cipher = whitelabel.company_cipher;
  const promoCode = useSelector((state) => state.promocode.promocode);

  // ##### 24-06-10 Komarov
  const dispatch = useDispatch();

// ##### 24-08-05 Komarov
  const company_id = useSelector(state => state.auth.user.company_id);

  const changeSearchDetails = () => {
    dispatch(resetHotelrates());
    // ##### 24-01-05 Komarov
    setSearchClicked(true);
    if (pickedStartValue === false) {
      // ##### 24-01-05 Komarov
      // alert('Arrival Date is missing');
      // alert(ArrivalDateMissing);
      return;
    }
    // ##### 24-03-21 Komarov
    if (pickedEndValue === false) {
      // ##### 24-01-05 Komarov
      // alert('Departure Date is missing');
      // alert(DepartureDateMissing);
      return;
    }
    // ##### 24-08-05 Komarov
    // TODO: Исправить код в соответствии с задачей убрать Промокод из URL
    if (pickedStartValue === true && pickedEndValue === true) {
      const List = {
        // ##### 24-08-05 Komarov
        // refpartner: search_data.refpartner,
        refpartner: company_id,
        selected_currency: search_data.selected_currency,
        start: startDate,
        end: endDate,
        city_id: search_data.city_id,
        hotel_id: search_data.hotel_id,
        // ##### 24-06-07 Komarov
        id: (search_data.hotel_id > 0 && search_data.hotel_id) ||
          search_data.city_id,
        cityName: search_data.cityName || "",
        // ##### 23-09-04 Komarov
        // adults: String(totalPaxRedux.adults),
        // children: String(totalPaxRedux.children),
        // rooms: String(totalPaxRedux.rooms),
        adults,
        children,
        rooms,
        // adults: totalPaxRedux.adults,
        // children: totalPaxRedux.children,
        // rooms: totalPaxRedux.rooms,
      };

      setNewList(List);
      setClicked(true);

      // ##### 24-04-17 Komarov
      // const params_string = `selected_currency=${List.selected_currency}&start=${List.start}&end=${List.end}&id=${List.hotel_id}&city_id=${List.city_id}&adults=${List.adults}&children=${List.children}&rooms=${List.rooms}&hotel_id=${List.hotel_id}`;
      // ##### 24-05-01 Komarov
      const params_string = makeParamsString({
        selected_currency: List.selected_currency,
        start: List.start,
        end: List.end,
        id: List.hotel_id,
        city_id: List.city_id,
        adults: List.adults,
        children: List.children,
        rooms: List.rooms,
        hotel_id: List.hotel_id,
        cityName: search_data.cityName || "",
        // ##### 24-05-29 Komarov
        // start: search_data.start || "",
        // end: search_data.end || "",
        hotel_name: search_data.hotelName || "",
        hotel_id: List.hotel_id,
      });

      // ##### 24-02-29 Komarov: TODO: Убрать промокод из URL
      if (isWhiteLabelSet) {
        const route_query = `?${params_string}`;

        history.push(
          `/${locale}/ref${company_cipher}/hotel_details/${route_query}`,
          [List]
        );
      } else if (promoCode) {
        // ##### 24-08-05 Komarov
        // TODO: Происходит смешивание понятий refpartner и promoCode, - нужно что-то с этим сделать
        // const route_query = `?${promoCode ? `refpartner=${promoCode}&` : ""}${params_string}`;
        const route_query = `?${params_string}`;

        history.push(`/${locale}/hotel_details/${route_query}`, [List]);
      } else {
        const route_query = `?${params_string}`;

        history.push(`/${locale}/hotel_details/${route_query}`, [List]);
      }
    }
  };
  const placeholder = PlaceHolderStrings();
  return (
    <div className={divClassName} data-component="InnerSearchBlockDiv">
      <h4>
        <span>
          {
            // ##### 23-10-04 Komarov
            CheckInDate}
        </span>
        {/* ##### 24-01-05 Komarov */}
        <Space direction="vertical">
          {/* ##### 24-01-05 Komarov: <ConfigProvider locale={dateLocales[locale]}>...</ConfigProvider> - конфигурируем локаль отображения календаря */}
          <ConfigProvider locale={dateLocales[locale]}>
            <DatePicker
              inputReadOnly // ##### 24-01-09 Komarov: свойство inputReadOnly предотвращает появление клавиатуры смартфона или планшета при касании поля выбора даты

              // ##### 24-02-10 Komarov
              disabledDate={disabledDateStart}
              // ##### 24-01-05 Komarov
              placeholder={[placeholder.placeHolderStartDate]}
              // ##### 24-01-05 Komarov
              // defaultValue={search_data.start && moment(search_data.start, dateFormat)}
              // format={dateFormat}
              // ##### 24-01-05 Komarov: moment(search_data.start, dateFormats.en) - парсим дату, которая в переменной search_data.start в виде строки, указав ("подсказав") функции moment формат, в котором в данный момент эта дата находится (dateFormats.en)
              defaultValue={search_data.start && moment(search_data.start, dateFormats.en)}
              // ##### 24-01-05 Komarov: moment(startDate, dateFormats.en) - парсим дату, которая в переменной startDate в виде строки, указав ("подсказав") функции moment формат, в котором в данный момент эта дата находится (dateFormats.en)
              value={startDate && moment(startDate, dateFormats.en)}
              // ##### 24-01-05 Komarov: указываем формат отображения даты в соотв. с текущей локалью (которая совпадает с выбранным на сайте языком)
              format={dateFormats[locale]}
              onChange={StartDateChange}
              placement={startDatePickerPlacement}
              // ##### 23-11-15 Komarov
              popupStyle={{
                textAlign: "center", // Align the calendar in the center horizontally
                marginTop: "10px", // Add some space between the input and the calendar

                // ##### 24-01-09 Komarov
                width: divClassName === "SearchDetailsSmallScreen" && "100%",
              }}
              id="DatepickerStartDate" />
          </ConfigProvider>
        </Space>
        {/* ##### 24-01-05 Komarov */}
        {searchClicked && !pickedStartValue && (
          <Alert message={ArrivalDateMissing} type="error" />
        )}
      </h4>
      <h4>
        <span>
          {
            // ##### 23-10-04 Komarov
            CheckOutDate}
        </span>
        {/* ##### 24-01-05 Komarov */}
        <Space direction="vertical">
          {/* ##### 24-01-05 Komarov: <ConfigProvider locale={dateLocales[locale]}>...</ConfigProvider> - конфигурируем локаль отображения календаря */}
          <ConfigProvider locale={dateLocales[locale]}>
            <DatePicker
              inputReadOnly // ##### 24-01-09 Komarov: свойство inputReadOnly предотвращает появление клавиатуры смартфона или планшета при касании поля выбора даты
              disabledDate={disabledDateEnd}
              // ##### 24-01-05 Komarov
              placeholder={[placeholder.placeHolderEndDate]}
              // ##### 24-01-05 Komarov
              // defaultValue={(endDate && moment(endDate, dateFormat)) || (search_data.end && moment(search_data.end, dateFormat))}
              // value={endDate && moment(endDate, dateFormat)}
              // format={dateFormat}
              // ##### 24-01-05 Komarov: moment(search_data.end, dateFormats.en) - парсим дату, которая в переменной search_data.end в виде строки, указав ("подсказав") функции moment формат, в котором в данный момент эта дата находится (dateFormats.en)
              defaultValue={(endDate && moment(endDate, dateFormats.en)) ||
                (search_data.end && moment(search_data.end, dateFormats.en))}
              // ##### 24-01-05 Komarov: moment(endDate, dateFormats.en) - парсим дату, которая в переменной endDate в виде строки, указав ("подсказав") функции moment формат, в котором в данный момент эта дата находится (dateFormats.en)
              value={endDate && moment(endDate, dateFormats.en)}
              // ##### 24-01-05 Komarov: указываем формат отображения даты в соотв. с текущей локалью (которая совпадает с выбранным на сайте языком)
              format={dateFormats[locale]}
              onChange={EndDateChange}
              // ##### 24-01-05 Komarov
              // allowClear={false}
              placement={endDatePickerPlacement}
              // ##### 23-11-15 Komarov
              popupStyle={{
                textAlign: "center", // Align the calendar in the center horizontally
                marginTop: "10px", // Add some space between the input and the calendar

                // ##### 24-01-09 Komarov
                width: divClassName === "SearchDetailsSmallScreen" && "100%",
              }}
              id="DatepickerEndDate" />
          </ConfigProvider>
        </Space>
        {/* ##### 24-01-05 Komarov */}
        {searchClicked && !pickedEndValue && (
          <Alert message={DepartureDateMissing} type="error" />
        )}
      </h4>
      <h4>
        {" "}
        <span>
          {
            // ##### 23-10-04 Komarov
            InnerSearch_1}
        </span>
        <HotelsPaxChoice
          MakeVisible={MakeVisible}
          paxListOpen={paxListOpen}
          rooms={search_data.rooms}
          adults={search_data.adults}
          children={search_data.children}
          // ##### 23-11-15 Komarov
          isSecondSearch />
      </h4>
      <h4>
        {/* ##### 24-03-21 Komarov */}
        <button
          type="submit"
          // ##### 23-02-22 Komarov
          className={buttonClassName}
          onClick={changeSearchDetails}
        >
          {
            // ##### 23-10-04 Komarov
            Change_Search_Details}
        </button>
      </h4>
      {/* ##### 23-07-03 Komarov */}
    </div>
  );
};
