// ##### 24-09-09 Komarov
import React, { useEffect, useContext, createContext, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
// ##### 24-04-12 Komarov
import { useLocation } from "react-router-dom";
import { getContent } from "../../../Redux/actions/content";
import { getHotelContent } from "../../../Redux/actions/hotelcontent";
import { LoadingMessage } from "../../Library/PageDevices/LoadingMessage";
import { CartGalleryShortVersion } from "../../Library/PageDevices/CartGallery/CartGalleryShortVersion";
// ##### 23-02-22 Komarov

import parse from "html-react-parser";
// ##### 24-04-23 Komarov
import { useMessages } from "../../../helpers/messages";
// ##### 24-04-12 Komarov
import { ValidateQuery } from "../Helpers/helper";
// ##### 24-04-17 Komarov
import uniqueId from "lodash.uniqueid";

// ##### 23-09-14 Komarov
// import './CartDetails.css'
// ##### 23-10-10 Komarov
const CartDetailsDiv = ({ className }) => {
  // ##### 24-04-23 Komarov
  const CartDetailsHeader = useMessages("CartDetailsHeader");
  const CartDetails_1 = useMessages("CartDetails_1");
  const CartDetails_2 = useMessages("CartDetails_2");
  // ##### 24-04-18 Komarov
  /* const CartDetails_3 = useMessages("CartDetails_3");
  const CartDetails_4 = useMessages("CartDetails_4");
  const CartDetails_5 = useMessages("CartDetails_5");
  const CartDetails_6 = useMessages("CartDetails_6");
  // ##### 24-04-12 Komarov
  const CartDetails_7 = useMessages("CartDetails_7");
  const Adults = useMessages("Adults");
  const Children = useMessages("Children");
  const Infant = useMessages("Infant");
  const TotalCost = useMessages("TotalCost"); */
  const BookingAddDetails = useMessages("BookingAddDetails");
  const TaxDetails = useMessages("TaxDetails");

  const hotelcontents = useSelector((state) => state.hotelcontent.hotelcontent);
  const tourcontents = useSelector((state) => state.content.content);
  // ##### 24-04-12 Komarov
  let available_now = !!useSelector(
    (state) => state.order.selectedItemInfo?.available_now
  );
  const query = ValidateQuery(useLocation());
  if (!available_now) {
    available_now = query.amount !== "No price was shown";
  }

  // ##### 24-04-18 Komarov
  /* const hotel =
    (localStorage.getItem("selectedItemInfo") &&
      JSON.parse(localStorage.getItem("selectedItemInfo"))) ||
    "No selectedItemInfo"; */

  // ##### 24-04-18 Komarov
  const { cart, cartClass /* , exchangeRate */ } =
    useContext(CartDetailsContext);

  // console.log('TOURCONTENTS',tourcontents)
  // console.log('CARTSERVICE', cart.service_type_id)

  const content =
    parseInt(cart.service_type_id) === parseInt("11")
      ? tourcontents
      : hotelcontents;

  return (
    <div
      className={`${cartClass ? cartClass : className}`}
      data-component="CartDetailsDiv"
    >
      <div style={{ display: "flex", flexDirection: "column" }}>
        <h2
          style={{
            color: "#003057",
            fontSize: "24px",
            fontWeight: "bold",
            textAlign: "center",
            order: "-2",
          }}
        >
          {
            // ##### 23-10-04 Komarov
            CartDetailsHeader
          }
        </h2>
        {content.length > 0 ? (
          content.map((item) => {
            return (
              // ##### 24-04-17 Komarov
              <React.Fragment key={uniqueId()}>
                <h3
                  style={{
                    color: "#003057",
                    fontSize: "22px",
                    textAlign: "center",
                    order: "-1",
                  }}
                >
                  {item.content_name === "Title" ? item.text : null}
                </h3>
                <div style={{ order: "0" }}>
                  {item.content_name === "Image" ? (
                    <CartGalleryShortVersion photos={item} />
                  ) : null}
                </div>
                <div
                  style={{
                    order: "1",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {item.content_name === "Summary" ? (
                    <>
                      <h4>
                        {
                          // ##### 23-10-04 Komarov
                          CartDetails_1
                        }
                      </h4>
                      <div
                        style={{
                          textAlign: "justify",
                          padding: "0.5vh",
                        }}
                      >
                        {parse(item.text)}
                      </div>
                    </>
                  ) : null}
                </div>

                <div
                  style={{
                    order: "2",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {item.content_name === "Inclusion" ? (
                    <>
                      <h4>Tour Inclusions</h4>
                      <div
                        style={{
                          textAlign: "justify",
                          padding: "0.5vh",
                        }}
                      >
                        {parse(item.text)}
                      </div>
                    </>
                  ) : null}
                </div>
              </React.Fragment>
            );
          })
        ) : (
          <div
          // ##### 23-11-22 Komarov
          /* style={{
            position: 'absolute',
            left: '40%',
            transform: 'translate(0%, -50%)',
            margin: '0'
          }} */
          >
            <LoadingMessage loadingMessageClass="RateLoading" />
          </div>
        )}
      </div>
      {/* <div>{cart.start}</div> */}
      <h4>
        {
          // ##### 23-10-04 Komarov
          CartDetails_2
        }
      </h4>
      <div className="MainRateDetails">
        {/* ##### 24-04-18 Komarov */}
        {/* <h5>
          {
            // ##### 23-10-04 Komarov
            CartDetails_3
          }
        </h5>
        <div>{cart.start}</div>

        <h5>
          {
            // ##### 23-10-04 Komarov
            CartDetails_4
          }
        </h5>
        <div>{cart.end}</div>

        <h5>
          {
            // ##### 23-10-04 Komarov
            CartDetails_5
          }
          {/* </h5><div>{cart.htlName.replace('%20',' ')}</div> /}
        </h5>
        <div>
          {content.length > 0
            ? content.map((item) => {
              // ##### 24-04-17 Komarov
                return <React.Fragment key={uniqueId()}>
                  {item.content_name === "Title" ? item.text : null}
                </React.Fragment>;
              })
            : null}
        </div>

        <h5>
          {
            // ##### 24-04-12 Komarov
            (available_now && CartDetails_6) || CartDetails_7
          }
        </h5>
        <div />

        <h5>
          {
            // ##### 23-10-04 Komarov
            Adults
          }
        </h5>
        <div>{cart.adults}</div>

        <h5>
          {
            // ##### 23-10-04 Komarov
            Children
          }
        </h5>
        <div>{cart.children}</div>

        <h5>
          {
            // ##### 23-10-04 Komarov
            Infant
          }
        </h5>
        <div>{cart.infants > 0 ? cart.infants : 0}</div>

        {/* ##### 24-04-13 Komarov /}
        {(available_now || hotel.dates) && (
          <>
            <h5>
              {
                // ##### 23-10-04 Komarov
                TotalCost
              }
            </h5>
            <div>
              {/* ##### 24-04-13 Komarov /}
              {/* {cart.selected_currency} {Math.round(cart.amount / exchangeRate)} /}
              {cart.selected_currency}{" "}
              {parseFloat(cart.amount / exchangeRate).toFixed(2)}
            </div>
          </>
        )} */}

        <h5>
          {
            // ##### 23-10-04 Komarov
            BookingAddDetails
          }
        </h5>
        <div className="TaxDetails">
          {
            // ##### 23-10-04 Komarov
            TaxDetails
          }
        </div>
      </div>
    </div>
  );
};

const CartDetailsContext = createContext();

export const CartDetails = ({ cart, cartClass }) => {
  // console.log('CART', cart)
  const dispatch = useDispatch();
  // ##### 23-12-27 Komarov
  const locale = useSelector((state) => state.language);

  // const currencies = useSelector(state=>state.currency.currencies)
  // @@@@@ 23-01-30 Komarov: Исправил ошибку определения того, какой массив currencies нужно взять
  // ##### 24-07-25 Komarov
  const currenciesFromState = useSelector((state) => state.currency.currencies);
  const currencies = currenciesFromState.length > 0 ? currenciesFromState : JSON.parse(localStorage.getItem("currency")).currencies;

  let exchangeRate;

  // ##### 24-03-21 Komarov
  for (const curr of currencies) {
    if (curr.name === cart.selected_currency)
      exchangeRate = parseInt(curr.value);
  }

  useEffect(() => {
    // ##### 24-03-21 Komarov
    process.env.NODE_ENV === "development" &&
      console.log(
        "axios:src/Components/Pages/BookingForm/CartDetails.js:getHotelContent(cart.hotel_id,locale)"
      );
    dispatch(getHotelContent(cart.hotel_id, locale));
    // ##### 23-04-12 Komarov
  }, [cart.hotel_id, locale, dispatch]);

  useEffect(() => {
    // ##### 24-03-21 Komarov
    process.env.NODE_ENV === "development" &&
      console.log(
        "axios:src/Components/Pages/BookingForm/CartDetails.js:getContent(cart.contract_id,locale)"
      );
    dispatch(getContent(cart.contract_id, locale));
    // ##### 23-04-12 Komarov
  }, [cart.contract_id, locale, dispatch]);
  // console.log('myCONTENT',content)

  // useEffect ( () => {
  //     axios.get(`${process.env.REACT_APP_SMART_URL}interface/content?id=${cart.contract_id}&language=en`) //former cart.tour_id
  //       .then( res => {
  //         setTourContent(res.data)
  //         })

  //     .catch( error => {
  //         setTourContent(undefined)
  //       console.log( '[axios error] : ' , error)
  //        });
  //    }, []);
  //    console.log('[BRIEFCONTENT]',tourContent)

  // ##### 24-09-11 Komarov
  const cartDetailsContextValue = useMemo(() => ({ cart, cartClass, exchangeRate }), [cart, cartClass, exchangeRate]); // value is cached by useMemo

  return (
    <CartDetailsContext.Provider value={cartDetailsContextValue}>
      {/* ##### 23-11-09 Komarov */}
      <CartDetailsDiv className={cartClass} />
    </CartDetailsContext.Provider>
  );
};
