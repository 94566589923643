import { combineReducers } from "redux";

import posts from "./posts";
import hotels from "./hotels";
import tours from "./tours";
import cities from "./cities";
import content from "./content";
import pages from "./pages";
import auth from "./auth";
import order from "./order";
import voucher from "./voucher";
import payment from "./payment";
import message from "./message";
import toptours from "./toptours";
import tophotels from "./tophotels";
import paxchoice from "./paxchoice";
import hotelcontent from "./hotelcontent";
import hotelrates from "./hotelrates";
import hotelroomschoice from "./hotelroomschoice";
import availabilitychoice from "./availabilitychoice";
import hotelsearchdata from "./hotelsearchdata";
import locale from "./locale";
import hotelcities from "./hotelcities";
import currency from "./currency";
import promocode from "./promocode";
// ##### 24-02-26 Komarov
import whitelabel from "./whitelabel";
import routes from "./routes";
import hotelchoiceintours from "./hotelchoiceintours";
import tourcontents from "./tourcontents";
import usersReducer from "./usersReducer";
import usersReducer2 from "./usersReducer2";
import usersReducer3 from "./usersReducer3";
import setDeviceType from "./setDeviceType";
// ##### 24-03-15 Komarov
// import setDeviceModel from './setDeviceModel';
import setCurrentUrl from "./setCurrentUrl";
// ##### 24-07-22 Komarov
import setOthersName from "./setOthersName";
// ##### 23-10-23 Komarov
// import setIpInfo from './setIpInfo';
import setLanguage from "./setLanguage";
import setData from "./setData";
// ##### 24-05-10 Komarov
import hotelsContext from "./hotelsContext";
// ##### 24-05-29 Komarov
import setSitePageListSection from "./setSitePageListSection";
// ##### 24-06-17 Komarov
import gallery from "./gallery";
// ##### 24-07-24 Komarov
import setCountry from "./setCountry";

//{combineReducers} - это библиотека из редакса для комбинации нескольких редьюсеров
const root_reducer = combineReducers({
  posts,
  hotels,
  tours,
  cities,
  content,
  pages,
  toptours,
  tophotels,
  auth,
  order,
  voucher,
  payment,
  message,
  paxchoice,
  hotelcontent,
  hotelrates,
  hotelroomschoice,
  availabilitychoice,
  hotelsearchdata,
  locale,
  hotelcities,
  currency,
  promocode,
  // ##### 24-02-26 Komarov
  whitelabel,
  routes,
  hotelchoiceintours,
  tourcontents,
  users: usersReducer,
  users2: usersReducer2,
  users3: usersReducer3,
  deviceType: setDeviceType,
  // ##### 24-03-15 Komarov
  // deviceModel: setDeviceModel,
  currentUrl: setCurrentUrl,
  // ##### 24-07-22 Komarov
  othersName: setOthersName,
  // ##### 23-10-23 Komarov
  // ipInfo: setIpInfo,
  language: setLanguage,
  // ##### 24-07-24 Komarov
  country: setCountry,
  data: setData,
  hotelsContext,
  // ##### 24-05-29 Komarov
  sitePageListSection: setSitePageListSection,
  // ##### 24-06-17 Komarov
  gallery,
});

export default root_reducer;
