import axios from "../helpers/public.axios";

import {
  GET_HOTEL_CONTENT_REQ,
  GET_HOTEL_CONTENT_RES,
  GET_HOTEL_CONTENT_ERR,
} from "../constants";

//action creator
export const hotelContentResponse = (res) => ({
  type: GET_HOTEL_CONTENT_RES,
  payload: res,
});

// console.log('[CONTENTRESPONCE]' , contentResponse)

// ##### 24-09-16 Komarov
export const getHotelContent = (id, lang, user_id) => (dispatch) => {
  // ##### 23-03-06 Komarov
  process.env.NODE_ENV === "development" &&
    console.log("axios:src/Redux/actions/hotelcontent.js:getHotelContent");

  dispatch({ type: GET_HOTEL_CONTENT_REQ });

  // ##### 24-09-11 Komarov
  // const url = `${process.env.REACT_APP_SMART_URL}interface/hotelcontent?id=${id}&language=${lang}`;
  // ##### 24-09-16 Komarov
  const url = `${process.env.REACT_APP_SMART_URL}interface/hotelcontent?id=${id}&language=${lang}&hotelview=1&user_id=${user_id}`;
  process.env.NODE_ENV === "development" &&
    console.log(
      "axios:src/Redux/actions/hotelcontent.js:getHotelContent, url:",
      url
    );
  axios
    .get(url)
    .then((res) => {
      const filtered_res = res.data.filter(
        (item, index, array) =>
          array.findIndex((t) => t.content_name === item.content_name) === index
      );

      dispatch(hotelContentResponse(filtered_res));
      // console.log('[REDUX_HOTEL_CONTENT]:' , res.data)
    })

    .catch((err) => {
      dispatch({ type: GET_HOTEL_CONTENT_ERR, error: err });
    });
};
