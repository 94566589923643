// ##### 23-03-29 Komarov
import React from "react";
// ##### 23-09-14 Komarov
// import './App.css';
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
// import {ScrollToTop} from './Components/Library/PageDevices/ScrollToTop';
import { ScrollToTopButton } from "./Components/Library/PageDevices/ScrollToTopButton/ScrollToTopButton";
// ##### 23-06-19 Komarov
import { SetAppData } from "./Components/Pages/Helpers/SetAppData";
import { LocalizationWrapper } from "./LocalizationWrapper";
// ##### 23-09-14 Komarov
// import "bootstrap/dist/css/bootstrap.min.css";
import PaymentService from "./Redux/services/payment.service";
import { PaymentServiceProvider } from "./Components/Context/PaymentServiceContext";
import config from "./Redux/config";
import { history } from "./Redux/helpers/history";
// ##### 23-12-28 Komarov
import { loggedIn } from "./Redux/actions/auth";
// ##### 24-07-25 Komarov
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
// ##### 24-08-05 Komarov
import moment from "moment";
import { setPromoCode } from "./Redux/actions/promocode";

const paymentService = new PaymentService();

const supportedLangs = config.supportedLangs.join("|");
// ##### 24-03-21 Komarov
process.env.NODE_ENV === "development" &&
  console.log("src/App.js:window.INITIAL_STATE", window.INITIAL_STATE);

// ##### 23-06-19 Komarov
function App({ isMarkupPresent }) {

  // ##### 24-08-05 Komarov
  function getCurrentTime() {    
    return moment();
  }
  
  function isTimeDifferenceLessThanMinute(oldTime) {
    // Calculate the difference in milliseconds
    const diffInMilliseconds = moment().diff(oldTime);
  
    // Convert milliseconds to minutes
    const diffInMinutes = diffInMilliseconds / (1000 * 60);
  
    return Math.abs(diffInMinutes) < 1;
  }

  const dispatch = useDispatch();

  // ##### 23-12-28 Komarov: Если пользователь был в системе, то атворизуем его
  const user =
    localStorage.getItem("user") && JSON.parse(localStorage.getItem("user"));
  useEffect(() => {
    if (!user || user === "undefined") {
      return;
    }
    dispatch(loggedIn(user));
    // ##### 24-03-21 Komarov
  }, [dispatch, user]);

  // ##### 24-08-05 Komarov
  const promocode = useSelector(state => state.promocode.promocode);

  // ##### 24-08-03 Komarov: Функционал для сохранения Промокода в Redux при работе приложения, когда оно переключается на новый URL и Redux "сбрасывается" к начальному состоянию
  useEffect(() => {
    // Actions to perform on app startup
    const savedPromocode = localStorage.getItem('promocode') && JSON.parse(localStorage.getItem('promocode'));
    if (savedPromocode && isTimeDifferenceLessThanMinute(savedPromocode.timePromocodeSet)) {
      dispatch(setPromoCode(savedPromocode.promocode));
    }
    savedPromocode && localStorage.removeItem('promocode');

    // ##### 24-08-03 Komarov: Перед закрытием приложения или переключением его на новый URL сохраняем значение Промокода в localStorage
    const savePromocode = () => {
      localStorage.setItem('promocode', JSON.stringify({
        timePromocodeSet: getCurrentTime(),
        promocode
      }));
    }

    // Handle beforeunload event
    window.addEventListener('beforeunload', 
      // Actions to perform before app closure
      savePromocode);

    return () => {
      // Cleanup function
      window.removeEventListener('beforeunload', savePromocode);
    };
  }, [dispatch, promocode]);

  // @@@@@ 22-09-28 Komarov: записываем объекты (pages, purePagesSsr, currency, tophotels) в localStorage
  // ##### 23-03-28 Komarov: добавил язык в функцию SetAppData
  // ##### 24-07-25 Komarov
  const language = useSelector(state => state.language);
  // ##### 24-09-05 Komarov: отложил выполнение SetAppData, чтобы не блокировалась отрисовка станицы
  // !isMarkupPresent && SetAppData("all", language);
  useEffect(() => {
    const timeout = setTimeout(() => {
      !isMarkupPresent && SetAppData("all", language);
    }, 0);
    return clearTimeout(timeout);
  }, []);

  // ##### 31-05-24 Komarov
  return (
    <>
      <BrowserRouter history={history}>
        <PaymentServiceProvider value={paymentService}>
          <Switch>
            <Route
              path={`/:locale(${supportedLangs})`}
              component={LocalizationWrapper}
            />
            <Redirect to={config.defaultLang} />
          </Switch>
          {/* ##### 23-07-27 Komarov */}
          {/* <ScrollToTop /> */}
        </PaymentServiceProvider>
      </BrowserRouter>
      <ScrollToTopButton />
    </>
  );
}

export default App;
