// ##### 24-03-21 Komarov
import React, { useState, useRef, useEffect } from "react";
import { NavComponent } from "./NavComponent";
import { MenuBar } from "../../../Library/Icons/menubar";
import { MenuClose } from "../../../Library/Icons/menuclose";
// ##### 23-11-01 Komarov
import { Media } from "../../../../helpers/MediaConfig";
import listenForOutsideClicks from "../../FirstPageofSearchModule/HotelsAutocompleteBlock/helpers/listen-for-outside-clicks";

// ##### 23-03-01 Komarov
const SmallScreensNavBarButton = ({ className, toggle, translate }) => {
  // ##### 24-03-21 Komarov
  return (
    <button
      type="button"
      className={className}
      onClick={toggle}
      data-component="SmallScreensNavBarButton"
    >
      {" "}
      {/* toggle translate */}
      {/* change the btn text based on whether translate is true or false */}
      {/* {translate?<CloseOutlined className='MenuButtonIcon'/>:<UnorderedListOutlined className='MenuButtonIcon'/>} */}
      {translate ? (
        <span className="CloseMenuIcon">
          <MenuClose />
        </span>
      ) : (
        <span className="MenuButtonIcon">
          <MenuBar />
        </span>
      )}
    </button>
  );
};

// ##### 24-01-12 Komarov
const SmallScreensNavBar = ({ /* width,  */sitepage_type, linkClassName }) => {
  // console.log("SMALLkk",sitepage_type)
  // ##### 24-01-12 Komarov
  const [translate, setTranslate] = useState(false);
  // ##### 24-08-08 Komarov
  const [sidebarListWidth, setSidebarListWidth] = useState();

  const sidebarListRef = useRef(null);
  useEffect(() => {
    setSidebarListWidth(sidebarListRef.current.getBoundingClientRect().width);
  }, [sidebarListRef.current])
  const menuRef = useRef(null);
  const [listening, setListening] = useState(false);
  // const [isOpen, setIsOpen] = useState(false);
  // const toggle = () => setIsOpen(!isOpen);
  const toggle = (e) => {
    setTranslate(!translate);
    // ##### 24-04-17 Komarov: Этим методом stopPropagation() останавливаем передачу события клика на другие слои компонентов, иначе (без применения этого метода) событие клика срабатывает дважды, и значение translate становится сначала true, и потом сразу false
    e.stopPropagation();
  };

  useEffect(
    listenForOutsideClicks(listening, setListening, menuRef, setTranslate)
  );

  // ##### 23-03-27 Komarov: sitePageType и sitePageRegion здесь не используются, но вызываются функции для их заполнения и используют ресурсы (замедляя сайт)
  // const sitePageType = SitePageType();
  // const sitePageRegion = SitePageRegion();
  // ##### 24-03-21 Komarov
  // const deviceType = useSelector(state => state.deviceType);
  // ##### 24-06-12 Komarov
  return (
    <div ref={menuRef} data-component="SmallScreensNavBar">
      {/* ##### 23-11-01 Komarov */}
      <Media lessThan="Mobile-L-425-768">
        <SmallScreensNavBarButton
          className="hamburger-btn"
          toggle={toggle}
          translate={translate}
        />
      </Media>
      <Media between={["Mobile-L-425-768", "Laptop-1024-1440"]}>
        <SmallScreensNavBarButton
          className="hamburger-btn-mobile-landscape"
          toggle={toggle}
          translate={translate}
        />
      </Media>
      <Media greaterThanOrEqual="Laptop-1024-1440">
        <SmallScreensNavBarButton
          className="hamburger-btn"
          toggle={toggle}
          translate={translate}
        />
      </Media>

      {/* /*hide and show the sidebar list based on whether translate is true or false*/}
      <div
        id="sidebar-list"
        // ##### 24-08-08 Komarov
        ref={sidebarListRef}
        //   style={{width:width*0.8}}
        className={`${translate ? "addTransition" : "removeTransition"}`}
      >
        <NavComponent
          // navClass="nav-small"
          // linkClassName = "nav-small-link"
          // $$$$$ 22-09-15 Komarov
          linkClassName={linkClassName}
          // pages={pages}
          sitepage_type={sitepage_type}
          // ##### 24-08-08 Komarov
          sidebarListWidth={sidebarListWidth}
        // width={width}
        // onClick={() => setTranslate(false)} //set translate to false to hide the sidebar list
        />
      </div>
    </div>
  );
};
export default SmallScreensNavBar;
