// ##### 24-09-09 Komarov
import React, { createContext, useMemo } from "react";
import { Media } from "../../../../../helpers/MediaConfig";
import { LargeScreenTopToursDiv } from "./LargeScreenTopToursDiv";

// ##### 24-09-09 Komarov
export const LargeScreenTopTours = ({
  TopToursContents, GetTourDetails, TourTypes,
}) => {
  const largeScreenTopToursContextValue = useMemo(() => ({
    TopToursContents,
    GetTourDetails,
    TourTypes,
  }), [
    // ##### 24-09-11 Komarov
    TopToursContents,
    GetTourDetails,
    TourTypes,
  ]);
  return (
    <LargeScreenTopToursContext.Provider
      value={largeScreenTopToursContextValue}
    >
      {/* ##### 23-11-08 Komarov */}
      <Media at="Tablet-768-1024">
        <LargeScreenTopToursDiv width={1180} />
      </Media>
      <Media greaterThan="Tablet-768-1024">
        <LargeScreenTopToursDiv width={1280} />
      </Media>
    </LargeScreenTopToursContext.Provider>
  )
};

// ##### 23-10-10 Komarov

export const LargeScreenTopToursContext = createContext();
