import React, { useRef } from "react";
// ##### 24-05-10 Komarov
import { useSelector } from "react-redux";
import moment from "moment";
import { Media } from "../../../../../helpers/MediaConfig";
import uniqueId from "lodash.uniqueid";
import { MonthBlock } from "./MonthBlock";
// ##### 24-05-15 Komarov
import { Virtuoso } from "react-virtuoso";

// ##### 24-05-13 Komarov
export const YearsMonths = ({ markedDates, calendarHeader }) => {
  const yearsMonths = useRef();
  // ##### 24-05-20 Komarov
  const selectedDays = useRef();
  // ##### 24-05-10 Komarov
  const locale = useSelector((state) => state.language);
  const monthsCount = 36;
  // ##### 24-01-17 Komarov: Включаем в настройках moment текущую локаль
  moment.locale(locale);
  const currentMonth = moment().month();
  const monthsHelperNumbers = [];
  for (let i = 0; i < monthsCount; i++) {
    monthsHelperNumbers.push(i);
  }
  return (
    <div
      data-component="YearsMonths"
      ref={yearsMonths}
      style={{
        // height: "85vh",
        // flex: 1,
        // overflowY: "scroll",
        // border: "1px solid #ccc",
        // padding: "10px",
        // marginBottom: "10px",
        // width: "100%",
        // display: "flex",
        // flexDirection: "column",
        // alignItems: "center",
        width: "100%",
      }}
      id="YearsMonths"
    >
      {/* ##### 24-05-16 Komarov */}
      <Media lessThan="Tablet-768-1024" style={{ height: "87vh" }}>
        <Virtuoso
          style={{
            flex: 1,
            overflowY: "scroll",
            border: "1px solid #ccc",
            padding: "10px",
            marginBottom: "10px",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
          totalCount={monthsCount}
          itemContent={(index) => (
            <MonthBlock
              key={uniqueId()}
              month={currentMonth + index}
              yearsMonths={yearsMonths}
              markedDates={markedDates}
              calendarHeader={calendarHeader}
              selectedDays={selectedDays}
            />
          )}
        />
        {/* {monthsHelperNumbers.map((number) => {
          return (
            <MonthBlock
              key={uniqueId()}
              month={currentMonth + number}
              yearsMonths={yearsMonths}
              markedDates={markedDates}
              calendarHeader={calendarHeader}
            />
          );
        })} */}
      </Media>
      {/* ##### 24-06-10 Komarov */}
      <Media at="Tablet-768-1024" style={{ height: "100vh" }}>
        {/* {monthsHelperNumbers.map((number) => {
          if (number % 2 === 0) return;
          return (
            <div
              key={uniqueId()}
              style={{
                display: "flex",
              }}
            >
              <MonthBlock
                key={uniqueId()}
                month={currentMonth + number - 1}
                yearsMonths={yearsMonths}
                markedDates={markedDates}
                calendarHeader={calendarHeader}
                selectedDays={selectedDays}
              />
              <MonthBlock
                key={uniqueId()}
                month={currentMonth + number}
                yearsMonths={yearsMonths}
                markedDates={markedDates}
                calendarHeader={calendarHeader}
                selectedDays={selectedDays}
              />
            </div>
          );
        })} */}
        <Virtuoso
          style={{
            flex: 1,
            overflowY: "scroll",
            border: "1px solid #ccc",
            padding: "10px",
            marginBottom: "10px",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
          totalCount={Math.floor(monthsCount / 2)}
          itemContent={(index) => {
            return (
              <div
                key={uniqueId()}
                style={{
                  display: "flex",
                }}
              >
                <MonthBlock
                  key={uniqueId()}
                  month={currentMonth + index * 2}
                  yearsMonths={yearsMonths}
                  markedDates={markedDates}
                  calendarHeader={calendarHeader}
                  selectedDays={selectedDays}
                />
                <MonthBlock
                  key={uniqueId()}
                  month={currentMonth + index * 2 + 1}
                  yearsMonths={yearsMonths}
                  markedDates={markedDates}
                  calendarHeader={calendarHeader}
                  selectedDays={selectedDays}
                />
              </div>
            );
          }}
        />
      </Media>
    </div>
  );
};
