// ##### 24-09-09 Komarov
import React, { useContext, createContext, useMemo } from "react";
import { Media } from "../../../../helpers/MediaConfig";
// ##### 24-05-22 Komarov
import { SearchContext } from "./SearchFront";

// ##### 23-11-02 Komarov
const SwitcherDiv = ({ className = null, width, buttonClassName = "" }) => {
  const { children, active, changeHandler } = useContext(SwitcherContext);
  // ##### 24-05-22 Komarov: switcherRef - ссылка для отображения react-портала в этом html-элементе
  const { switcherRef } = useContext(SearchContext);
  return (
    <div
      className={className}
      style={{ width }}
      ref={switcherRef}
      data-component="SwitcherDiv"
    >
      {" "}
      {React.Children.count(children) > 0 && (
        <>
          {React.Children.map(children, (child) => {
            if (React.isValidElement(child)) {
              return React.cloneElement(child, {
                active: child.props.value === active,
                changeHandler: changeHandler(child.props.value),
                // ##### 23-11-02 Komarov
                buttonClassName,
              });
            }
          })}
        </>
      )}
    </div>
  );
};

// ##### 23-04-25 Komarov
const SwitcherButton = ({ className }) => {
  const { changeHandler, children } = useContext(SwitcherItemContext);
  // ##### 24-03-21 Komarov
  return (
    <button
      type="button"
      className={className}
      onClick={changeHandler}
      data-component="SwitcherButton"
    >
      {children}
    </button>
  );
};

// ##### 23-10-10 Komarov
const SwitcherContext = createContext();

export const Switcher = (props) => {
  // ##### 24-03-21 Komarov
  const { children, active, changeHandler } = props;

  // ##### 24-09-09 Komarov
  const switcherContextValue = useMemo(() => ({
    children,
    active,
    changeHandler,
  }), [
    // ##### 24-09-11 Komarov
    children,
    active,
    changeHandler,
  ]);

  // ##### 23-03-01 Komarov
  return (
    <SwitcherContext.Provider
      value={switcherContextValue}
    >
      {/* ##### 23-10-31 Komarov */}
      <Media greaterThanOrEqual="Laptop-1024-1440">
        <SwitcherDiv
          className="switcher"
          // width: '16em'
          width="12em"
          // ##### 23-11-02 Komarov
          buttonClassName="switcher__item"
        />
      </Media>
      <Media lessThan="Tablet-768-1024">
        <SwitcherDiv
          className="switcher_mobile"
          width="100vw"
          buttonClassName="switcher__item_mobile"
        />
      </Media>
      <Media between={["Tablet-768-1024", "Laptop-1024-1440"]}>
        <SwitcherDiv
          className="switcher_mobile"
          width="100vw"
          buttonClassName="switcher__item_tablet_portrait"
        />
      </Media>
    </SwitcherContext.Provider>
  );
};

const SwitcherItemContext = createContext();

export const SwitcherItem = (props) => {
  // ##### 23-03-29 Komarov
  const { children, active, changeHandler, buttonClassName } = props;
  // console.log(props);
  const switcherItemContextValue = useMemo(() => ({
    changeHandler,
    children,
  }), [
    // ##### 24-09-11 Komarov
    changeHandler,
    children,
  ]);

  // ##### 23-11-02 Komarov
  return (
    <SwitcherItemContext.Provider
      value={switcherItemContextValue}
    >
      {/* ##### 23-06-27 Komarov */}
      <SwitcherButton className={buttonClassName + (active ? " active" : "")} />
    </SwitcherItemContext.Provider>
  );
};
