// ##### 24-06-14 Komarov
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { getGeneralGeo, getTopTours } from "../../../../../Redux/actions";
import { LoadingOutlined } from "@ant-design/icons";
// ##### 24-04-23 Komarov
import { useMessages } from "../../../../../helpers/messages";
// ##### 23-11-08 Komarov
import { Media } from "../../../../../helpers/MediaConfig";
import { LargeScreenTopTours } from "./LargeScreenTopTours";
import { MediumScreenTopTours } from "./MediumScreenTopTours";
import { SmallScreenTopTours } from "./SmallScreenTopTours";

// ##### 23-09-14 Komarov
// import './TopToursCSS.css'

export const TopTours = () => {
  // ##### 23-12-27 Komarov
  const locale = useSelector((state) => state.language);
  const dispatch = useDispatch();
  // ##### 23-03-28 Komarov: toptours не используется
  // const toptours = useSelector(state => state.cities.gen_locs)
  // const TopToursContents = useSelector(state => state.toptours.toptours)
  // ##### 23-04-12 Komarov
  const topToursFromState = useSelector((state) => state.toptours.toptours);

  const history = useHistory();

  // ##### 24-07-25 Komarov
  const citiesGenLocs = useSelector(state => state.cities.gen_locs);

  ///получаю из смарта тур имя, тур айди, сити имя, сити айди
  useEffect(() => {
    // ##### 24-07-25 Komarov
    (process.env.NODE_ENV === "development" &&
    citiesGenLocs.length > 0) ||
      console.log(
        "axios:src/Components/Pages/PageComponents/HomePage/TopTours.js:getGeneralGeo()"
      );
    // dispatch (getGeneralGeo ());
    citiesGenLocs.length > 0 ||
      dispatch(getGeneralGeo());
    // ##### 23-04-12 Komarov
  }, [dispatch]);

  useEffect(() => {
    // ##### 24-07-25 Komarov
    (process.env.NODE_ENV === "development" &&
    topToursFromState.length > 0) ||
      console.log(
        "axios:src/Components/Pages/PageComponents/HomePage/TopTours.js:getTopTours()"
      );
    // dispatch (getTopTours ());
    topToursFromState.length > 0 ||
      dispatch(getTopTours());
    // ##### 23-04-12 Komarov
  }, [dispatch]);

  if (!topToursFromState) {
    return (
      <div data-component="TopTours">
        {" "}
        <LoadingOutlined />
      </div>
    );
  }

  //  const TourTypesFiltered = TopToursContents.filter((item,index,array) =>
  //  array.findIndex(t => parseInt(t.subservices.id) === parseInt(item.subservices.id))===index).map((item)=>item.subservices.name)

  // console.log('[TourTypesFiltered]', TourTypesFiltered)

  // ##### 24-07-25 Komarov
  const TourTypesFiltered = topToursFromState.filter(
    (item) => item.main_photo.length > 0
  )
    .filter(
      (item1, index1, array1) =>
        array1.findIndex(
          (t) => parseInt(t.subservices.id) === parseInt(item1.subservices.id)
        ) === index1
    )
    .map((item) => item.subservices.name);

  // console.log('[TTtest]', TTtest)

  // ##### 24-04-23 Komarov
  const chooseAnotherTour =
    useMessages("ChooseAnotherTour") || "Please choose another tour";

  const GetTourDetails = (e) => {
    let route_query;
    if (e.target.id) {
      route_query = `/${locale}/toptours/?tour_id=${e.target.id}`;
    } else {
      route_query = "/";
      // ##### 23-09-07 Komarov
      alert(chooseAnotherTour);
    }

    // history.push(`/toptours/${route_query}`)
    history.push(route_query);
    // console.log('[HISTORY : ] ', history)
  };

  // console.log('[TopToursContents]',TopToursContents)

  return (
    <div
      style={{ order: "4", marginLeft: "auto", marginRight: "auto" }}
      data-component="TopTours"
    >
      {/* ##### 23-11-08 Komarov */}
      <Media greaterThanOrEqual="Tablet-768-1024">
        <LargeScreenTopTours
          TopToursContents={topToursFromState}
          TourTypes={TourTypesFiltered}
          GetTourDetails={GetTourDetails}
        />
      </Media>
      <Media at="Mobile-L-425-768">
        <MediumScreenTopTours
          TopToursContents={topToursFromState}
          TourTypes={TourTypesFiltered}
          GetTourDetails={GetTourDetails}
        />
      </Media>
      <Media lessThan="Mobile-L-425-768">
        <SmallScreenTopTours
          TopToursContents={topToursFromState}
          TourTypes={TourTypesFiltered}
          GetTourDetails={GetTourDetails}
        // width={width}
        />
      </Media>
    </div>
  );
};
