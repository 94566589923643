// ##### 23-03-29 Komarov
import React, { useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
// ##### 24-08-05 Komarov
// import config from "../../../Redux/config";

// import {OfflineSummary} from './OfflineSummary'

// ##### 23-02-20 Komarov
export const IncompleteSendEmail = ({
  name,
  surname,
  phone,
  email,
  smart_service_id,
  cart,
}) => {
  // TODO: Передать сюда объект cart
  // console.log('IncompleteSendEmail-cart: ', cart)
  const { user: currentUser } = useSelector((state) => state.auth);
  // ##### 24-08-05 Komarov
  const company_id = useSelector(state => state.auth.user.company_id);

  // const [ModifyClientsRQ_Add, setModifyClientsRQ_Add] = useState([]);

  useEffect(() => {
    // ##### 24-03-21 Komarov
    /* const ActionRQ = {
            username:process.env.REACT_APP_SMART_USERNAME,
            password:process.env.REACT_APP_SMART_PASSWORD,
            // "user_id" :1426,
            user_id :currentUser.user_id,
            refpartner:cart.refpartner?cart.refpartner:null,
            // "refpartner":null,
            action:"ModifyClientsRQ",
            data :
                {
                    // ##### 23-02-20 Komarov
                    service_id : smart_service_id,
                    service_number : 1,
                    clients :
                        [
                            {
                                operation : "add",
                                client_type : "buyer",
                                site_client_id : 1,
                                name,
                                surname,
                                phone,
                                email
                            },
                            // {
                            //     "operation" : "add",
                            //     "client_type" : "client",
                            //     "site_client_id" : 2,
                            //     "name" : "Gogel",
                            //     "surname" : "Mogel",
                            //     "phone" : "+38 (044) 490 7114",
                            //     "email" : "atest@arktur.ua"
                            // },
                        ],
                    cart
                }
        }; */
    const ActionRQ = {
      username: process.env.REACT_APP_SMART_USERNAME,
      password: process.env.REACT_APP_SMART_PASSWORD,
      // "user_id" :1426,
      user_id: currentUser.user_id,
      // ##### 24-08-05 Komarov
      // refpartner:cart.refpartner?cart.refpartner:null,
      refpartner: company_id,
      // "refpartner":null,
      action: "ModifyClientsRQ",
      "data:service_id": smart_service_id,
      "data:service_number": 1,
      "data:clients:1:operation": "add",
      "data:clients:1:client_type": "buyer",
      "data:clients:1:site_client_id": 1,
      "data:clients:1:name": name,
      "data:clients:1:surname": surname,
      "data:clients:1:phone": phone,
      "data:clients:1:email": email,
      // "data:clients:2:operation" : "add",
      // "data:clients:2:client_type" : "client",
      // "data:clients:2:site_client_id" : 2,
      // "data:clients:2:name" : "Gogel",
      // "data:clients:2:surname" : "Mogel",
      // "data:clients:2:phone" : "+38 (044) 490 7114",
      // "data:clients:2:email" : "atest@arktur.ua",
      // "data:cart": cart
      "data:cart:adults": cart.adults || "No info",
      "data:cart:amount": cart.amount || "No info",
      "data:cart:children": cart.children || "No info",
      "data:cart:contract_id": cart.contract_id || "No info",
      "data:cart:end": cart.end || "No info",
      "data:cart:hotel_id": cart.hotel_id || "No info",
      "data:cart:htlName": cart.htlName || "No info",
      "data:cart:nrf": cart.nrf || "No info",
      "data:cart:numberofunits": cart.numberofunits || "No info",
      "data:cart:room_id": cart.room_id || "No info",
      "data:cart:selected_currency": cart.selected_currency || "No info",
      "data:cart:service_type_id": cart.service_type_id || "No info",
      "data:cart:start": cart.start || "No info",
      "data:cart:tariff_id": cart.tariff_id || "No info",
    };

    // ##### 23-01-31 Komarov: исправил адрес системки отправки почты на актуальный
    // axios.post('http://arkturdmc.com.ua:8011/', JSON.stringify({ActionRQ}))
    // axios.post('http://arktur.ua:8011/', JSON.stringify({ActionRQ}))
    axios.post(
      `${process.env.REACT_APP_URL}/online_summary`,
      JSON.stringify({ ActionRQ })
    );
    // ##### 24-03-21 Komarov
    /* .then(response => {
                // console.log('RESPONSE', response)
                // setSendCart(response.data[0])
            })
            .catch(error =>{
                // setSendCart(undefined)
                // console.log('[axios error]: ', error)
            }); */
    // ##### 23-04-12 Komarov
  }, [
    currentUser.user_id,
    smart_service_id,
    name,
    surname,
    phone,
    email,
    cart,
  ]);

  // console.log('ModifyClientsRQ_Add',ModifyClientsRQ_Add)

  return <>{/* <div>Email sent.</div> */}</>;
};
