// ##### 24-09-09 Komarov
import React, { createContext, useMemo } from "react";
import moment from "moment";

import "moment/locale/uk";

// ##### 23-09-14 Komarov
// import './HotelItemsCSS.css'
import config from "../../../../../Redux/config";
// ##### 24-04-23 Komarov
// ##### 23-11-02 Komarov
import { Media } from "../../../../../helpers/MediaConfig";
import { DivTag } from "./DivTag";
// ##### 24-07-25 Komarov
import { useSelector } from "react-redux";

moment.locale(config.defaultLang);

// ##### 23-10-10 Komarov
export const HotelRatesContext = createContext();

// ##### 23-02-17 Komarov: Убрал свойство "key", - оно не использовалось, и вызывало предупреждение в Консоли разработчика
export const HotelRates = ({
  hotelTariff,
  hotelRooms,
  searchResults,
  hotelName,
  cityName,
  date_difference,
  adults,
  pickedCurrency,
}) => {
  // console.log('PROPS_PROPS',key,hotelTariff,hotelRooms,searchResults,hotelName,cityName)

  // console.log('HOTEL_ROOMS_2',hotelTariff)
  // ##### 24-03-21 Komarov
  // const location = useLocation()

  // const currencies = useSelector(state=>state.currency.currencies)
  // $$$$$ 23-01-27 Komarov: Исправил ошибку определения того, какой массив currencies нужно взять
  // ##### 24-07-25 Komarov
  const currencyFromState = useSelector(state => state.currency.currencies);
  const currencies =
    currencyFromState.length > 0 ? currencyFromState
      : JSON.parse(localStorage.getItem("currency")).currencies;

  let exchangeRate;

  // ##### 24-03-21 Komarov
  for (const curr of currencies) {
    if (curr.name === pickedCurrency) exchangeRate = parseInt(curr.value);
  }

  let min_rate = Number.MAX_VALUE;

  // console.log('[HOTELROOMS22]', hotelRooms)

  let newobj2 = {};

  // ##### 24-04-05 Komarov
  if (hotelTariff.dates) {
    for (const item of hotelTariff.dates) {
      if (item.date === searchResults.start) {
        for (const item1 of item.rooms) {
          for (const item2 of item1.tariffs) {
            for (const item3 of item2.prices) {
              if (item3.sum < min_rate) {
                min_rate = item3.sum;
                newobj2 = {
                  min_rate: min_rate,
                  room_id: item1.room_id,
                };
              }
            }
            // }
            // else
            // message = "Not Available"
          }
        }
      }
    }
  }

  // console.log('[MIN_RATE at]', hotelName , min_rate ,newobj2)

  // const filtered_new = filtered_hotelRooms.sort((c,d)=>(parseInt(c.sum,10)-parseInt(d.sum,10)))[0]

  // const addToHotelDetails = (e) => {

  //     e.preventDefault()

  //     const route_hotel_query = `${location.search},hotel_name=${hotelName},hotel_id=${hotelTariff.hotel_id}`
  //     history.push(`/hotel_details/${route_hotel_query}`)
  //   }

  // ##### 24-09-09 Komarov
  const hotelRatesContextValue = useMemo(() => ({
    hotelRooms,
    newobj2,
    hotelTariff,
    date_difference,
    adults,
    pickedCurrency,
    exchangeRate,
    cityName,
    hotelName,
  }), [
    // ##### 24-09-11 Komarov
    hotelRooms,
    newobj2,
    hotelTariff,
    date_difference,
    adults,
    pickedCurrency,
    exchangeRate,
    cityName,
    hotelName,
  ]);

  // ##### 23-03-01 Komarov
  return (
    <HotelRatesContext.Provider
      value={hotelRatesContextValue}
    >
      <div
        style={{ gridColumn: "2", gridRow: "2" }}
        data-component="HotelRates"
      >
        {/* ##### 23-11-02 Komarov */}
        <Media greaterThanOrEqual="Laptop-1024-1440">
          <DivTag gridAutoRows="minmax(40px, auto)" />
        </Media>
        <Media lessThan="Laptop-1024-1440">
          <DivTag gridAutoRows="minmax(15px, auto)" />
        </Media>
      </div>
    </HotelRatesContext.Provider>
  );
};
