// ##### 24-06-14 Komarov
import React, {
  useState,
  useEffect,
  createContext,
  useMemo,
} from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
// ##### 23-02-17 Komarov
import moment from "moment";
import { ValidateQuery } from "../../../Helpers/helper";
import { useWindowWidthAndHeight } from "../../../Helpers/WindowResizeHook";
import { getHotelCities } from "../../../../../Redux/actions/hotelcities";
import { getPickedCurrencyResponse } from "../../../../../Redux/actions/currency";
// ##### 24-07-25 Komarov
import { setPromoCode } from "../../../../../Redux/actions/promocode";
// import { getRoute } from "../../../../Redux/actions/routes";
// ##### 24-03-01 Komarov
import {
  // ##### 24-08-01 Komarov
  // setCurrentUrl,
  setHotelsRatesContentLoaded,
} from "../../../../../Redux/actions";

import { Search } from "../../../FirstPageofSearchModule/SearchResizersAndSwitchers/SearchFront";
// ##### 23-03-29 Komarov
import { LoadingMessage } from "../../../../Library/PageDevices/LoadingMessage";
// ##### 23-09-14 Komarov
// import './HotelItemsCSS.css'
import config from "../../../../../Redux/config";
// ##### 24-04-23 Komarov
import { useMessages } from "../../../../../helpers/messages";
// ##### 23-11-02 Komarov
import { Media } from "../../../../../helpers/MediaConfig";
import { UlTag } from "./UlTag";

moment.locale(config.defaultLang);

// ##### 23-10-10 Komarov
export const HotelItemsContext = createContext();

export const HotelItems = () => {
  // ##### 24-06-27 Komarov
  /* useEffect(() => {
    window.scrollTo(0, 0);
  }, []); */
  // ##### 24-06-26 Komarov
  useEffect(() => {
    localStorage.getItem('clientData') && localStorage.removeItem('clientData');
  }, []);
  // ##### 24-04-23 Komarov
  const SearchResults = useMessages("SearchResults");
  // ##### 24-03-21 Komarov
  // const RotaryMessage = useMessages("RotaryMessage");
  const PropertiesFound = useMessages("PropertiesFound");

  // console.log('HOTEL_TITLE', title)

  ///получаю с помощью своиства истории (history) из компонента search результаты поиска - массив с одним объектом.
  // ##### 24-03-21 Komarov
  const location = useLocation();
  // ##### 23-11-09 Komarov
  /* const currentUrl = useSelector(state => state.currentUrl);

  useEffect(() => {
    // ##### 23-09-04 Komarov
    window.scrollTo(0, 0);
  }, [currentUrl]); */

  // ##### 23-03-29 Komarov
  const [width /* height */] = useWindowWidthAndHeight();

  // ##### 24-03-21 Komarov
  const search_data = ValidateQuery(location);
  // console.log('HOTEL ITEM LOCATION', location)

  // // const searchResults = search_data.query.replace(/_/g, " ")
  // console.log('[SEARCHRESULTS] : ' , searchResults , 'DATSENKO',search_data, search_data.title.replace(/_/g, " "))
  // console.log('DATSENKO',search_data)

  const dispatch = useDispatch();
  // const generalHotelItems = useSelector(state => state.hotels.general_hotels)
  const generalHotelItems = useSelector(
    (state) => state.hotelcities.hotel_cities
  );

  // dispatch(getRoute(search_data))
  // ##### 24-08-05 Komarov
  // TODO: Исправить код в соответствии с задачей убрать Промокод из URL
  const { selected_currency/* , refpartner */ } = search_data;
  useEffect(() => {
    // dispatch(getRoute(search_data));
    // ##### 23-04-12 Komarov
    // dispatch(getPickedCurrencyResponse(search_data.selected_currency));
    // dispatch(setPromoCode(search_data.refpartner));
    dispatch(getPickedCurrencyResponse(selected_currency));
    // ##### 24-08-05 Komarov
    // dispatch(setPromoCode(refpartner));
  }, [dispatch, selected_currency/* , refpartner */]);

  // ##### 24-06-06 Komarov
  useEffect(() => {
    dispatch(setHotelsRatesContentLoaded(false));
  }, [
    search_data.id,
    search_data.title,
    search_data.start,
    search_data.end,
    search_data.adults,
    search_data.children,
    search_data.rooms,
  ]);

  // console.log('[PICKEDCURRENCY]',pickedCurrency)

  // ##### 23-12-28 Komarov
  const initialHotelRate = useMemo(() => {
    return [];
  }, []);
  // ##### 24-05-23 Komarov
  const initialHotelsContent = useMemo(() => {
    return {};
  }, []);
  const [hotelRate, setHotelRate] = useState(initialHotelRate);
  // ##### 24-05-23 Komarov
  const [hotelsContent, setHotelsContent] = useState(initialHotelsContent);
  // ##### 23-02-17 Komarov
  const [smartResultFound, setSmartResultFound] = useState("");

  // ##### 23-12-27 Komarov
  const locale = useSelector((state) => state.language);

  ///получаю из смарта тур имя, тур айди, сити имя, сити айди
  // useEffect ( () => {
  //   dispatch (getGeneralHotels ());
  // }, [])

  useEffect(() => {
    // ##### 24-03-21 Komarov
    process.env.NODE_ENV === "development" &&
      console.log(
        "axios:src/Components/Pages/SecondPageofSearchModule/Hotels/HotelItems.js:getHotelCities(locale)"
      );
    dispatch(getHotelCities(locale));
    // ##### 23-04-12 Komarov
  }, [dispatch, locale]);

  // console.log('[GENERAL_HOTELS] , ' , generalHotelItems)///получаю из смарта имя отеля, айди отеля, сити имя, сити айди

  ///используется непосредственно для вывода названий отелей на странице.
  ///если айди города, который мне приходит первоначально от Саши Ефица (классификатор contracts) не находится в данных, пришедших в результате поиска клиентом,
  ///то в переменную filtered возвращается тур айди из классификатора contracts, равное айди, пришедшему от клиентского поиска
  ///иначе возвращается сити айди из классификатора contracts, равное айди из поиска

  // ##### 24-03-21 Komarov
  const filtered_hotel_items = generalHotelItems.filter((item) => {
    // if(item.city_id.indexOf(search_data.id) === -1){
    if (parseInt(item.city_id) !== parseInt(search_data.id)) {
      return parseInt(item.hotel_id) === parseInt(search_data.id);
      // return parseInt(item.hotel_id)
    }
    return parseInt(item.city_id) === parseInt(search_data.id);
    // return parseInt(item.city_id)
  });

  // console.log('[FILTERED_GENERAL_HOTELS]', filtered_hotel_items)

  ///отфильтровала данные поиска, чтобы получить только айди отеля поиска
  // ##### 23-03-29 Komarov
  /* const filtered_hotel_id = filtered_hotel_items.map(function(item1){
    return parseInt(item1.hotel_id)
  }) */

  // ##### 24-04-11 Komarov
  const hotelstransorig = useSelector((state) => state.hotels.hotelstransorig);

  let filtered_city_name;
  // ##### 24-03-21 Komarov
  for (const item1 of filtered_hotel_items) {
    filtered_city_name = item1.localized_city_name;
  }
  // ##### 24-06-19 Komarov
  if (!filtered_city_name) {
    filtered_city_name = decodeURIComponent(search_data.title) || hotelstransorig[locale][0][localStorage.getItem("city_id")].name;
  }

  // console.log('[FILTERED GEO] : ', filtered)
  // console.log('[FILTERED_CITY_NAME] : ', filtered_city_name)

  // ##### 23-03-29 Komarov
  // const currentMonth = moment().format('YYYY-MM');
  // const today = moment().format('YYYY-MM-DD');

  const date_difference = moment(search_data.end).diff(
    moment(search_data.start),
    "days"
  );
  // console.log('[DATE DIFFERENCE]',date_difference)
  // ##### 23-11-07 Komarov
  const sale =
    localStorage.getItem("user") &&
    JSON.parse(localStorage.getItem("user")).sale;

  // console.log('[TODAY MONTH]' , currentMonth, '[TODAY DATE]', today, '[TEST_DATE] : ' , search_data.date + '-01')
  const { user: currentUser } = useSelector((state) => state.auth);
  // ##### 24-03-01 Komarov
  const company_cipher = useSelector(
    (state) => state.whitelabel.company_cipher
  );
  // ##### 24-04-05 Komarov
  const available_now = useSelector(
    (state) => state.order.selectedItemInfo?.available_now
  );

  // ##### 24-05-23 Komarov
  const deviceType = useSelector((state) => state.deviceType);

  // console.log('USER',currentUser.user_id,search_data.refpartner)
  // ##### 24-08-05 Komarov
  const company_id = useSelector(state => state.auth.user.company_id);
  const promocode = useSelector(state => state.promocode.promocode);

  // ##### 24-03-21 Komarov
  useEffect(() => {
    const ActionRQ = (sale && {
      username: process.env.REACT_APP_SMART_USERNAME,
      password: process.env.REACT_APP_SMART_PASSWORD,
      // "user_id" :1426,
      user_id: currentUser.user_id,
      action: "GetPriceHotelRQ",
      sale: sale,
      // ##### 24-08-05 Komarov
      refpartner: /* search_data.refpartner ? search_data.refpartner :  */company_id,
      // ##### 24-06-08 Komarov
      shortContent: "1",
      data: {
        promocode,
        // ##### 24-05-23 Komarov
        deviceType,
        // ##### 24-04-05 Komarov
        available_now: Number(available_now),
        // ##### 24-03-01 Komarov
        company_cipher: company_cipher || 0,
        start: search_data.start, // date of arrival
        end: search_data.end, // date of departure
        city_id: search_data.city_id,
        // search_data.city_id,         // Id of city - can`t be equel to zero
        hotel_id:
          parseInt(search_data.city_id) === parseInt(search_data.id)
            ? 0
            : search_data.id,
        // parseInt(search_data.city_id) === parseInt(search_data.id)? 320 : search_data.id,       // Id of hotel: if hotel_id = 0, must return all hotels of the pointed city in response
        numberofunits: search_data.rooms, // Quantity of rooms, 1 by default, NOT OBLIGATORY
        calculation_data: {
          adults: search_data.adults,
          children: search_data.children,
          // "child_discount" : parseInt(search_data.c) !== 0? 0.5: null,
          lower_cost_limit: 0.0, // lower cost limit of room in USD, NOT OBLIGATORY
          upper_cost_limit: 200.0, // upper cost limit of room in USD, NOT OBLIGATORY
        },
      },
    }) || {
      username: process.env.REACT_APP_SMART_USERNAME,
      password: process.env.REACT_APP_SMART_PASSWORD,
      // "user_id" :1426,
      user_id: currentUser.user_id,
      action: "GetPriceHotelRQ",
      // ##### 24-08-05 Komarov
      refpartner: /* search_data.refpartner ? search_data.refpartner :  */company_id,
      // ##### 24-06-08 Komarov
      shortContent: "1",
      data: {
        promocode,
        // ##### 24-05-23 Komarov
        deviceType,
        // ##### 24-04-05 Komarov
        available_now: Number(available_now),
        // ##### 24-03-01 Komarov
        company_cipher: company_cipher || 0,
        start: search_data.start, // date of arrival
        end: search_data.end, // date of departure
        city_id: search_data.city_id,
        // search_data.city_id,         // Id of city - can`t be equel to zero
        hotel_id:
          parseInt(search_data.city_id) === parseInt(search_data.id)
            ? 0
            : search_data.id,
        // parseInt(search_data.city_id) === parseInt(search_data.id)? 320 : search_data.id,       // Id of hotel: if hotel_id = 0, must return all hotels of the pointed city in response
        numberofunits: search_data.rooms, // Quantity of rooms, 1 by default, NOT OBLIGATORY
        calculation_data: {
          adults: search_data.adults,
          children: search_data.children,
          // "child_discount" : search_data.c !== 0? 0.5: null,
          lower_cost_limit: 0.0, // lower cost limit of room in USD, NOT OBLIGATORY
          upper_cost_limit: 200.0, // upper cost limit of room in USD, NOT OBLIGATORY
        },
      },
    };

    // axios.post('http://smartbooker.biz/interface/xmlsubj/', JSON.stringify({ActionRQ}))
    // ##### 24-05-17 Komarov
    const url = `${process.env.REACT_APP_SMART_URL}interface/xmlsubj/`;
    process.env.NODE_ENV === "development" &&
      console.log(
        "axios:src/Components/Pages/SecondPageofSearchModule/Hotels/HotelItems.js:useEffect, url:",
        url
      );
    axios
      .post(url, JSON.stringify({ ActionRQ }))
      .then((response) => {
        // ##### 23-02-17 Komarov: если из Смарта пришёл ответ "Hi!", это значит, что в БД ничего не нашлось по запрошенному набору параметров (нет отеля на такую дату и/или с такими условиями, и т.п.). TODO: нужно возвращать от Смарта правильный код ответа в таком случае, и тогда здесь поменятяь его обработку
        // ##### 24-03-21 Komarov
        if (response.data === "Hi!") {
          setSmartResultFound("Hi!");
          // ##### 23-12-21 Komarov
          setHotelRate(initialHotelRate);
          // ##### 24-05-23 Komarov
          setHotelsContent(initialHotelsContent);
        }
        // ##### 24-03-21 Komarov
        /*for (const key in response.data.data) {
          if (key === 'hotels') {
            setHotelRate(response.data.data[key])
          }
          // setHotelRate(response.data)

        }*/

        // ##### 24-04-11 Komarov
        if (response.data.data.city_id) {
          localStorage.setItem("city_id", response.data.data.city_id);
        }

        // ##### 24-04-08 Komarov: Убирааем тарифы с повторяющимися типами из отелей, доступных под запрос (у которых нет объекта dates)
        const hotelsRates = [];
        for (const hotelsRate of response.data.data.hotels) {
          if (hotelsRate.dates) {
            hotelsRates.push(hotelsRate);
          } else {
            const room_rates = [];
            for (const roomRate of hotelsRate.room_rates) {
              const tariffsSet = {};
              for (const tariff of roomRate.tariffs) {
                tariffsSet[tariff.tariff_type_id] = tariff;
              }
              room_rates.push({
                ...roomRate,
                tariffsToShow: Object.values(tariffsSet),
              });
            }
            hotelsRates.push({
              ...hotelsRate,
              room_rates,
            });
          }
        }
        setHotelRate(hotelsRates);
        // ##### 24-05-23 Komarov
        setHotelsContent(response.data.data.hotels_content);
        // ##### 24-06-06 Komarov
        dispatch(setHotelsRatesContentLoaded(true));
      })
      .catch((error) => {
        setHotelRate(undefined);
        console.log("[axios error] : ", error);
      });
    // ##### 24-03-21 Komarov
  }, [
    currentUser.user_id,
    search_data.refpartner,
    search_data.start,
    search_data.end,
    search_data.city_id,
    search_data.id,
    search_data.rooms,
    search_data.adults,
    search_data.children,
    sale,
    initialHotelRate,
    // ##### 24-05-23 Komarov
    initialHotelsContent,
    company_cipher,
  ]);

  // console.log('GEN_HOTEL_RATE', hotelRate)

  // ##### 24-08-05 Komarov
  /* useEffect(() => {
    // ##### 24-08-01 Komarov
    const rememberLink = (to) => {
      // dispatch(setCurrentUrl(to));
      // localStorage.setItem("currentUrl", to);
    };
    // ##### 24-02-22 Komarov
    const strToRemove = `/${locale}`;
    rememberLink(
      location.pathname.substring(
        location.pathname.indexOf(strToRemove) + strToRemove.length
      ) + location.search
    );
    // ##### 24-08-05 Komarov
  }, [locale, dispatch, location.pathname, location.search]); */

  // ##### 24-06-06 Komarov
  const hotelsRatesContentLoaded = useSelector(
    (state) => state.hotelrates.hotelsRatesContentLoaded
  );

  // ##### 24-09-09 Komarov
  const hotelItemsContextValue = useMemo(() => ({
    hotelRate,
    search_data,
    filtered_city_name,
    date_difference,
    filtered_hotel_items,
    // ##### 24-04-11 Komarov
    hotelstransorig,
  }), [
    // ##### 24-09-11 Komarov
    hotelRate,
    search_data,
    filtered_city_name,
    date_difference,
    filtered_hotel_items,
    // ##### 24-04-11 Komarov
    hotelstransorig,
  ]); // ##### 24-09-10 Komarov

  return (
    <HotelItemsContext.Provider
      value={hotelItemsContextValue}
    >
      {/* <h3>Search Results</h3> */}
      <div data-component="HotelItems">
        {/* <InnerSearchBlock search_data={search_data}/> */}
        <Search searchProps={search_data} />
      </div>
      <div className="searchrendering_Wrapper">
        <div>
          <h3
            style={{
              marginTop: "2vw",
              color: "#003057",
              fontFamily: "Arial",
              // ##### 24-08-14 Komarov
              // fontSize: "30px",
              fontSize: "22px",
              fontWeight: "bold",
            }}
          >
            {
              // ##### 23-10-04 Komarov
              SearchResults
            }
          </h3>
        </div>

        <div
          // @@@@@ 23-01-30 Komarov: Подвинул выше блок с сообщением о загрузке, чтобы он не накладывался на Footer
          // ##### 24-05-23 Komarov
          style={
            !hotelsRatesContentLoaded ||
              !hotelRate ||
              hotelRate.length === 0 ||
              !hotelsContent ||
              Object.keys(hotelsContent).length === 0
              ? { height: "250px" }
              : null
          }
        >
          {/* ##### 24-06-06 Komarov */}
          {!hotelsRatesContentLoaded ||
            !hotelRate ||
            hotelRate.length === 0 ||
            !hotelsContent ||
            Object.keys(hotelsContent).length === 0 ? (
            <div>
              {/* ##### 23-02-17 Komarov */}
              <LoadingMessage
                loadingMessageClass="RateLoading"
                smartResultFound={smartResultFound}
              />
            </div>
          ) : (
            <>
              <h2
                style={{
                  color: "#001959",
                  backgroundColor: "rgb(255, 239, 131)",
                  // border:'2px solid #001959',
                  borderRadius: "5px",
                  marginLeft: "auto",
                  marginRight: "auto",
                  textAlign: "center",
                  paddingTop: "2vh",
                  paddingBottom: "2vh",
                  // ##### 24-08-14 Komarov
                  // fontSize: "25px",
                  fontSize: "22px",
                  maxWidth: "80%",
                  fontStyle: "Italic",
                }}
              >
                {/* ##### 24-03-21 Komarov */}
                <span>
                  {filtered_city_name} : {hotelRate.length}
                  <span style={{ marginRight: "5px" }} />
                  {
                    // ##### 23-10-04 Komarov
                    PropertiesFound
                  }
                </span>
              </h2>

              {/* ##### 23-11-02 Komarov */}
              <Media greaterThanOrEqual="Laptop-1024-1440">
                <UlTag
                  ulCLassName="HotelDescriptionUl"
                  width={width * 0.65}
                  liClassName="HotelDescriptionLi"
                  // ##### 24-05-23 Komarov
                  hotelsContent={hotelsContent}
                />
              </Media>
              {/* ##### 23-04-20 Komarov */}
              <Media lessThan="Laptop-1024-1440">
                <UlTag
                  ulCLassName="HotelDescriptionUlSmallScreen"
                  width={width * 0.9}
                  liClassName="HotelDescriptionLiSmallScreen"
                  // ##### 24-05-23 Komarov
                  hotelsContent={hotelsContent}
                />
              </Media>
            </>
          )}
        </div>
      </div>
    </HotelItemsContext.Provider>
  );
};
