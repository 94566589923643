// ##### 23-09-07 Komarov
import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  createContext,
  // ##### 24-09-09 Komarov
  useMemo,
} from "react";
import axios from "axios";
import { Radio, Checkbox } from "antd";
import { useSelector } from "react-redux";
// ##### 23-04-12 Komarov
import uniqueId from "lodash.uniqueid";
// ##### 24-06-26 Komarov
import { useLocation, useHistory } from "react-router-dom";

import { ClientTitles } from "../../Library/StaticJsonData/ClientTitles";
import { ConfirmButton } from "./ConfirmButton";
import { TermsConditions } from "./TermsConditions";
import { PlaceHolderStrings } from "../../Library/Localization/placeholders";
// ##### 23-07-10 Komarov
import InputMask from "react-input-mask";
// ##### 24-04-23 Komarov
import { useMessages } from "../../../helpers/messages";
// ##### 24-04-24 Komarov
import { ValidateQuery } from "../Helpers/helper";
import { OnlineSummary } from "./OnlineSummary";
import { OfflineSummary } from "./OfflineSummary";
import SaveReference from "../../Library/Notifications/SaveReference";
import { Pay } from "../../Library/LiqPay/Pay";
import { IncompleteSendEmail } from "./IncompleteSendEmail";
// ##### 24-04-29 Komarov
import { StarRating } from "../../Library/StaticJsonData/StarRating";
// ##### 24-05-01 Komarov
import { dateTime } from "../../../helpers/dateFormats";
import moment from "moment";
// ##### 24-08-05 Komarov
// import config from "../../../Redux/config";

// ##### 23-09-14 Komarov
// import './BookingForm.css'
// import './ClientDetailsCSS.css'

// ##### 23-10-10 Komarov
const ClientDetailsForm = ({ className }) => {
  const { cart } = useContext(ClientDetailsContext);

  const { user: currentUser } = useSelector((state) => state.auth);

  const [sendCart, setSendCart] = useState([{}]);
  // const [userData, setUserData] = useState(null);

  const placeholder = PlaceHolderStrings();

  const [nameInput, setNameInput] = useState("");
  const [surnameInput, setSurnameInput] = useState("");
  const [phoneInput, setPhoneInput] = useState("");
  const [emailInput, setEmailInput] = useState("");
  const [commentsInput, setCommentsInput] = useState("");
  // ##### 24-06-11 Komarov
  const [agreedPriceInput, setAgreedPriceInput] = useState("");
  const [termsTicked, setTermsTicked] = useState(false);
  const [readTerms, setReadTerms] = useState(false);

  // ##### 24-06-26 Komarov
  const clientData = localStorage.getItem('clientData') && JSON.parse(localStorage.getItem('clientData')) || {};
  const [list, setList] = useState(clientData);

  useEffect(() => {
    localStorage.setItem('clientData', JSON.stringify(list));
  }, [list]);

  const [clicked, setClicked] = useState(false);
  // ##### 24-05-01 Komarov
  const [emailSent, setEmailSent] = useState(false);

  const [bookerTravels, setbookerTravels] = useState(0);
  // ##### 24-05-01 Komarov: Переменная для хранения данных формы, которые потом отправляются на email
  const [formData, setFormData] = useState([]);

  const responseErrorIndex = useRef(0);
  // ##### 24-05-01 Komarov
  const locale = useSelector((state) => state.language);

  // ##### 24-03-21 Komarov
  // const [responseError, setResponseError] = useState(responseErrorIndex);
  // ##### 24-03-01 Komarov
  const company_cipher = useSelector(
    (state) => state.whitelabel.company_cipher
  );
  // ##### 24-08-05 Komarov
  const company_id = useSelector(state => state.auth.user.company_id);
  // ##### 24-04-23 Komarov
  const location = useLocation();
  const available_now = ValidateQuery(location).available_now === "true";
  // ##### 24-05-23 Komarov
  const [registrationTimestamp, setRegistrationTimestamp] = useState();

  // ##### 24-05-01 Komarov
  useEffect(() => {
    // ##### 24-04-23 Komarov: TODO: Сделать запрос к action для регистрации заявки Под запрос
    if (!available_now && clicked) {
      // ##### 24-05-01 Komarov: Отправка email с данными Формы Бронирования
      // ##### 24-05-01 Komarov: TODO: Переделать формирование этого объекта, чтобы код не повторялся
      let data = {};
      for (const item of formData) {
        data[Object.keys(item)[0]] = Object.values(item)[0];
      }

      // ##### 24-05-23 Komarov
      const ActionRQ = {
        username: process.env.REACT_APP_SMART_USERNAME,
        password: process.env.REACT_APP_SMART_PASSWORD,
        // "user_id" :1426,
        user_id: currentUser.user_id,
        // ##### 24-08-05 Komarov
        refpartner: /* cart.refpartner ? cart.refpartner :  */company_id,
        action: "FormOnRequestRQ",
        data: {
          // ##### 24-03-01 Komarov
          company_cipher: company_cipher || 0,
          bookings: [
            {
              name: nameInput,
              surname: surnameInput,
              email: emailInput,
              phone: phoneInput,
              service_type_id: cart.service_type_id,
              start: cart.start,
              end: cart.end ? cart.end : null,
              contract_id: cart.contract_id, //former tour_id
              tariff_id: cart.tariff_id, //former tour_tariff_id
              room_id: cart.room_id, //former tour_room_id
              numberofunits:
                parseInt(cart.service_type_id) === parseInt(1)
                  ? parseInt(cart.numberofunits)
                  : parseInt(1),
              hotel_id: cart.hotel_id,
              star_rating: cart.hotel_category.length,
              city_id: cart.city_id,
              hotel_room_id:
                parseInt(cart.service_type_id) === parseInt(1)
                  ? cart.hotel_room_id
                  : null, //only for package tour
              hotel_rate_id:
                parseInt(cart.service_type_id) === parseInt(1)
                  ? cart.hotel_rate_id
                  : null, // only for package tours
              calculation_data: {
                adults: cart.adults,
                children: cart.children,
                amount: cart.amount,
              },
              comments: commentsInput,
              // ##### 24-06-11 Komarov
              agreedPrice: agreedPriceInput === "" ? 0 : agreedPriceInput,
            },
          ],
        },
      };

      axios
        .post(
          `${process.env.REACT_APP_SMART_URL}interface/xmlsubj/`,
          JSON.stringify({ ActionRQ })
        )
        .then((res) => {
          setRegistrationTimestamp(res.data[0].data.timestamp);
          axios
            .post(
              `${process.env.REACT_APP_URL}/online_summary`,
              JSON.stringify({ data, subject: "Booking On Request" })
            )
            .then(() => setEmailSent(true));
        });
    } // ##### 23-04-12 Komarov
  }, [
    cart.amount,
    cart.service_type_id,
    cart.start,
    cart.end,
    cart.contract_id,
    cart.tariff_id,
    cart.room_id,
    cart.numberofunits,
    cart.hotel_id,
    cart.hotel_room_id,
    cart.hotel_rate_id,
    cart.adults,
    cart.children,
    cart.refpartner,
    currentUser.user_id,
    // ##### 24-03-21 Komarov
    // responseError,
    company_cipher,
    commentsInput,
    // ##### 24-06-11 Komarov
    agreedPriceInput,
    clicked,
  ]);

  useEffect(() => {
    // ##### 24-04-23 Komarov: TODO: Сделать запрос к action для регистрации заявки Под запрос
    if (available_now && clicked) {
      // ##### 24-03-21 Komarov
      const ActionRQ = {
        username: process.env.REACT_APP_SMART_USERNAME,
        password: process.env.REACT_APP_SMART_PASSWORD,
        // "user_id" : currentUser ? currentUser.user_id : 1426,
        // "user_id" : 1426,
        user_id: currentUser.user_id,
        // ##### 24-08-05 Komarov
        refpartner: /* cart.refpartner ? cart.refpartner :  */company_id,
        action: "AddToCartRQ",
        data: {
          // ##### 24-03-01 Komarov
          company_cipher: company_cipher || 0,
          bookings: [
            {
              service_type_id: cart.service_type_id,
              start: cart.start,
              end: cart.end ? cart.end : null,
              contract_id: cart.contract_id, //former tour_id
              tariff_id: cart.tariff_id, //former tour_tariff_id
              room_id: cart.room_id, //former tour_room_id
              numberofunits:
                parseInt(cart.service_type_id) === parseInt(1)
                  ? parseInt(cart.numberofunits)
                  : parseInt(1),
              hotel_id: cart.hotel_id,
              hotel_room_id:
                parseInt(cart.service_type_id) === parseInt(1)
                  ? cart.hotel_room_id
                  : null, //only for package tour
              hotel_rate_id:
                parseInt(cart.service_type_id) === parseInt(1)
                  ? cart.hotel_rate_id
                  : null, // only for package tours
              calculation_data: {
                adults: cart.adults,
                children: cart.children,
                amount: cart.amount,
              },
              comments: commentsInput,
            },
          ],
        },
      };

      axios
        .post(
          `${process.env.REACT_APP_SMART_URL}interface/xmlsubj/`,
          JSON.stringify({ ActionRQ })
        )
        .then((response) => {
          // console.log('RESPONSE ClientDetails.js', response)
          if (response.data[0].errors[0] === "Duplicate entry") {
            responseErrorIndex.current += 1;
            // ##### 24-03-21 Komarov
            // setResponseError(responseErrorIndex.current);
            // console.log('RESPONSE responseErrorIndex', responseError);
          }
          setSendCart(response.data[0]);
        })
        .catch((error) => {
          setSendCart(undefined);
          console.log("[axios error]: ", error);
        });
    } // ##### 23-04-12 Komarov
  }, [
    cart.amount,
    cart.service_type_id,
    cart.start,
    cart.end,
    cart.contract_id,
    cart.tariff_id,
    cart.room_id,
    cart.numberofunits,
    cart.hotel_id,
    cart.hotel_room_id,
    cart.hotel_rate_id,
    cart.adults,
    cart.children,
    cart.refpartner,
    currentUser.user_id,
    // ##### 24-03-21 Komarov
    // responseError,
    company_cipher,
    commentsInput,
    clicked,
  ]); // (Долбим) Посылаем запросы, пока Твид не выдаст twid_reference

  const AgreedFunc = () => {
    setTermsTicked(!termsTicked);
  };

  const ReadTermsFunc = () => {
    setReadTerms(!readTerms);
  };

  const closeTermsConditions = () => {
    setReadTerms(false);
  };

  // ##### 23-02-20 Komarov: app_service_id - объект, который содержит входные атрибуты после выполнения AddToCart
  let app_service_id = new Object();

  if (sendCart) {
    // ##### 24-03-21 Komarov
    for (const key in sendCart.data) {
      app_service_id = sendCart.data[key];
    }
  }

  // TODO: Пока нету поля currentUser.phone
  useEffect(() => {
    if (parseInt(currentUser.user_id) !== 1426) {
      setNameInput(currentUser.name);
      setSurnameInput(currentUser.surname);
      setEmailInput(currentUser.email);
    }
    // ##### 23-04-12 Komarov
  }, [
    currentUser.user_id,
    currentUser.name,
    currentUser.surname,
    currentUser.email,
  ]);

  // if( !sendCart){
  //     return <div> Loading...</div>
  // }

  // console.log('CART',cart)
  // console.log('SENDCART',sendCart)
  // console.log('app_service_id',app_service_id)

  // console.log('NEWARRAY', app_service_id.service_id)

  // ##### 23-10-04 Komarov
  const AgreeTermsCond =
    useMessages("AgreeTermsCond") || "Please agree to Terms and Conditions";

  // ##### 23-10-04 Komarov
  const ClientBookingForm = useMessages("ClientBookingForm");
  const ClientBookingForm_1 = useMessages("ClientBookingForm_1");
  const EmailAddress = useMessages("EmailAddress");
  const DoYouBookForSMBDElse = useMessages("DoYouBookForSMBDElse");
  const Yes = useMessages("Yes");
  const No = useMessages("No");
  // ##### 24-04-17 Komarov
  const HotelTitle = useMessages("HotelTitle");
  const RoomType = useMessages("Room type");
  const Tariff = useMessages("Tariff");
  const TotalCostShort = useMessages("TotalCostShort");
  // ##### 24-04-26 Komarov
  const SelectedCurrency = useMessages("SelectedCurrency");
  const Adults = useMessages("Adults");
  const Children = useMessages("Children");
  const Rooms = useMessages("Rooms");
  const Comments = useMessages("Comments");
  // ##### 24-06-11 Komarov
  const AgreedPrice = useMessages("AgreedPrice");
  const CartDetails_3 = useMessages("CartDetails_3"); // Текст "Початок Послуг"
  const CartDetails_4 = useMessages("CartDetails_4"); // Текст "Закінчення Послуг"
  // ##### 24-04-18 Komarov
  const SupplierForm_city = useMessages("SupplierForm_city");
  const BackToRooms = useMessages("BackToRooms");
  // ##### 24-05-01 Komarov
  const RegistrationDateTime = useMessages("RegistrationDateTime");
  // ##### 24-04-29 Komarov
  const StarRatingLabelText = useMessages("StarRating");
  const starRatingValue = StarRating[cart.hotel_category.length - 1];
  // console.log('CONFIRMBUTTON_CART',cart)
  // console.log('smart_order_id',smart_order_id)
  // ##### 24-06-26 Komarov
  const history = useHistory();

  const onSubmit = (e) => {
    list ? setClicked(true) : setClicked(false);
    if (termsTicked === false) {
      alert(AgreeTermsCond);
      setClicked(false);
    }
    e.preventDefault();
  };

  const AddContacts = () => {
    const newList = {
      ready: true,
      name: nameInput,
      surname: surnameInput,
      phone: phoneInput,
      email: emailInput,
      // ##### 24-06-26 Komarov
      comments: commentsInput,
    };

    setList(newList);

    // ##### 24-05-01 Komarov: Функция для добавления в массив данных формы нового элемента после определённого из элементов в этом массиве (на определённое место)
    const insertAfter = (
      arrayWhere,
      itemWhat,
      itemNameAfterWhichItemWtahWillBeInserted
    ) => {
      const indexOfItemAfterWhichItemWtahWillBeInserted = arrayWhere.indexOf(
        arrayWhere.find(
          (item) =>
            Object.keys(item)[0] === itemNameAfterWhichItemWtahWillBeInserted
        )
      );
      const leftItems = [
        ...arrayWhere.filter((_item, index) => {
          if (index <= indexOfItemAfterWhichItemWtahWillBeInserted) {
            return true;
          }
        }),
      ];
      const rightItems = [
        ...arrayWhere.filter((_item, index) => {
          if (index > indexOfItemAfterWhichItemWtahWillBeInserted) {
            return true;
          }
        }),
      ];
      return [...leftItems, itemWhat, ...rightItems];
    };

    // ##### 24-05-01 Komarov: Данные формы, которые всегда присутствуют
    const staticFormData = [
      { [SupplierForm_city]: decodeURIComponent(cart.cityName) },
      { [HotelTitle]: cart.htlName },
      { [StarRatingLabelText]: starRatingValue },
      { [RoomType]: cart.room_name },
      {
        [Tariff]:
          cart.tariff_type_name +
          (parseInt(cart.nrf) === 1 ? " Non Refundable" : ""),
      },
      { [Adults]: cart.adults },
      { [Rooms]: cart.numberofunits },
      { [SelectedCurrency]: cart.selected_currency },
      { [CartDetails_3]: cart.start },
      { [CartDetails_4]: cart.end },
      { [placeholder.placeHolderName]: nameInput },
      { [placeholder.placeHolderSurname]: surnameInput },
      { [ClientBookingForm_1]: phoneInput },
      { Email: emailInput },
      { [Comments]: commentsInput },
      // ##### 24-06-11 Komarov
      { [AgreedPrice]: agreedPriceInput },
    ];

    // ##### 24-05-01 Komarov: Функция для добавления в форму данных, которые не всегда присутствуют, в случае их наличия
    const prepareData = () => {
      let result = staticFormData;
      if (decodeURIComponent(cart.amount) !== "No price was shown") {
        result = insertAfter(result, { [TotalCostShort]: cart.amount }, Rooms);
      }
      if (parseInt(cart.children) !== 0) {
        result = insertAfter(result, { [Children]: cart.children }, Adults);
      }
      return result;
    };

    const data = prepareData();

    setFormData(data);

    // else alert('Please update phone number. Digits only')

    // if(!ModifyClientsRQ_Add){
    //     history.push('\offlineSummary')
    // }
  };

  // ##### 24-06-26 Komarov
  const backToRooms = () => {
    // history.back();
    // ##### 24-06-27 Komarov
    // const urlPart = localStorage.getItem("urlToBackToRooms") || localStorage.getItem("lastLocation")
    // history.push(`/${locale}${urlPart}`);
    if (localStorage.getItem("urlToBackToRooms")) {
      const urlPart = localStorage.getItem("urlToBackToRooms");
      history.push(`/${locale}${urlPart}`);
    } else {
      history.back();
    }
  };

  const bookerTravelsChoice = (e) => {
    // console.log('radio checked', e.target.value);
    setbookerTravels(e.target.value);
  };

  // const changeHandler = ( me ) => _ => {
  //      setAlign(me);
  //     }

  function NameInputFunc(e) {
    // console.log(e.target.value)
    return setNameInput(e.target.value);
  }

  function SurnameInputFunc(e) {
    // console.log(e.target.value)
    return setSurnameInput(e.target.value);
  }

  // const phonepattern="^(\+[0-9]{10}|\+[0-9]{12})$"

  // const phonepattern = /[0-9]/g

  const PhoneInputFunc = (e) => {
    //    if(e.target.value.match(phonepattern)){
    setPhoneInput(e.target.value);
    //  setPattern(true)
    // }
  };

  function EmailInputFunc(e) {
    // console.log(e.target.value)
    return setEmailInput(e.target.value);
  }

  function CommentsInputFunc(e) {
    // console.log(e.target.value)
    return setCommentsInput(e.target.value);
  }

  // ##### 24-06-11 Komarov
  function AgreedPriceInputFunc(e) {
    return setAgreedPriceInput(e.target.value);
  }

  // function changeOption(e){
  //   console.log(e.target.value)
  //   return setMyOption(e.target.value)
  // }

  // ##### 24-04-18 Komarov
  return (
    <form
      className={className}
      onSubmit={onSubmit}
      data-component="ClientDetailsForm"
    >
      <div className="ReadOnlyBlock">
        {/* ##### 24-04-25 Komarov */}
        <div className="ReadOnlyElement">
          <label className="FormLabelForReadOnlyDiv">{SupplierForm_city}</label>
          <input value={decodeURIComponent(cart.cityName)} readOnly />
        </div>
        <div className="ReadOnlyElement">
          <label className="FormLabelForReadOnlyDiv">{HotelTitle}</label>
          <input value={decodeURIComponent(cart.htlName)} readOnly />
        </div>
        {/* ##### 24-04-29 Komarov */}
        <div className="ReadOnlyElement">
          <label className="FormLabelForReadOnlyDiv">
            {StarRatingLabelText}
          </label>
          <input value={starRatingValue} readOnly />
        </div>
        <div className="ReadOnlyElement">
          <label className="FormLabelForReadOnlyDiv">{RoomType}</label>
          <input value={decodeURIComponent(cart.room_name)} readOnly />
        </div>
        <div className="ReadOnlyElement">
          <label className="FormLabelForReadOnlyDiv">{Tariff}</label>
          <input
            value={
              // ##### 24-04-26 Komarov
              cart.tariff_type_name +
              (parseInt(cart.nrf) === 1 ? " Non Refundable" : "")
            }
            readOnly
          />
        </div>
        <div className="ReadOnlyElement">
          <label className="FormLabelForReadOnlyDiv">{Adults}</label>
          <input value={cart.adults} readOnly />
        </div>
        {parseInt(cart.children) !== 0 && (
          <div className="ReadOnlyElement">
            <label className="FormLabelForReadOnlyDiv">{Children}</label>
            <input value={cart.children} readOnly />
          </div>
        )}
        <div className="ReadOnlyElement">
          <label className="FormLabelForReadOnlyDiv">{Rooms}</label>
          <input value={cart.numberofunits} readOnly />
        </div>
        {cart.amount !== "No price was shown" && (
          <div className="ReadOnlyElement">
            <label className="FormLabelForReadOnlyDiv">{TotalCostShort}</label>
            <input value={cart.amount} readOnly />
          </div>
        )}
        {/* ##### 24-04-26 Komarov */}
        <div className="ReadOnlyElement">
          <label className="FormLabelForReadOnlyDiv">{SelectedCurrency}</label>
          <input value={cart.selected_currency} readOnly />
        </div>
        <div className="ReadOnlyElement">
          <label className="FormLabelForReadOnlyDiv">{CartDetails_3}</label>
          <input value={cart.start} readOnly />
        </div>
        <div className="ReadOnlyElement">
          <label className="FormLabelForReadOnlyDiv">{CartDetails_4}</label>
          <input value={cart.end} readOnly />
        </div>
        {/* ##### 24-05-23 Komarov */}
        {registrationTimestamp && (
          <div className="ReadOnlyElement">
            <label className="FormLabelForReadOnlyDiv">
              {RegistrationDateTime}
            </label>
            <input
              value={moment(registrationTimestamp).format(dateTime[locale])}
              readOnly
            />
          </div>
        )}
      </div>
      <div className="InputBlock">
        <label className="FormLabel">
          {
            // ##### 23-10-04 Komarov
            ClientBookingForm
          }
        </label>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
            width: "100%",
          }}
        >
          <select style={{ marginRight: "0.5vw", width: "10%" }}>
            {
              // ##### 24-03-21 Komarov
              ClientTitles?.map((item) => {
                return (
                  // ##### 23-04-12 Komarov
                  <option key={uniqueId()}>{item}</option>
                );
              })
            }
          </select>

          <input
            type={"text"}
            // ##### 24-06-26 Komarov
            value={clientData.name || nameInput}
            onChange={NameInputFunc}
            placeholder={placeholder.placeHolderName}
            maxLength="50"
            style={{
              width: "45%",
              marginRight: "0.5vw",
            }}
            required
          />

          <input
            type={"text"}
            // ##### 24-06-26 Komarov
            value={clientData.surname || surnameInput}
            onChange={SurnameInputFunc}
            placeholder={placeholder.placeHolderSurname}
            maxLength="50"
            style={{ width: "45%" }}
            required
          />
        </div>
      </div>

      <div className="InputBlock">
        <label
          className="FormLabel"
          // ##### 23-07-10 Komarov
          //    for="phone"
          htmlFor="phone"
        >
          {
            // ##### 23-10-04 Komarov
            ClientBookingForm_1
          }
        </label>
        <InputMask
          mask="+999 99 999 9999"
          maskChar="_"
          alwaysShowMask={true}
          // ##### 24-06-26 Komarov
          value={clientData.phone || phoneInput}
          onChange={PhoneInputFunc}
        >
          {() => (
            <input
              type="tel"
              placeholder={placeholder.placeHolderPhone}
              required
            />
          )}
        </InputMask>
      </div>

      <div className="InputBlock">
        <label className="FormLabel">
          {
            // ##### 23-10-04 Komarov
            EmailAddress
          }
        </label>

        <input
          type={"email"}
          // ##### 24-06-26 Komarov
          value={clientData.email || emailInput}
          onChange={EmailInputFunc}
          placeholder={placeholder.placeHolderEmail}
          required
        />
      </div>

      {/* ##### 24-04-17 Komarov */}
      <div className="InputBlock">
        <label className="FormLabel">{Comments}</label>

        <input
          type={"text"}
          // ##### 24-06-26 Komarov
          value={clientData.comments || commentsInput}
          onChange={CommentsInputFunc}
        />
      </div>

      {/* ##### 24-06-11 Komarov */}
      {cart.amount === "No price was shown" && (
        <div className="InputBlock">
          <label className="FormLabel">{AgreedPrice}</label>

          <input
            type={"text"}
            value={agreedPriceInput}
            onChange={AgreedPriceInputFunc}
          />
        </div>
      )}

      {/* ##### 24-04-17 Komarov */}
      <div
        /* style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          marginTop: "3vh",
          width: "80%",
        }} */
        className="InputBlock"
      >
        <label className="FormLabel">
          {
            // ##### 23-10-04 Komarov
            DoYouBookForSMBDElse
          }
        </label>
        {/* ##### 24-04-17 Komarov */}
        {/* <div>
          <Radio.Group
            onChange={bookerTravelsChoice}
            value={bookerTravels}
            className="RadioForm"
          >
            <Radio
              style={{ color: "#102D69", fontWeight: "bold" }}
              value={1}
              key={1}
              // ##### 23-04-27 Komarov
              disabled={clicked === false ? false : true}
            >
              {
                // ##### 23-10-04 Komarov
                Yes
              }
            </Radio>
            <Radio
              style={{ color: "#102D69", fontWeight: "bold" }}
              value={0}
              key={0}
              //    ##### 23-04-27 Komarov
              disabled={clicked === false ? false : true}
            >
              {
                // ##### 23-10-04 Komarov
                No
              }
            </Radio>
          </Radio.Group>
        </div> */}
        <Radio.Group
          onChange={bookerTravelsChoice}
          value={bookerTravels}
          className="RadioForm"
        >
          <Radio
            style={{ color: "#102D69", fontWeight: "bold" }}
            value={1}
            key={1}
            // ##### 23-04-27 Komarov
            disabled={clicked === false ? false : true}
          >
            {
              // ##### 23-10-04 Komarov
              Yes
            }
          </Radio>
          <Radio
            style={{ color: "#102D69", fontWeight: "bold" }}
            value={0}
            key={0}
            //    ##### 23-04-27 Komarov
            disabled={clicked === false ? false : true}
          >
            {
              // ##### 23-10-04 Komarov
              No
            }
          </Radio>
        </Radio.Group>
      </div>
      <>
        {parseInt(bookerTravels) === 1 ? (
          <div className="InputBlock">
            <label className="FormLabel">{"Traveller Name Details"}</label>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-evenly",
                width: "100%",
              }}
            >
              <select style={{ marginRight: "0.5vw", width: "10%" }}>
                {
                  // ##### 24-03-21 Komarov
                  ClientTitles?.map((item) => {
                    return (
                      // ##### 23-04-12 Komarov
                      <option key={uniqueId()}>{item}</option>
                    );
                  })
                }
              </select>

              <input
                type={"text"}
                value={nameInput}
                onChange={NameInputFunc}
                // ##### 24-03-21 Komarov
                placeholder={"Name"}
                maxLength="50"
                style={{
                  width: "45%",
                  marginRight: "0.5vw",
                }}
                required
              />

              <input
                type={"text"}
                value={surnameInput}
                onChange={SurnameInputFunc}
                // ##### 24-03-21 Komarov
                placeholder={"Surname"}
                maxLength="50"
                style={{ width: "45%" }}
                required
              />
            </div>
          </div>
        ) : null}
      </>

      <div
        // ##### 24-04-29 Komarov
        // className="WrapperTermsConditions"
        className="ReadOnlyBlock TermsConditions"
      >
        <Checkbox
          onChange={AgreedFunc}
          defaultChecked={false}
          disabled={clicked === false ? false : true}
        />
        <div
          className="TermsConditions"
          onClick={ReadTermsFunc}
          // ##### 24-03-21 Komarov
          onKeyDown={(e) => {
            if (e.key === "Enter" || e.key === " ") {
              ReadTermsFunc();
            }
          }}
        >
          {
            // ##### 23-10-04 Komarov
            AgreeTermsCond
          }
        </div>
      </div>
      {readTerms === true ? (
        <TermsConditions
          readTerms={readTerms}
          closeTermsConditions={closeTermsConditions}
        />
      ) : null}

      {/* ##### 24-04-19 Komarov */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
        }}
      >
        {/* ##### 24-04-24 Komarov */}
        <ConfirmButton
          AddContacts={AddContacts}
          clicked={clicked}
          available_now={available_now}
        />

        {/* ##### 24-04-19 Komarov */}
        <button
          type="button"
          className="BackToRoomsButton"
          onClick={backToRooms}
        >
          {
            // ##### 23-10-04 Komarov
            BackToRooms
          }
        </button>
      </div>
      {
        // ##### 24-04-24 Komarov
        // clicked === true&&smart_order_id?(
        available_now && clicked === true && app_service_id.smart_reference ? (
          <>
            {
              // console.log('YES smart_reference',smart_reference)
            }
            <OnlineSummary
              name={nameInput}
              surname={surnameInput}
              phone={phoneInput}
              email={emailInput}
              // ##### 23-02-20 Komarov
              smart_service_id={app_service_id.service_id}
              cart={cart}
            />
            <SaveReference
              customer_reference={app_service_id.customer_reference}
            />
            <Pay
              //  ##### 23-02-20 Komarov
              smart_service_id={app_service_id.service_id}
              smart_order_id={app_service_id.booking_id}
              smart_reference={app_service_id.smart_reference}
              service_number={app_service_id.service_number}
              cart={cart}
              // ##### 24-03-21 Komarov
              client={`${nameInput} ${surnameInput}`}
            />
          </>
        ) : // ##### 23-02-20 Komarov
          // clicked === true&&!smart_order_id?(
          available_now && clicked === true && !app_service_id.smart_reference ? (
            <>
              {
                // console.log('NOT smart_reference',smart_reference)
              }
              <OfflineSummary wait={10000} />
              <IncompleteSendEmail
                name={nameInput}
                surname={surnameInput}
                phone={phoneInput}
                email={emailInput}
                // ##### 23-02-20 Komarov
                smart_service_id={app_service_id.service_id}
                cart={cart}
              />
            </>
          ) : null
      }
    </form>
  );
};

const ClientDetailsContext = createContext();

// ##### 24-09-11 Komarov
export const ClientDetails = ({ cart, formClassName }) => {
  const clientDetailsContextValue = useMemo(() => ({ cart }), [cart]); // value is cached by useMemo
  return (
    <ClientDetailsContext.Provider value={clientDetailsContextValue}>
      {/* ##### 23-11-08 Komarov */}
      <ClientDetailsForm className={formClassName} />
    </ClientDetailsContext.Provider>
  )
};
