import React, { useState, useEffect } from "react";
// ##### 24-04-19 Komarov
import { useLocation } from "react-router-dom";
// ##### 24-07-25 Komarov
import { useDispatch, useSelector } from "react-redux";
import { BookButtonHotel } from "../BookButtonHotel";
import { getOccupancySearch } from "../../../../../Redux/actions/hotelsearchdata";

import { Select } from "antd";
// ##### 24-04-05 Komarov
import { Media } from "../../../../../helpers/MediaConfig";
// ##### 24-04-19 Komarov
import { ValidateQuery } from "../../../Helpers/helper";

// ##### 23-09-14 Komarov
// import './RatesBlockCSS.css'

// ##### 24-09-09 Komarov
const BookButton = ({ props }) => {
  const {
    style,
    selectedAvailability,
    room_id,
    room_name,
    tariff_type_name,
    totalsum,
    tariff_id,
    contract_id,
    occupancy,
    pickedCurrency,
    refpartner,
    tariff_policy,
    start,
    end,
    available_now,
    path
  } = props;
  return (
    <h5 style={style} data-component="BookButton">
      <BookButtonHotel
        selectedAvailability={selectedAvailability}
        room_id={room_id}
        room_name={room_name}
        // ##### 24-04-17 Komarov
        tariff_type_name={tariff_type_name}
        // ##### 24-04-13 Komarov
        totalsum={(!isNaN(totalsum) && totalsum) || "No price was shown"}
        tariff_id={tariff_id}
        contract_id={contract_id}
        occupancy={occupancy}
        pickedCurrency={pickedCurrency}
        refpartner={refpartner}
        tariff_policy={tariff_policy}
        start={start}
        end={end}
        available_now={available_now}
        // ##### 24-04-19 Komarov
        cityName={decodeURIComponent(path.cityName)}
      />
    </h5>
  );
};

export const AvailableOptions = (props) => {
  // ##### 24-04-17 Komarov
  const {
    rooms,
    room_id,
    room_name,
    tariff_type_name,
    sum,
    tariff_id,
    availability,
    occupancy,
    contract_id,
    pickedCurrency,
    refpartner,
    tariff_policy,
    start,
    end,
    available_now,
    available_now_hotel,
  } = props;

  // ##### 24-04-19 Komarov
  let path;
  const location = useLocation();
  if (location.search) {
    path = ValidateQuery(location);
  }
  // const currencies = useSelector(state=>state.currency.currencies)
  // @@@@@ 23-01-30 Komarov: Исправил ошибку определения того, какой массив currencies нужно взять
  // ##### 24-07-25 Komarov
  const currenciesFromState = useSelector(state => state.currency.currencies);
  // ##### 24-07-25 Komarov
  const currencies =
    currenciesFromState.length > 0
      ? currenciesFromState
      : JSON.parse(localStorage.getItem("currency")).currencies;
  // console.log('PROPS', props)
  let exchangeRate;

  // ##### 24-03-21 Komarov
  for (const curr of currencies) {
    if (curr.name === pickedCurrency) {
      // ##### 24-02-22 Komarov
      // exchangeRate = parseInt(curr.value);
      exchangeRate = curr.value;
    }
  }

  const [selectedAvailability, setSelectedAvailability] = useState(
    parseInt(rooms, 10)
  );
  // ##### 24-02-22 Komarov
  const [totalsum, setTotalSum] = useState(
    (selectedAvailability * sum) / exchangeRate
  );

  const { Option } = Select;

  const dispatch = useDispatch();

  const SelectRooms = (value) => {
    setSelectedAvailability(value);
    setTotalSum((value * sum) / exchangeRate);
    // setPicked(true)
    // dispatch(getAvail(value,index))
  };

  useEffect(() => {
    dispatch(getOccupancySearch(selectedAvailability, occupancy));
    // ##### 23-04-12 Komarov
  }, [dispatch, selectedAvailability, occupancy]);

  // ##### 24-03-21 Komarov
  const empty_array = [];

  for (let i = 1; empty_array.length < availability; i++) {
    empty_array.push(i);
  }

  // console.log('AVAILABILITY', selectedAvailability, empty_array)

  // ##### 24-09-09 Komarov
  return (
    <>
      {/* ##### 24-04-05 Komarov */}
      {!!available_now_hotel && (
        <h5
          data-component="AvailableOptions"
          style={{
            fontSize: "17px",
            color: "#102D69",
            fontFamily: "Arial",
            fontWeight: "bold",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "white",
          }}
        >
          {pickedCurrency}{" "}
          {parseFloat((sum * selectedAvailability) / exchangeRate).toFixed(2)}
        </h5>
      )}

      {/* ##### 24-04-05 Komarov */}
      {!!available_now && (
        <h5
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "white",
          }}
        >
          <Select
            defaultValue={selectedAvailability}
            onChange={SelectRooms}
            bordered={true}
            size="middle"
          >
            <>
              {
                // ##### 24-03-21 Komarov
                empty_array?.map((item) => {
                  return (
                    <Option
                      className="AvailableOptions"
                      value={item}
                      key={item}
                    >
                      {item}
                    </Option>
                  );
                })
              }
            </>
          </Select>
        </h5>
      )}

      {/* ##### 24-04-05 Komarov */}
      <Media lessThan="Laptop-1024-1440">
        <BookButton
          props={{
            style: (!!available_now && {
              display: "flex",
              justifyContent: "center",
              alignItems: "middle",
              backgroundColor: "white",
              marginBottom: 0,
            }) || {
              margin: "1vw",
            },
            selectedAvailability,
            room_id,
            room_name,
            tariff_type_name,
            totalsum,
            tariff_id,
            contract_id,
            occupancy,
            pickedCurrency,
            refpartner,
            tariff_policy,
            start,
            end,
            available_now,
            path
          }}
        />
      </Media>
      <Media greaterThanOrEqual="Laptop-1024-1440">
        <BookButton
          props={{
            style:
              (!!available_now && {
                display: "flex",
                justifyContent: "center",
                alignItems: "middle",
                backgroundColor: "white",
                marginBottom: 0,
              }) ||
              (!!available_now_hotel && {
                borderLeft: "2px solid rgb(109, 109, 196)",
              }) ||
              null,
            selectedAvailability,
            room_id,
            room_name,
            tariff_type_name,
            totalsum,
            tariff_id,
            contract_id,
            occupancy,
            pickedCurrency,
            refpartner,
            tariff_policy,
            start,
            end,
            available_now,
            path
          }}
        />
      </Media>
    </>
  );
};
