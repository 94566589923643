// ##### 24-09-09 Komarov
import React, { createContext, useMemo } from "react";
import { SmallScreenTopToursDiv } from "./SmallScreenTopToursDiv";

// ##### 24-09-09 Komarov
export const SmallScreenTopTours = ({
  TopToursContents, GetTourDetails, TourTypes,
}) => {
  const smallScreenTopToursContextValue = useMemo(() => ({
    TopToursContents,
    GetTourDetails,
    TourTypes,
  }), [
    // ##### 24-09-11 Komarov
    TopToursContents,
    GetTourDetails,
    TourTypes,
  ]);
  return (
    <SmallScreenTopToursContext.Provider
      value={smallScreenTopToursContextValue}
    >
      <SmallScreenTopToursDiv width="100vw" className="lessThanOrEqual_425" />
    </SmallScreenTopToursContext.Provider>
  )
};

// ##### 23-10-10 Komarov

export const SmallScreenTopToursContext = createContext();
