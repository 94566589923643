// ##### 23-07-20 Komarov
import React from "react";
// ##### 23-10-04 Komarov
import moment from "moment";
// ##### 23-03-29 Komarov
import { DatePicker, Space, Select } from "antd";
import uniqueId from "lodash.uniqueid";
import { MenuClose } from "../../../../Icons/menuclose";
import CitiesTurkey from "../../../../StaticJsonData/CitiesTurkey.json";
import { PlaceHolderStrings } from "../../../../Localization/placeholders.js";
// ##### 24-04-23 Komarov
import { useMessages } from "../../../../../../helpers/messages";
// ##### 23-10-04 Komarov
import config from "../../../../../../Redux/config";

moment.locale(config.defaultLang);

// import '../GroupTravelFormCSS.css'

// ##### 23-10-04 Komarov
export const DestinationsBlock = ({
  destination,
  selectCountry,
  onChangeCity,
  onSearchCity,
  AddDestination,
  removeDestination,
  country,
  onChangeDates,
  status,
  // ##### 23-10-16 Komarov
  count,
  stayBlock,
}) => {
  // ##### 23-07-20 Komarov

  const { Option } = Select;
  const { RangePicker } = DatePicker;
  const placeholder = PlaceHolderStrings();
  // ##### 23-10-04 Komarov
  const dateFormat = "YYYY-MM-DD";

  // ##### 23-10-02 Komarov: вынес вычисление значений для рендеринга (вызовы функций) в код перед рендерингом, иначе возникают ошибки при рендеринге компонентов, скрытых до нажатия кнопки или другого события, по которому соотв. компонент появляется на странице.
  // ##### 24-04-23 Komarov
  const Group_request_destination = useMessages("Group_request_destination");

  // ##### 23-10-04 Komarov: функция для помечания полей обязательными для заполнения (она добавляет звёздочку к названию поля)
  const markReuired = (labelText) => `${labelText} *`;

  return (
    <div className="groupTravelblocks" data-component="DestinationsBlock">
      <span>{markReuired(Group_request_destination)}</span>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "40% 40% 20%",
          gridAutoRows: "auto",
          // ##### 23-10-16 Komarov
          marginTop: "0.5vh",
          marginBottom: "0.5vh",
        }}
      >
        {/* ##### 23-10-16 Komarov */}
        {count > 0 && (
          <ul
            style={{
              gridColumn: "1/4",
              gridRow: "1",
              width: "90%",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            {destination.length > 0
              ? destination.map((item, index) => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-evenly",
                      }}
                      key={uniqueId()}
                    >
                      {/* ##### 23-04-12 Komarov */}
                      <li key={uniqueId()}>
                        {/* ##### 24-03-21 Komarov */}
                        {`country : ${item.country} | city : ${item.city}`}
                        <Space direction="vertical" size={12}>
                          <RangePicker
                            onChange={onChangeDates(item)}
                            // ##### 23-10-04 Komarov
                            value={
                              (stayBlock.length > 0 &&
                                stayBlock[index] &&
                                stayBlock[index].stay_dates &&
                                stayBlock[index].stay_dates[0] !== "" &&
                                stayBlock[index].stay_dates[1] !== "" && [
                                  moment(
                                    stayBlock[index].stay_dates[0],
                                    dateFormat
                                  ),
                                  moment(
                                    stayBlock[index].stay_dates[1],
                                    dateFormat
                                  ),
                                ]) ||
                              null
                            }
                            format={dateFormat}
                            status={status}
                          />
                        </Space>
                      </li>
                      <MenuClose
                        size={"10%"}
                        color={"#E89090"}
                        removeDestination={removeDestination(item.ID)}
                      />
                    </div>
                  );
                })
              : null}
          </ul>
        )}

        <Select
          onChange={selectCountry}
          placeholder={placeholder.placeHolderCountry}
          bordered={true}
          size="middle"
          // ##### 23-10-04 Komarov
          status={status}
          style={{
            gridColumn: "1",
            gridRow: "2",
            //  minWidth:'5vw',
            marginRight: "0.5vw",
          }}
        >
          <Option value={"Moldova"} key={uniqueId()}>
            {"Moldova"}
          </Option>
          <Option value={"Turkey"} key={uniqueId()}>
            {"Turkey"}
          </Option>
          <Option value={"Ukraine"} key={uniqueId()}>
            {"Ukraine"}
          </Option>
        </Select>

        <Select
          showSearch
          placeholder={placeholder.placeHolderCity}
          optionFilterProp="children"
          onChange={onChangeCity}
          onSearch={onSearchCity}
          // ##### 23-10-04 Komarov
          status={status}
          style={{
            gridColumn: "2",
            gridRow: "2",
            marginRight: "0.5vw",
          }}
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {
            // ##### 24-03-21 Komarov
            CitiesTurkey?.map((item) => {
              if (country === item.country) {
                return (
                  <Option value={item.name} key={uniqueId()}>
                    {item.name}
                  </Option>
                );
              }
            })
          }
        </Select>
        <div className="groupTravelAddButton">
          {/* ##### 24-03-21 Komarov */}
          <button type="button" onClick={AddDestination}>
            {destination.length > 0 ? "+ Add more" : "+ Add"}
          </button>
        </div>
      </div>
    </div>
  );
};
