import { useEffect } from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import store from "../../../Redux/redux/store";
// ##### 23-03-29 Komarov
import { getTopHotels, getPurePageSsr } from "../../../Redux/actions";

// ##### 24-03-21 Komarov
export const SetAppData = (objectName) => {
  // locale берём из localStorage
  // TODO: При загрузке страницы с результатами поиска localStorage чистое, и язык в нём неопределён
  const locale = localStorage.getItem("currentLanguage");
  const deviceTypeFromState = useSelector((state) => state.deviceType);
  const pagesFromState = useSelector((state) => state.pages);
  const purePagesSsrFromState = useSelector(
    (state) => state.pages.purePagesSsr
  );
  const tophotelsFromState = useSelector((state) => state.tophotels);
  // ##### 23-03-28 Komarov
  // const hotels = useSelector(state => state.hotels);
  // const hotelstrans = useSelector(state => state.hotels.hotelstrans);
  const dispatch = useDispatch();

  // ##### 23-04-12 Komarov
  // Detection of present of the 'tophotels'
  useEffect(() => {
    // ##### 24-07-25 Komarov
    if (!(objectName === "tophotels" || objectName === "all")) {
      return;
    }
    // ##### 23-04-12 Komarov
    let flag = 0;
    // ##### 24-07-25 Komarov
    if (localStorage.getItem("tophotels")?.tophotels && localStorage.getItem("tophotels").tophotels.length > 0) {
      // console.log('*42 tophotels');
      // ##### 24-03-21 Komarov
      if (objectName === "tophotels") return;
      flag++;
    }
    // console.log(' Tophotels Local flag == 0:', flag == 0, flag);
    // console.log('*431 tophotels:', JSON.parse(localStorage.getItem('tophotels')));
    // ##### 24-07-25 Komarov
    if (flag === 0 && tophotelsFromState && tophotelsFromState.tophotels && tophotelsFromState.tophotels.length > 0) {
      // console.log('*441 tophotels');
      // ##### 24-07-25 Komarov
      localStorage.setItem("tophotels", JSON.stringify(tophotelsFromState));
      // ##### 24-03-21 Komarov
      if (objectName === "tophotels") return;
      flag++;
    }
    // console.log(' Tophotels Redux flag == 0:', flag == 0, flag);
    // console.log('*432 tophotels Local:', JSON.parse(localStorage.getItem('tophotels')));
    // console.log('*433 tophotels Redux:', tophotels);
    // ##### 24-03-21 Komarov
    if (flag === 0) {
      // console.log('*45 tophotels');
      // ##### 24-03-21 Komarov
      process.env.NODE_ENV === "development" &&
        console.log(
          "axios:src/Components/Pages/Helpers/SetAppData.js:getTopHotels()"
        );
      dispatch(getTopHotels());
    }
    // ##### 24-03-21 Komarov
    if (flag === 0) {
      // console.log('*46 tophotels');
      // ##### 24-07-25 Komarov
      localStorage.setItem("tophotels", JSON.stringify(tophotelsFromState));
    }
    // console.log(' Tophotels Axios flag == 0:', flag == 0, flag);
    // ##### 24-03-21 Komarov
    if (objectName === "tophotels") return;
    // ##### 24-07-25 Komarov
  }, [dispatch, tophotelsFromState, objectName]);

  // Detection of present of the 'purePagesSsr'
  useEffect(() => {
    // ##### 24-07-25 Komarov
    if (!(objectName === "purePagesSsr" || objectName === "all")) {
      return;
    }
    // ##### 23-04-12 Komarov
    let flag = 0;
    // console.log('*2 purePagesSsr');
    // ##### 23-09-14 Komarov
    if (
      localStorage.getItem("purePagesSsr") &&
      localStorage.getItem("purePagesSsr") !== "undefined" &&
      Array.from(Object.values(localStorage.getItem("purePagesSsr"))).length >
        0
    ) {
      // console.log('*20 purePagesSsr');
      // console.log("*201 localStorage.getItem('purePagesSsr'):", localStorage.getItem('purePagesSsr'));
      // ##### 24-03-21 Komarov
      if (objectName === "purePagesSsr") return;
      flag++;
    }

    // ##### 24-07-25 Komarov
    if (flag === 0 && purePagesSsrFromState) {
      localStorage.setItem(
        "purePagesSsr",
        JSON.stringify(pagesFromState.purePagesSsr)
      );
      // ##### 24-03-21 Komarov
      if (objectName === "purePagesSsr") return;
      flag++;
    }

    // ##### 24-03-21 Komarov
    if (flag === 0) {
      // console.log('*25 purePagesSsr');
      // ##### 24-03-21 Komarov
      process.env.NODE_ENV === "development" &&
        console.log(
          "axios:src/Components/Pages/Helpers/SetAppData.js:getPurePageSsr(locale, deviceType)"
        );
      // ##### 24-07-25 Komarov
      dispatch(getPurePageSsr(locale, deviceTypeFromState));
    }
    // console.log('*233 purePagesSsr Redux:', purePagesSsr);
    // ##### 24-03-21 Komarov
    if (flag === 0) {
      // console.log('*26 purePagesSsr');
      // ##### 24-07-25 Komarov
      localStorage.setItem(
        "purePagesSsr",
        JSON.stringify(pagesFromState.purePagesSsr)
      );
    }
    // console.log(' purePagesSsr Axios flag == 0:', flag == 0, flag);
    // ##### 24-03-21 Komarov
    if (objectName === "purePagesSsr") return;
    // ##### 24-07-25 Komarov
  }, [
    purePagesSsrFromState,
    pagesFromState.purePagesSsr,
    objectName,
    dispatch,
    locale,
    deviceTypeFromState,
  ]);

  // Detection of present of the 'page'
  let flag = 0;
  // ##### 24-03-21 Komarov
  if (objectName === "pages" || objectName === "all") {
    // console.log('*1 pages', pages);
    // ##### 24-07-25 Komarov
    if (localStorage.getItem("pages")?.pages && localStorage.getItem("pages").pages.length > 0 && objectName === "pages") return;
    if (pagesFromState && pagesFromState.pages.length > 0) {
      localStorage.setItem("pages", JSON.stringify(pagesFromState));
      // console.log('src/Components/Pages/Helpers/SetAppData.js:pages from localStorage (set)', JSON.parse(localStorage.getItem('pages')));
      // ##### 24-03-21 Komarov
      if (objectName === "pages") return;
      flag++;
    }

    // ##### 24-03-21 Komarov
    if (flag === 0) {
      // console.log('*15 pages');
      // console.log('src/Components/Pages/Helpers/SetAppData.js:pages from localStorage (flag = 0)', JSON.parse(localStorage.getItem('pages')));
      // ##### 24-03-21 Komarov
      process.env.NODE_ENV === "development" &&
        console.log(
          "axios:src/Components/Pages/Helpers/SetAppData.js:SetAppData, url:",
          `${process.env.REACT_APP_SMART_URL}interface/classifier?classifier=sitepage&language=${locale}`
        );
      axios
        .get(
          `${process.env.REACT_APP_SMART_URL}interface/classifier?classifier=sitepage&language=${locale}`
        )
        .then((res) => {
          // $$$$$ 23-01-27 Komarov: Исправил место добавления pages из Smart (на место pages.pages вместо pages, потому что на уровне первого pages по приложению присутствуют ещё другие данные)
          // localStorage.setItem('pages', JSON.stringify(res.data));
          // TODO: Если объект pages есть в localStorage, - не перезаписывать его (тогда чаловек зашёл на страницу с Результатами поиска с Главной страницы)
          const pagesFromLocalStorage = JSON.parse(
            localStorage.getItem("pages")
          );
          // console.log('src/Components/Pages/Helpers/SetAppData.js:pagesFromLocalStorage before', pagesFromLocalStorage);
          // ##### 23-04-07 Komarov
          if (pagesFromLocalStorage) {
            pagesFromLocalStorage.pages = res.data;
            localStorage.setItem(
              "pages",
              JSON.stringify(pagesFromLocalStorage)
            );
            // ##### 24-07-25 Komarov
            return;
          }
          // ##### 24-03-21 Komarov
          const pagesForLocalStorage = {};
          pagesForLocalStorage.pages = res.data;
          localStorage.setItem("pages", JSON.stringify(pagesForLocalStorage));
        });
      // ##### 24-03-21 Komarov
      if (objectName === "pages") return;
    }
  }

  // Detection of present of the 'currency'
  // ##### 24-07-25 Komarov
  if (!(objectName === "currency" || objectName === "all")) {
    return;
  }
  if (localStorage.getItem("currency")?.currencies && localStorage.getItem("currency").currencies.length > 0 && objectName === "currency") return;
  // ##### 24-07-25 Komarov
  if (store.getState().currency && store.getState().currency.currencies.length > 0) {
    // console.log('*34 currency');
    // $$$$$ 23-01-27 Komarov
    // localStorage.setItem('currency', JSON.stringify(store.getState().currency.currencies));
    localStorage.setItem(
      "currency",
      JSON.stringify(store.getState().currency)
    );
    // ##### 24-03-21 Komarov
    if (objectName === "currency") return;
    flag++;
  }
  // ##### 24-03-21 Komarov
  if (flag === 0) {
    // console.log('*35 currency');
    // ##### 24-03-21 Komarov
    process.env.NODE_ENV === "development" &&
      console.log(
        "axios:src/Components/Pages/Helpers/SetAppData.js:SetAppData, url:",
        `${process.env.REACT_APP_SMART_URL}interface/classifier?classifier=currency`
      );
    axios
      .get(
        `${process.env.REACT_APP_SMART_URL}interface/classifier?classifier=currency`,
        {}
      )
      .then((res) => {
        // $$$$$ 23-01-27 Komarov
        // localStorage.setItem('currency', JSON.stringify(res.data));
        // TODO: Сделать установку правильного значения pickedcurrency, потому что сейчас оно установлено прямо в коде, в приложении оно выбирается пользователем, а на текущем этапе не известно, так как из Смарта не приходит (спросить у Кати, как его лучше определить и правильно установить)
        localStorage.setItem(
          "currency",
          JSON.stringify({
            currencies: res.data,
            pickedcurrency: "UAH",
          })
        );
      });
    // ##### 24-03-21 Komarov
    if (objectName === "currency") return;
    // ##### 24-07-25 Komarov
    return;
  }
  flag = 0;
};
