// ##### 24-06-14 Komarov
import React, { useContext } from "react";
// ##### 24-08-10 Komarov
// import ArkturCollection from "../../../../Library/Images/ArkturCollection.jpg";
import { LoadingMessage } from "../../../../Library/PageDevices/LoadingMessage";
import uniqueId from "lodash.uniqueid";
import { SmallScreenTopToursContext } from "./SmallScreenTopTours";
// ##### 24-08-10 Komarov
import { TopToursTitle } from "../../ResponsiveHeader/Header/TopToursTitle";

export const SmallScreenTopToursDiv = ({ width, className = "" }) => {
  const { TopToursContents, GetTourDetails, TourTypes } = useContext(
    SmallScreenTopToursContext
  );
  return (
    <div className={className}>
      <div
        style={{
          padding: "19px",
          backgroundColor: "#005AEB",
          marginTop: "2vw",
          borderRadius: "5px",
        }} />
      <div style={{ textAlign: "center" }}>
        {/* ##### 24-03-18 Komarov: TODO: Взять перевод значения атрибута alt из Смарта */}
        {/* ##### 24-08-10 Komarov */}
        {/* <img
          src={ArkturCollection}
          alt="Artur Collection"
          style={{ width: width }} /> */}
        <TopToursTitle />
      </div>

      <div>
        <ul>
          {TourTypes.length > 0 ? (
            TourTypes.map((type) => {
              return (
                <div className="SmallerTopToursWrapper" key={uniqueId()}>
                  <h3
                    style={{
                      fontFamily: "Arial Narrow",
                      fontSize: "24px",
                      fontWeight: "bold",
                      color: "white",
                      textAlign: "center",
                      backgroundColor: "#005AEB",
                      borderRadius: "5px",
                      lineHeight: "6vh",
                      width: `${width}px`,
                    }}
                  >
                    {type}
                  </h3>
                  <ul
                    style={{
                      listStyle: "none",
                    }}
                  >
                    {TopToursContents.length > 0 ? (
                      TopToursContents.map((tour, index) => {
                        if (index < 12) {
                          return (
                            <li
                              key={tour.tour_id}
                              onClick={GetTourDetails}
                              // ##### 24-03-21 Komarov
                              onKeyDown={(e) => {
                                if (e.key === "Enter" || e.key === " ") {
                                  GetTourDetails();
                                }
                              }}
                            >
                              <div>
                                {tour.main_photo[0] ? (
                                  <img
                                    id={tour.tour_id}
                                    // ##### 24-03-21 Komarov
                                    alt={tour.tour_name}
                                    className="SmallerTopToursImage"
                                    style={{ width: width }}
                                    src={(process.env.REACT_APP_PROTOCOL ||
                                      "https://") + tour.main_photo[0]} />
                                ) : null
                                  // (<LoadingMessage itemWidth={`${width}px`}/>
                                  // )
                                }
                              </div>
                              {tour.main_photo[0] ? (
                                <div
                                  className="SmallerTopToursTitle"
                                  // ##### 23-03-08 Komarov
                                  style={{ width: width, textAlign: "center" }}
                                >
                                  <h4 id={tour.tour_id}>{tour.tour_name}</h4>
                                </div>
                              ) : null}
                            </li>
                          );
                        }
                      })
                    ) : (
                      <div>{null}</div>
                    )}
                  </ul>
                </div>
              );
            })
          ) : (
            <LoadingMessage itemWidth={`${width}px`} />
          )}
        </ul>
      </div>
    </div>
  );
};
