// @@@@@ 22-09-02 Komarov: Ширина экрана меньше 1024 - ширина экрана для мобильных устройств
// ##### 23-02-22 Komarov
export const desktopMaxWidth_1920 = 1920;
export const desktopMidWidth_1366 = 1366;
export const desktopMinUpWidth_1280 = 1280;
export const desktopMinWidth_1024 = 1024;
export const tabletMaxWidth_960 = 960;
export const tabletMidUpWidth_840 = 840;
export const tabletMidWidth_768 = 768;
// export const tabletMidWidth_768 = 786; // Kate's value
export const tabletMinWidth_512 = 512;
// ##### 23-03-16 Komarov
export const mobileMaxWidth_414 = 414;
export const mobileMidWidth_360 = 360;
export const mobileMinWidth_264 = 264;
// ##### 23-02-02 Komarov: добавил комментарии в стиле JSDoc, чтобы при наведении мышки во всплывающем окне отображались тексты функций
/**
 * ##### 23-02-22 Komarov
 * isMobileWidth = (width, height) => Math.min(width, height) >= mobileMinWidth_264 && Math.min(width, height) < tabletMinWidth_512
 */
// ##### 23-02-22 Komarov
export const isMobileWidth = (width, height) =>
  Math.min(width, height) >= mobileMinWidth_264 &&
  Math.min(width, height) < tabletMinWidth_512;
/**
 * ##### 23-02-22 Komarov
 * isTabletWidth = (width, height) => Math.max(width, height) >= tabletMinWidth_512 && Math.max(width, height) < desktopMinWidth_1024
 */
// ##### 23-02-22 Komarov
export const isTabletWidth = (width, height) =>
  Math.max(width, height) >= tabletMinWidth_512 &&
  Math.max(width, height) < desktopMinWidth_1024;
/**
 * ##### 23-02-22 Komarov
 * isDesktopWidth = (width) => width >= desktopMinWidth_1024
 */
// ##### 23-02-22 Komarov
export const isDesktopWidth = (width) => width >= desktopMinWidth_1024;
export const coefficient = 37.78; // Взял из размера синего прямоугольника на Главной странице из расчёта, что он 887px (0.65 * 1366px) на экране ширины 1366px
// ##### 23-07-10 Komarov
export const sliderFrequency = 9000;
