// ##### 24-06-14 Komarov
import React, { useContext } from "react";
import { useSelector } from "react-redux";
import uniqueId from "lodash.uniqueid";
import { HotelContent } from "../HotelContent/HotelContent";
import { HotelRates } from "../HotelRates/HotelRates";
import { HotelItemsContext } from "./HotelItems";

// import 'moment-timezone';
// ##### 24-04-02 Komarov: Отображение перечня отелей города или одного отеля, если в Autocomplete был выбран отель
// ##### 24-05-23 Komarov
export const UlTag = ({ ulCLassName, width, liClassName, hotelsContent }) => {
  const {
    hotelRate, search_data, filtered_city_name, date_difference, filtered_hotel_items, hotelstransorig,
  } = useContext(HotelItemsContext);
  // ##### 24-05-10 Komarov
  const locale = useSelector((state) => state.language);
  // ##### 24-04-05 Komarov
  return (
    <ul
      data-component="UlTag"
      className={ulCLassName}
      style={{
        width: `${width}px`,
      }}
    >
      <>
        {/* ##### 24-05-23 Komarov */}
        {hotelRate &&
          hotelRate.length > 0 &&
          hotelsContent &&
          Object.keys(hotelsContent).length > 0
          ? hotelRate.map((hotelTariff) => {
            if (hotelTariff) {
              // ##### 24-04-05 Komarov
              if (hotelTariff.dates) {
                return (
                  //  ##### 23-02-22 Komarov
                  <li
                    // ##### 24-04-05 Komarov
                    // key={hotelTariff.hotel_id}
                    key={uniqueId()}
                    className={liClassName}
                  >
                    <HotelRates
                      // ##### 24-04-05 Komarov
                      // key={hotelTariff.hotel_id}
                      key={uniqueId()}
                      hotelTariff={hotelTariff}
                      hotelRooms={hotelTariff.room_rates}
                      searchResults={search_data}
                      history={history}
                      hotelName={hotelTariff.hotel_name}
                      cityName={filtered_city_name}
                      date_difference={date_difference}
                      adults={search_data.adults}
                      pickedCurrency={search_data.selected_currency} />

                    {filtered_hotel_items.length > 0 && filtered_hotel_items
                      ? filtered_hotel_items.map((hotel) => {
                        if (parseInt(hotel.hotel_id) ===
                          parseInt(hotelTariff.hotel_id)) {
                          if (hotel) {
                            // ##### 23-02-17 Komarov
                            return (
                              <HotelContent
                                key={uniqueId()}
                                hotel={hotel}
                                // ##### 24-05-23 Komarov
                                hotelContent={hotelsContent[locale][hotelTariff.hotel_id]}
                                hotelTariff={hotelTariff}
                                cityName={filtered_city_name} />
                            );
                          }
                        }
                      })
                      : null}
                  </li>
                );
                // ##### 24-04-11 Komarov
              } else {
                // const translated_city_name = filtered_hotel_items[0].localized_city_name;
                const translated_city_name = filtered_hotel_items[0]?.localized_city_name ||
                  hotelstransorig[locale][0][localStorage.getItem("city_id")]
                    .name;
                const hotel = {
                  hotel_id: hotelTariff.hotel_id,
                  localized_city_name: translated_city_name,
                  localized_hotel_name: hotelstransorig[locale][1][hotelTariff.hotel_id]?.name ||
                    "No hotel name",
                };
                if (!hotelstransorig[locale][1][hotelTariff.hotel_id]) {
                  console.warn(
                    "There is no info for hotel with id in hotelstransorig",
                    hotelTariff.hotel_id,
                    ", current locale is",
                    locale
                  );
                }
                return (
                  <li key={uniqueId()} className={liClassName}>
                    <HotelRates
                      key={hotelTariff.hotel_id}
                      hotelTariff={hotelTariff}
                      hotelRooms={hotelTariff.room_rates}
                      searchResults={search_data}
                      history={history}
                      hotelName={hotelTariff.hotel_name}
                      cityName={filtered_city_name}
                      date_difference={date_difference}
                      adults={search_data.adults}
                      pickedCurrency={search_data.selected_currency} />

                    {/* ##### 24-05-23 Komarov */}
                    {hotelsContent &&
                      Object.keys(hotelsContent).length > 0 && (
                        <HotelContent
                          key={uniqueId()}
                          hotel={hotel}
                          // ##### 24-05-23 Komarov
                          hotelContent={hotelsContent[locale][hotelTariff.hotel_id]}
                          hotelTariff={hotelTariff}
                          cityName={filtered_city_name} />
                      )}
                  </li>
                );
              }
            }
          })
          : null}
      </>
    </ul>
  );
};
