// ##### 23-07-14 Komarov
export function ValidateQuery(location) {
  // ##### 23-07-28 Krat-Komarov
  // let data = location.search.replace('?','').split(',')
  // ##### 23-11-09 Komarov
  // let data = (typeof(location.search.replace) === "function" && location.search.replace('?','').split(',')) || location.replace('?','').split(',')
  // ##### 24-06-28 Komarov
  const isLocationSearchReplaceFunction = typeof location.search.replace === "function";
  const getSplittingSymbol = (stringToSplit) => {
    if (stringToSplit.includes('&')) return '&';
    return ',';
  }
  const data = (isLocationSearchReplaceFunction &&
    location.search.replace("?", "").split(getSplittingSymbol(location.search))) ||
    location.replace("?", "").split(getSplittingSymbol(location));

  const result = {};

  data.map((item) => {
    const name = item.match(/\w+(?==)/);
    const value = item.match(/\=.+/);
    // console.log('[REGULAR VALIDATION]', value[0].replace('=',''))

    // ##### 23-04-05 Komarov: поставил учёт случаев, когда name оказывается null, когда отсутствует location
    // result[name[0]] = decodeURI(value[0].replace('=',''));
    // ##### 23-11-23 Komarov
    // name && value ? result[name[0]] = decodeURI(value[0].replace('=','')) : true;
    if (name && value) {
      result[name[0]] = decodeURI(value[0].replace("=", ""));
    }

    // console.log('REGULAR', result)
  });

  // console.log('RESULTING', result)

  return result;
}

// ##### 24-04-17 Komarov
export function makeParamsString(paramsObject) {
  if (typeof paramsObject === "string") return paramsObject;
  let resultWithAmpersand = "";
  for (const key in paramsObject) {
    if (paramsObject[key] === "") {
      process.env.NODE_ENV === "development" &&
        console.warn(`Value of ${key} is empty`);
      continue;
    }
    if (paramsObject[key] === "Not set") {
      process.env.NODE_ENV === "development" &&
        console.warn(`Value of ${key} is not set`);
      continue;
    }
    resultWithAmpersand += `${key}=${encodeURIComponent(paramsObject[key])}&`;
  }
  return resultWithAmpersand.slice(0, -1);
}
