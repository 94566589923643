// ##### 24-09-09 Komarov
import React, { createContext, useMemo } from "react";
// ##### 24-08-10 Komarov
import { MediumScreenTopToursDiv } from "./MediumScreenTopHotelsDiv";

// ##### 24-09-09 Komarov
export const MediumScreenTopTours = ({
  TopToursContents, GetTourDetails, TourTypes,
}) => {
  const mediumScreenTopToursContextValue = useMemo(() => ({
    TopToursContents,
    GetTourDetails,
    TourTypes,
  }), [
    // ##### 24-09-11 Komarov
    TopToursContents,
    GetTourDetails,
    TourTypes,
  ]);
  return (
    <MediumScreenTopToursContext.Provider
      value={mediumScreenTopToursContextValue}
    >
      {/* ##### 24-08-10 Komarov */}
      <MediumScreenTopToursDiv
        // ##### 23-11-08 Komarov
        // width='29vw'
        width="45vw" />
    </MediumScreenTopToursContext.Provider>
  )
};

// ##### 23-10-10 Komarov

export const MediumScreenTopToursContext = createContext();
