// ##### 24-09-09 Komarov
import React, { useState, useEffect, createContext, useMemo } from "react";
import axios from "axios";
import parse from "html-react-parser";
import { useDispatch, useSelector } from "react-redux";
// ##### 23-04-12 Komarov
import { useLocation } from "react-router-dom";
import { OccupancyRates } from "../OccupancyRates.js";
import { getTourContents } from "../../../../../Redux/actions/tourcontents.js";
import { ValidateQuery } from "../../../Helpers/helper.js";
// ##### 23-09-14 Komarov
// import 'antd/dist/antd.css';
import { Moon } from "../../../../Library/Icons/moon.js";
import { Sun } from "../../../../Library/Icons/sun.js";
import { useWindowWidthAndHeight } from "../../../Helpers/WindowResizeHook.js";
// ##### 23-04-12 Komarov
import uniqueId from "lodash.uniqueid";
import { DivTag } from "./DivTag.js";

export const TourDetailsContext = createContext();

// ##### 24-03-21 Komarov
export const TourDetails = () => {
  // ##### 24-05-31 Komarov
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  // ##### 23-12-27 Komarov
  const locale = useSelector((state) => state.language);
  // ##### 24-03-21 Komarov
  const location = useLocation();
  const dispatch = useDispatch();

  const choiceDetailsNew = useSelector(
    (state) => state.tourcontents.tourcontents
  );
  // ##### 24-03-21 Komarov
  // const deviceType = useSelector(state => state.deviceType);
  // ##### 23-09-14 Komarov
  const search_data = ValidateQuery(location);

  useEffect(() => {
    // ##### 24-03-21 Komarov
    process.env.NODE_ENV === "development" &&
      console.log(
        "axios:src/Components/Pages/ThirdPageofSearchModule/Tours/TourDetails.js:getTourContents(search_data.tour_id, search_data.selection)"
      );
    dispatch(getTourContents(search_data.tour_id, search_data.selection));
    // ##### 23-04-12 Komarov
  }, [dispatch, search_data.tour_id, search_data.selection]);

  // console.log('choiceDetailsNew',choiceDetailsNew)

  let mappingExists;
  let hotelsExist;

  // ##### 24-03-21 Komarov
  for (const key in choiceDetailsNew[0]) {
    if (key.includes("mapping")) {
      if (choiceDetailsNew[0][key].join("").includes("no mapping hotels")) {
        mappingExists = "false";
      } else mappingExists = "true";
    }
  }
  // ##### 24-03-21 Komarov
  for (const key in choiceDetailsNew[0]) {
    if (key.includes("hotels")) {
      if (choiceDetailsNew[0][key].join("").includes("no attached hotels")) {
        hotelsExist = "false";
      } else hotelsExist = "true";
    }
  }
  // console.log('TEST',mappingExists,hotelsExist)

  // console.log('PROPS', props)
  // console.log('Tour Details Location', search_data.selection)

  // const totalPax = useSelector(state=>state.pax)

  const [details, setDetails] = useState([{}]);
  const [rateDetails, setrateDetails] = useState([{}]);
  const [selectionDetails, setSelection] = useState(search_data.selection);
  const [filteredDetails, setfilteredDetails] = useState([{}]);
  const [open, setOpen] = useState(false);

  // ##### 24-03-21 Komarov
  // const { Option } = Select;

  // ##### 23-04-12 Komarov
  const [/* width, */ height] = useWindowWidthAndHeight();

  useEffect(() => {
    // ##### 24-03-21 Komarov
    process.env.NODE_ENV === "development" &&
      console.log(
        "axios:src/Components/Pages/ThirdPageofSearchModule/Tours/TourDetails.js:TourDetails"
      );
    // ##### 23-03-27 Komarov
    process.env.NODE_ENV === "development" &&
      console.log(
        "axios:src/Components/Pages/ThirdPageofSearchModule/Tours/TourDetails.js:useEffect, url:",
        `${process.env.REACT_APP_SMART_URL}interface/content?id=${search_data.tour_id}&language=${locale}`
      );
    axios
      .get(
        `${process.env.REACT_APP_SMART_URL}interface/content?id=${search_data.tour_id}&language=${locale}`
      )
      .then((res) => {
        setDetails(res.data);
      })

      .catch((error) => {
        setDetails(undefined);
        // ##### 24-03-21 Komarov
        console.log("[axios error] : ", error);
      });
    //  ##### 23-04-12 Komarov
  }, [locale, search_data.tour_id]);

  // console.log('[DETAILED CONTENT]', details)
  const { user: currentUser } = useSelector((state) => state.auth);
  // ##### 24-03-01 Komarov
  const company_cipher = useSelector(
    (state) => state.whitelabel.company_cipher
  );

  // ##### 24-03-21 Komarov
  useEffect(() => {
    const ActionRQ = {
      username: process.env.REACT_APP_SMART_USERNAME,
      password: process.env.REACT_APP_SMART_PASSWORD,
      // "user_id" :1426,
      user_id: currentUser.user_id,
      action: "GetPriceTourRQ",
      data: {
        // ##### 24-03-01 Komarov
        company_cipher: company_cipher || 0,
        city_id: search_data.city_id,
        // date: currentMonth === search_data.date ? today : (search_data.date + '-01'),
        date: search_data.selection,
        window: 30,
        tour_id: search_data.tour_id,
      },
    };

    // axios.post((process.env.REACT_APP_PROTOCOL || 'https://') + 'hotels-ua.biz/interface/xmlsubj/', JSON.stringify({ActionRQ}))
    axios
      .post(
        `${process.env.REACT_APP_SMART_URL}interface/xmlsubj/`,
        JSON.stringify({ ActionRQ })
      )
      // axios.post('http://smartbooker/interface/xmlsubj/', JSON.stringify({ActionRQ}))
      .then((res) => {
        setrateDetails(res.data);
        // console.log('setrateDetails]' , res.data)
      })
      .catch((error) => {
        setrateDetails(undefined);
        console.log("[axios error] : ", error);
      });
    // ##### 24-03-21 Komarov
  }, [
    currentUser.user_id,
    search_data.city_id,
    search_data.selection,
    search_data.tour_id,
    company_cipher,
  ]);

  // console.log('[setrateDetails] : ' , rateDetails)

  // ##### 24-03-21 Komarov
  useEffect(() => {
    const ActionRQ = {
      username: process.env.REACT_APP_SMART_USERNAME,
      password: process.env.REACT_APP_SMART_PASSWORD,
      // "user_id" :1426,
      user_id: currentUser.user_id,
      action: "GetPriceTourRQ",
      data: {
        // ##### 24-03-01 Komarov
        company_cipher: company_cipher || 0,
        city_id: search_data.city_id,
        // date: currentMonth === search_data.date ? today : (search_data.date + '-01'),
        date: search_data.selection,
        window: 30,
        tour_id: search_data.tour_id,
      },
    };

    // axios.post((process.env.REACT_APP_PROTOCOL || 'https://') + 'hotels-ua.biz/interface/xmlsubj/', JSON.stringify({ActionRQ}))
    axios
      .post(
        `${process.env.REACT_APP_SMART_URL}interface/xmlsubj/`,
        JSON.stringify({ ActionRQ })
      )
      // axios.post('http://smartbooker/interface/xmlsubj/', JSON.stringify({ActionRQ}))
      .then((res) => {
        const filtered = res.data[0].rate.filter(
          (item1, index, array) =>
            array.findIndex((t) => t.date === item1.date) === index
        );

        setfilteredDetails(filtered);
        // console.log('[setfilteredDetails]' , filtered)
      })
      .catch((error) => {
        setfilteredDetails(undefined);
        // ##### 24-03-21 Komarov
        console.log("[axios error] : ", error);
      });
    // ##### 24-03-21 Komarov
  }, [
    currentUser.user_id,
    search_data.city_id,
    search_data.selection,
    search_data.tour_id,
    company_cipher,
  ]);

  function selectedPeriod(value) {
    // console.log('SELECTED : ' , value)
    setSelection(value);
  }

  const MakeVisible = () => {
    setOpen(!open);
  };

  // ##### 24-09-09 Komarov
  const tourDetailsContextValue = useMemo(() => ({
    details,
    height,
    rateDetails,
    selectionDetails,
    search_data,
    choiceDetailsNew,
    MakeVisible,
    selectedPeriod,
    filteredDetails,
  }), [
    // ##### 24-09-11 Komarov
    details,
    height,
    rateDetails,
    selectionDetails,
    search_data,
    choiceDetailsNew,
    MakeVisible,
    selectedPeriod,
    filteredDetails,
  ]); // ##### 24-09-10 Komarov

  return (
    <TourDetailsContext.Provider
      value={tourDetailsContextValue}
    >
      <div className="TourDetailsWrapper" data-component="TourDetails">
        <h2 style={{ marginTop: "4vh" }}>
          {search_data.tour_name.replace(/%20/g, " ")}
        </h2>
        <div className="Icons">
          <Sun />

          <div style={{ marginLeft: "0.4vw" }}>
            {rateDetails[0].duration} days
          </div>

          <div style={{ marginLeft: "0.4vw", marginRight: "0.4vw" }}> - </div>

          <Moon />

          <div style={{ marginLeft: "0.4vw" }}>
            {" "}
            {rateDetails[0].duration - 1} nights{" "}
          </div>
        </div>

        {/* ##### 23-04-20 Komarov */}
        <DivTag
          className="greaterThan-desktopMinWidth_1024 TourDetailsInner"
          width="60%"
        />
        <DivTag
          className="lessThanOrEqual-desktopMinWidth_1024 TourDetailsInnerSmallScreen"
          width="100%"
        />

        <div className="CostBreakdown">
          <h3>Cost breakdown</h3>
          <OccupancyRates
            // choiceDetailsNew={choiceDetailsNew}
            // hotelChoice={hotelChoice}
            selectionDetails={selectionDetails}
            tour_id={search_data.tour_id}
            // totalPax={AmountPax}
            choiceDetailsNew={choiceDetailsNew}
            mappingExists={mappingExists}
            hotelsExist={hotelsExist}
            pickedCurrency={search_data.selected_currency}
          />
        </div>

        <div>
          {
            // ##### 24-03-21 Komarov
            details?.map((item) => {
              if (item.content_name === "Body") {
                return (
                  // ##### 23-04-12 Komarov
                  <div key={uniqueId()} className="DescriptionTourDetails">
                    {/* ##### 23-10-10 Komarov: TODO: взять перевод из Смарта */}
                    <h3>Tour Itinerary</h3>
                    {parse(item.text)}
                  </div>
                );
              }
            })
          }
        </div>
      </div>
    </TourDetailsContext.Provider>
  );
};
