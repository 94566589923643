// ##### 24-06-14 Komarov
import React, { useContext } from "react";
// ##### 24-08-10 Komarov
// import ArkturCollection from "../../../../Library/Images/ArkturCollection.jpg";
import uniqueId from "lodash.uniqueid";
import { MediumScreenTopToursContext } from "./MediumScreenTopTours";
// ##### 24-08-10 Komarov
import { TopToursTitle } from "../../ResponsiveHeader/Header/TopToursTitle";

// ##### 24-08-10 Komarov
export const MediumScreenTopToursDiv = ({ width }) => {
  const { TopToursContents, GetTourDetails, TourTypes } = useContext(
    MediumScreenTopToursContext
  );
  return (
    <div>
      <div
        style={{
          padding: "19px",
          backgroundColor: "#005AEB",
          marginTop: "2vw",
          borderRadius: "5px",
        }} />
      <div style={{ textAlign: "center" }}>
        {/* ##### 24-03-18 Komarov: TODO: Брать перевод значения атрибута alt из Смарта */}
        {/* ##### 24-08-10 Komarov */}
        {/* <img src={ArkturCollection} alt="Arktur Collection" /> */}
        <TopToursTitle />
      </div>

      <div>
        <ul>
          {TourTypes.length > 0 ? (
            TourTypes.map((type) => {
              return (
                <div className="TopToursWrapper" key={uniqueId()}>
                  <h3
                    style={{
                      fontFamily: "Arial Narrow",
                      fontSize: "24px",
                      fontWeight: "bold",
                      color: "white",
                      textAlign: "center",
                      backgroundColor: "#005AEB",
                      borderRadius: "5px",
                      lineHeight: "6vh",
                      // ##### 23-11-08 Komarov
                      // width: `${width * 0.65}px`
                    }}
                  >
                    {type}
                  </h3>
                  <ul
                    style={{
                      display: "grid",
                      // ##### 23-11-08 Komarov
                      // gridTemplateColumns: `repeat(2, ${width * 0.6 / 2}px)`,
                      //  ##### 23-11-08 Komarov
                      //  gridTemplateColumns: `repeat(2, 16.8em)`,
                      // ##### 24-03-21 Komarov
                      gridTemplateColumns: "repeat(2, 50vw)",
                      listStyle: "none",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  >
                    {TopToursContents.length > 0 ? (
                      TopToursContents.map((tour, index) => {
                        if (index < 12) {
                          return (
                            <li
                              key={tour.tour_id}
                              onClick={GetTourDetails}
                              // ##### 24-03-21 Komarov
                              onKeyDown={(e) => {
                                (e.key === "Enter" || e.key === " ") &&
                                  GetTourDetails();
                              }}
                            >
                              {/* <TopToursDetails
                                                              tour_id={tour.tour_id}
                                                            />  */}
                              <div>
                                {tour.main_photo[0] ? (
                                  <img
                                    // ##### 24-03-21 Komarov
                                    alt={tour.tour_name}
                                    style={{ width }}
                                    id={tour.tour_id}
                                    className="TopToursImage"
                                    // width={`${width * 0.5 / 2}px`}
                                    src={(process.env.REACT_APP_PROTOCOL ||
                                      "https://") + tour.main_photo[0]} />
                                ) : null
                                  // (<LoadingMessage itemWidth={`${width*0.5/2}px`}/>)
                                }
                              </div>
                              {tour.main_photo[0] ? (
                                // ##### 23-11-08 Komarov
                                <div
                                  className="TopToursTitleTablet"
                                  style={{ width }}
                                >
                                  {/* ##### 23-03-30 Komarov */}
                                  <h4 id={tour.tour_id} style={{ width }}>
                                    {tour.tour_name.toUpperCase()}
                                  </h4>
                                </div>
                              ) : null}
                            </li>
                          );
                        }
                      })
                    ) : (
                      <div>{null}</div>
                    )}
                  </ul>
                </div>
              );
            })
          ) : (
            <div>{null}</div>
          )}
        </ul>
      </div>
    </div>
  );
};
