// ##### 24-09-09 Komarov
import React, { useEffect, useContext, createContext, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { changeLang } from "../../../../Redux/actions/locale";
import { LocalizationNavLink } from "../../../Library/Localization/LocalizationNavLink";
// ##### 24-03-01 Komarov
import { RouteSwitcher } from "../../RoutesAndLinks/RouteSwitcher";
import { LangSelectionTool } from "../../../Library/Localization/LangSelectionTool/LangSelectionTool";
import { CurrencySelectionTool } from "../../PageComponents/CurrencySelectionTool/CurrencySelectionTool";
import {
  getCurrency,
  getPickedCurrencyResponse,
} from "../../../../Redux/actions/currency";
import { ValidateQuery } from "../../Helpers/helper";
import { SeoLogoIco } from "../../../Library/SEO/SeoLogoIco";
import { SeoMetaTitle } from "../../../Library/SEO/SeoMetaTitle";
// ##### 23-09-21 Komarov
import { Search } from "../../FirstPageofSearchModule/SearchResizersAndSwitchers/SearchFront";
// ##### 24-04-23 Komarov
import { useMessages } from "../../../../helpers/messages";
// ##### 24-04-23 Komarov
import parse from "html-react-parser";
import { SeoMetaKeywords } from "../../../Library/SEO/SeoMetaKeywords";
import { SeoMetaDescription } from "../../../Library/SEO/SeoMetaDescription";

// ##### 23-09-21 Komarov
const HeaderClassDiv = ({ headerClass }) => {
  // ##### 24-03-01 Komarov
  const { backGroundImg, lang } = useContext(HeaderWhiteLabelContext);
  // ##### 24-03-07 Komarov
  const company_cipher = useSelector(
    (state) => state.whitelabel.company_cipher
  );
  // ##### 24-03-21 Komarov
  const imgTag = useSelector((state) => state.whitelabel.data[lang].ImageSvg);
  const img = parse(imgTag);
  // ##### 24-08-05 Komarov
  // const whiteLabelLogo = img.firstChild.getAttribute("src");
  const whiteLabelLogo = img.props.src;
  let path;
  const location = useLocation();
  if (location.search) {
    path = ValidateQuery(location);
  } else path = { selected_currency: "UAH", default: true };
  const deviceType = useSelector((state) => state.deviceType);
  // ##### 24-03-02 Komarov
  return (
    <div
      data-component="HeaderClassDiv"
      className={headerClass}
      style={{
        order: "0",
      }}
    >
      <div
        style={{
          display: "flex",
          // ##### 24-02-28 Komarov
          // flexDirection: 'row-reverse',
          // justifyContent: 'space-evenly',
          justifyContent: "space-around",
          // maxWidth: '80%'
        }}
      >
        {/* ##### 24-03-07 Komarov */}
        <LocalizationNavLink exact to={`/ref${company_cipher}`}>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "75%",
              marginTop: "2vh",
            }}
          >
            {/* ##### 24-03-21 Komarov */}
            {/* ##### 24-03-18 Komarov: TODO: Взять перевод значения атрибута alt из Смарта */}
            <img src={whiteLabelLogo} alt="White Label Logo" />
          </div>
        </LocalizationNavLink>
        {/* ##### 24-02-28 Komarov */}
        {/* <div style={{ marginLeft: '4vw', marginTop: '3vh' }}>
        <LangSelectionFlags />
      </div> */}
        <div className="LangCurrency">
          {/* TODO: Сделать html-разметку select-list'а с соотв. опциями (пунктами) */}
          {/* ##### 23-04-07 Komarov */}
          <CurrencySelectionTool
            path={path}
            language={lang}
            deviceType={deviceType}
          />
          {/* TODO: Сделать html-разметку select-list'а с соотв. опциями (пунктами) */}
          <LangSelectionTool
            language={lang}
            location={location}
            deviceType={deviceType}
          />
        </div>
      </div>
      {/* ##### 24-03-02 Komarov */}
      {/* ##### 24-03-18 Komarov: TODO: Взять перевод значения атрибута alt из Смарта */}
      <img
        alt="White Label Background"
        src={backGroundImg}
        style={{
          width: "100%",
        }}
      />
    </div>
  );
};

const HeaderWrapperMain = () => {
  const {
    // ##### 24-03-21 Komarov
    // toggler_type,
    // body_messages,
    // ##### 24-03-01 Komarov
    isMainPage,
  } = useContext(HeaderWhiteLabelContext);
  // ##### 23-09-21 Komarov
  return (
    <header
      data-component="HeaderWrapperMain"
      className="wrapperMain"
      style={{ display: "flex", flexDirection: "column" }}
    >
      <SeoLogoIco />
      <SeoMetaTitle />
      <SeoMetaKeywords />
      <SeoMetaDescription />
      <HeaderClassDiv headerClass="WhiteLabelTopMenu" />
      {/* ##### 24-03-01 Komarov */}
      {isMainPage && <Search style={{ order: "2" }} />}
      {/* ##### 24-02-28 Komarov */}
      {/* {
      toggler_type === "TOURS" ?
        <TopTours />
        :
        <TopHotels />
    }
    {
      body_messages.length > 0 ? body_messages.map((item) => {
        return (
          <BodyPage className="BodyPageClass"
            sidePageID={item.id}
            sitePageTypeID={item.sitepage_type_id}
            sitePageName={item.name}
            key={uniqueId()} />
        )
      }) : null
    } */}
      {/* ##### 24-03-01 Komarov */}
      <RouteSwitcher />
    </header>
  );
};

// ##### 23-10-10 Komarov
const HeaderWhiteLabelContext = createContext();

export const HeaderWhiteLabel = () => {
  // ##### 24-05-31 Komarov
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  // ##### 23-09-21 Komarov
  const toggler_type = useSelector((state) => state.tophotels.toggler_type);
  // ##### 24-04-23 Komarov
  const body_messages = Object.values(useMessages()).filter(
    (item) => parseInt(item.sitepage_region_id) === 13
  );

  let path;
  const location = useLocation();
  if (location.search) {
    path = ValidateQuery(location);
  } else path = { selected_currency: "UAH", default: true };

  const dispatch = useDispatch();
  dispatch(getPickedCurrencyResponse(path.selected_currency));

  const lang = useSelector((state) => state.language);
  // ##### 24-07-25 Komarov
  const currencies = useSelector((state) => state.currency.currencies);

  // Думал, что язык работает и без этого запроса
  useEffect(() => {
    dispatch(changeLang(lang));
    // ##### 23-04-12 Komarov
  }, [lang, dispatch]);
  // Запрашиваем валюту из Смарта для сайта
  useEffect(() => {
    // ##### 24-07-25 Komarov
    (process.env.NODE_ENV === "development" &&
      currencies.length > 0) ||
      console.log(
        "axios:src/Components/Pages/PageComponents/ResponsiveHeader/HeaderWhiteLabel.js:getCurrency()"
      );
    // dispatch(getCurrency());
    currencies.length > 0 ||
      dispatch(getCurrency());
    // ##### 23-04-12 Komarov
  }, [dispatch]);

  // ##### 24-03-01 Komarov
  // const backGroundImg = `url(${RotaryHeader}`;
  // ##### 24-08-05 Komarov
  // const imgTag = useSelector((state) => state.whitelabel.data[lang].Image);
  const whitelabel = useSelector((state) => state.whitelabel);
  const isWhitelabelSet = whitelabel.set;
  const imgTag = isWhitelabelSet && whitelabel.data[lang].Image;
  const img = isWhitelabelSet && parse(imgTag);
  // const backgroundImage = isWhitelabelSet && img.firstChild.getAttribute("src");
  const backgroundImage = isWhitelabelSet && img.props.src;
  const currentUrl = useSelector((state) => state.currentUrl);
  const company_cipher = useSelector(
    (state) => state.whitelabel.company_cipher
  );
  // ##### 24-08-05 Komarov
  const isMainPage = isWhitelabelSet &&
    company_cipher.length + currentUrl.indexOf(company_cipher) ===
    currentUrl.length;

  // ##### 24-09-09 Komarov
  const headerWhiteLabelContextValue = useMemo(() => ({
    // ##### 24-08-05 Komarov
    backGroundImg: backgroundImage,
    // ##### 24-02-28 Komarov
    lang,
    toggler_type,
    body_messages,
    // ##### 24-03-01 Komarov
    isMainPage,
  }), [
    // ##### 24-09-11 Komarov
    backgroundImage,
    // ##### 24-02-28 Komarov
    lang,
    toggler_type,
    body_messages,
    // ##### 24-03-01 Komarov
    isMainPage,
  ]);

  return (
    <HeaderWhiteLabelContext.Provider
      value={headerWhiteLabelContextValue}
    >
      {/* ##### 24-08-05 Komarov */}
      {(isWhitelabelSet && <HeaderWrapperMain />) || <div>Loading...</div>}
    </HeaderWhiteLabelContext.Provider>
  );
};
