// ##### 23-10-18 Komarov
import { createMedia } from "@artsy/fresnel";

/* export const mediaBreakpoints = {
  sm: 0,
  md: 768,
  lg: 1024,
  xl: 1192,
}; */

// ##### 23-10-18 Komarov: генерируем точки границ ширины экрана. Названия диапазонов до максимального будет такого типа: '320-330'. А диапазон с максимальной шириной - '1920-inf' (от 1920 до бесконечности).
/* const minWidth = 130;
const maxWidth = 1920;
const step = 10;
let breakpoints = {};
for (let i = minWidth; i < maxWidth; i += step) {
  breakpoints[`${i}-${i + step > maxWidth && 'inf' || i + step}`] = i;
} */

// ##### 23-10-31 Komarov
const breakpoints = {
  "Mobile-XS-136-320": 136,
  "Mobile-S-320-375": 320,
  "Mobile-M-375-425": 375,
  "Mobile-L-425-768": 425,
  "Tablet-768-1024": 768,
  "Laptop-1024-1440": 1024,
  "Laptop-L-1440-2560": 1440,
  "4k-2560-inf": 2560,
};

export const interactions = {
  hover: "(hover: hover)",
  notHover: "(hover: none)",
  landscape: "not all and (orientation: portrait)",
  portrait: "not all and (orientation: landscape)",
};

const AppMedia = createMedia({
  breakpoints,
  interactions,
});

// Generate CSS to be injected into the head
export const mediaStyle = AppMedia.createMediaStyle();
export const { Media, MediaContextProvider } = AppMedia;
export { breakpoints };

/*
Bootstrap screen breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
); */

/*
Browser Dev Tools screen breakpoints
Mobile S - 320 px
Mobile M - 375 px
Mobile L - 425 px
Tablet - 768 px
Laptop - 1024 px
Laptop L - 1440 px
4k - 2560 px
*/
