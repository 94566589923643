// ##### 24-09-09 Komarov
import React, { useState, useEffect, createContext, useMemo } from "react";
// ##### 23-04-12 Komarov
// ##### 24-04-23 Komarov
import { useMessages } from "../../../../../helpers/messages";
// ##### 23-10-30 Komarov
import { Media } from "../../../../../helpers/MediaConfig";
import { DivTag } from "./DivTag";

// ##### 23-10-10 Komarov
export const ContentBlockContext = createContext();

// ##### 24-03-21 Komarov
export const ContentBlock = ({ hotelcontents, search_data }) => {
  // ##### 24-04-23 Komarov
  const HotelAddress = useMessages("HotelAddress");

  // console.log('[HOTELCONTENTS]', hotelcontents)
  // ##### 23-03-29 Komarov
  const [photoHeight /* setPhotoHeight */] = useState("55vh");

  const [show, setShow] = useState(true);

  // ##### 24-05-30 Komarov
  /* useEffect(() => {
    const timer = setTimeout(() => setShow(false), 3000);
    return () => {
      clearTimeout(timer);
    };
  }, []); */
  const showContent = !(
    hotelcontents?.length > 0 &&
    parseInt(search_data.id) === parseInt(hotelcontents[1].id)
  );
  useEffect(() => {
    setShow(showContent);
  }, [showContent]);

  // console.log('WIDTH',width)

  // ##### 24-09-09 Komarov
  const contentBlockContextValue = useMemo(() => ({
    show,
    hotelcontents,
    HotelAddress,
    search_data,
    photoHeight,
  }), [
    // ##### 24-09-11 Komarov
    show,
    hotelcontents,
    HotelAddress,
    search_data,
    photoHeight,
  ]); // ##### 24-09-10 Komarov

  return (
    //  <div className={`${width>768?"ContentDetails":"ContentDetailsSmallScreen"}`}>
    // ##### 23-02-22 Komarov
    <ContentBlockContext.Provider
      value={contentBlockContextValue}
    >
      {/* ##### 23-10-31 Komarov */}
      <Media lessThan="Tablet-768-1024">
        <DivTag className="ContentDetailsSmallScreen" />
      </Media>
      <Media greaterThanOrEqual="Tablet-768-1024">
        <DivTag className="ContentDetails" />
      </Media>
    </ContentBlockContext.Provider>
  );
};
