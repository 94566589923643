// ##### 23-12-27 Komarov
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { EmailNotify } from "./EmailNotify";
// ##### 24-08-05 Komarov
// import config from "../../../Redux/config";

// ##### 23-02-20 Komarov
export const OnlineSummary = ({
  name,
  surname,
  phone,
  email,
  smart_service_id,
  cart,
}) => {
  const [ModifyClientsRQ_Add, setModifyClientsRQ_Add] = useState([]);
  const { user: currentUser } = useSelector((state) => state.auth);

  // ##### 23-12-27 Komarov
  const locale = useSelector((state) => state.language);
  // ##### 24-03-01 Komarov
  const company_cipher = useSelector(
    (state) => state.whitelabel.company_cipher
  );
  // ##### 24-08-05 Komarov
  const company_id = useSelector(state => state.auth.user.company_id);

  // console.log('LOCAL',locale)

  useEffect(() => {
    // ##### 24-03-21 Komarov
    const ActionRQ = {
      username: process.env.REACT_APP_SMART_USERNAME,
      password: process.env.REACT_APP_SMART_PASSWORD,
      // "user_id" :1426,
      user_id: currentUser.user_id,
      // ##### 24-08-05 Komarov
      refpartner: /* cart.refpartner ? cart.refpartner :  */company_id,
      action: "ModifyClientsRQ",
      language: locale,
      data: {
        // ##### 24-03-01 Komarov
        company_cipher: company_cipher || 0,
        // ##### 23-02-20 Komarov
        service_id: smart_service_id,
        service_number: 1,
        clients: [
          {
            operation: "add",
            client_type: "buyer",
            site_client_id: 1,
            name,
            surname,
            phone,
            email,
          },
          {
            operation: "add",
            client_type: "client",
            site_client_id: 2,
            name,
            surname,
            phone,
            email,
          },
        ],
      },
    };

    axios
      .post(
        `${process.env.REACT_APP_SMART_URL}interface/xmlsubj/`,
        JSON.stringify({ ActionRQ })
      )
      .then((response) => setModifyClientsRQ_Add(response.data));

      // ##### 24-08-05 Komarov
  }, [
    currentUser.user_id,
    company_id,
    locale,
    smart_service_id,
    name,
    surname,
    phone,
    email,
    company_cipher,
  ]);

  // console.log('ModifyClientsRQ_Add',ModifyClientsRQ_Add)

  return (
    <>
      {/* <div>Thank your reservation!</div> */}
      <EmailNotify
        name={name}
        surname={surname}
        phone={phone}
        email={email}
        // ##### 23-02-20 Komarov
        // app_service_id = {smart_service_id}
        smart_service_id={smart_service_id}
        cart={cart}
      />
      <div data-component="OnlineSummary">
        {
          ModifyClientsRQ_Add.length > 0 &&
            ModifyClientsRQ_Add.map((/* item */) => {
              return (
                <>
                  {/* <h4>{item.action}</h4> */}
                  {/* ##### 23-10-06 Komarov: TODO: брать перевод из Смарта */}
                  <div>Thank you for your reservation!</div>
                </>
              );
            })
          //  ):<OfflineSummary wait={2000}/>
        }
      </div>
    </>
  );
};
