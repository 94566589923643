import axios from "../helpers/public.axios";

import {
  GET_TOPHOTELS_REQ,
  GET_TOPHOTELS_RES,
  GET_TOPHOTELS_ERR,
} from "../constants";
// ##### 23-03-29 Komarov
import {
  GET_TOGGLER_TYPE_REQ,
  GET_TOGGLER_TYPE_RES /* ,GET_TOGGLER_TYPE_ERR */,
} from "../constants";

export const tophotelsResponse = (res) => ({
  type: GET_TOPHOTELS_RES,
  payload: res,
});

// ##### 24-06-13 Komarov
export const getTopHotels =
  (deviceType = "desktop") =>
  (dispatch) => {
    // ##### 23-03-06 Komarov
    process.env.NODE_ENV === "development" &&
      console.log("axios:src/Redux/actions/tophotels.js:getTopHotels");

    dispatch({ type: GET_TOPHOTELS_REQ });

    // ##### 24-03-21 Komarov
    process.env.NODE_ENV === "development" &&
      console.log(
        "axios:src/Redux/actions/tophotels.js:getTopHotels, url:",
        `${process.env.REACT_APP_SMART_URL}interface/tophotels?&deviceType=${deviceType}`
      );
    axios
      .get(
        `${process.env.REACT_APP_SMART_URL}interface/tophotels?&deviceType=${deviceType}`,
        {}
      )
      .then((res) => {
        // console.log('[TOP TOURS] : ' , res.data)
        dispatch(tophotelsResponse(res.data));
      })
      .catch((err) => {
        dispatch({ type: GET_TOPHOTELS_ERR, error: err });
      });
  };

export const togglerTypeResponse = (res) => ({
  type: GET_TOGGLER_TYPE_RES,
  payload: res,
});

// ##### 24-03-21 Komarov
export const getTogglerType = (param) => (dispatch) => {
  dispatch({ type: GET_TOGGLER_TYPE_REQ });
  dispatch(togglerTypeResponse(param));
};
