// ##### 23-03-29 Komarov
import React, { useState } from "react";
import { CloseCircleFilled } from "@ant-design/icons";

// ##### 23-09-14 Komarov
// import './ClearInputCSS.css';

// ##### 24-05-10 Komarov
export const ClearInput = ({ clearInput }) => {
  /* return(
        <div className={ makevisible === true ? 'visibleCross' : 'notVisibleCross' }
             onClick={clearInput}>
            <CloseCircleFilled style={{color:'slategrey'}}/>
        </div>
    ) */

  const [crossColor, setCrossColor] = useState("#c9c9c9");

  const onMouseEnter = () => {
    setCrossColor("slategrey");
  };

  const onMouseLeave = () => {
    setCrossColor("#c9c9c9");
  };

  return (
    <div
      data-component="ClearInput"
      className="visibleCross"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={clearInput}
      // ##### 24-03-21 Komarov
      onKeyDown={(event) => {
        if (event.key === "Enter") {
          clearInput();
        }
      }}
    >
      {/* <CloseCircleFilled style={{color:'#96c6f0'}}/> */}
      <CloseCircleFilled style={{ color: crossColor }} />
    </div>
  );
};
