// ##### 23-09-26 Komarov
import React from "react";
import { CupOfCoffee } from "./Animation/CupOfCoffee";
// ##### 24-04-23 Komarov
import { useMessages } from "../../../helpers/messages";
// ##### 23-12-29 Komarov
import { useLocation } from "react-router-dom";
import { ValidateQuery } from "../../Pages/Helpers/helper";
import parse from "html-react-parser";

// import './LoadingMessage.css'
// ##### 24-09-09 Komarov
const NoSearchResultMessageStart = ({ noTextMessage }) => {
  return (
    <h3 className="LoadingText" data-component="LoadingMessage">
      {!noTextMessage
        ? parse(useMessages("NoSearchResultMessageStart"))
        : null}
    </h3>
  );
};
const NoSearchResultMessageEnd = ({ noTextMessage }) => {
  return (
    <h3 className="LoadingText" data-component="NoSearchResultMessageEnd">
      {!noTextMessage ? parse(useMessages("NoSearchResultMessageEnd")) : null}
    </h3>
  );
};

// ##### 23-07-10 Komarov
export const LoadingMessage = ({
  loadingMessageClass,
  itemWidth,
  noTextMessage,
  noBackground,
  smartResultFound,
}) => {
  // ##### 24-04-23 Komarov
  // const NoSearchResultMessage = useMessages("NoSearchResultMessage");
  const PleaseInputCityorHotelName = useMessages("PleaseInputCityorHotelName");
  const StartDate = useMessages("StartDate");
  const EndDate = useMessages("EndDate");
  const Adults = useMessages("Adults");
  const Children = useMessages("Children");
  const Rooms = useMessages("Rooms");
  const SelectedCurrency = useMessages("SelectedCurrency");
  const location = useLocation();
  const search_data = ValidateQuery(location);

  return (
    <div
      className={loadingMessageClass ? loadingMessageClass : "Loading"}
      style={{
        width: `${itemWidth ? itemWidth : null}`,
        // @@@@@ 23-01-30 Komarov: Разместил надпись по центру вертикально
        //  display:'block',
        // ##### 23-11-22 Komarov
        //  position:'absolute',
        zIndex: "30000",
        backgroundColor: `${noBackground ? "white" : "#cce0ff"}`,
        borderRadius: "5px",
      }}
    >
      {
        // ##### 23-02-17 Komarov: Отображаем текст с предложением обратиться на email в том случае, если из Смарта пришёл ответ с пустым результатом (не нашлось отелей по этому результату)
        // ##### 23-12-29 Komarov
        smartResultFound !== "Hi!" ? (
          <CupOfCoffee />
        ) : (
          /* ( <h3 className='LoadingText'>
                  {
                    !noTextMessage?(
                        // ##### 23-10-04 Komarov
                        NoSearchResultMessage
                       ):null
                    }   
              </h3>) */
          <>
            <NoSearchResultMessageStart noTextMessage={noTextMessage} />
            <div>
              {PleaseInputCityorHotelName}: {search_data.title}
            </div>
            <div>
              {StartDate}: {search_data.start}
            </div>
            <div>
              {EndDate}: {search_data.end}
            </div>
            <div>
              {Adults}: {search_data.adults}
            </div>
            <div>
              {Children}: {search_data.children}
            </div>
            <div>
              {Rooms}: {search_data.rooms}
            </div>
            <div>
              {SelectedCurrency}: {search_data.selected_currency}
            </div>
            <NoSearchResultMessageEnd noTextMessage={noTextMessage} />
          </>
        )
      }
    </div>
  );
};
