// ##### 23-12-27 Komarov
import React, { useEffect } from "react";
// ##### 23-03-29 Komarov
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { changeLang } from "../../../../Redux/actions/locale";
// ##### 24-04-17 Komarov
import { ValidateQuery, makeParamsString } from "../../Helpers/helper";

// ##### 24-04-19 Komarov
export const HotelBookButton = ({
  innerText,
  hotel,
  hotelsearch,
  hotelName,
  cityName,
}) => {
  // console.log('HOTEL_HOTEL',hotel)
  // ##### 23-12-27 Komarov
  const locale = useSelector((state) => state.language);
  // ##### 24-02-22 Komarov
  const search_data = ValidateQuery(hotelsearch);
  const promoCode = useSelector((state) => state.promocode.promocode);
  // ##### 24-03-01 Komarov
  const whitelabel = useSelector((state) => state.whitelabel);
  const isWhiteLabelSet = whitelabel.set;
  const company_cipher = whitelabel.company_cipher;
  // const pickedcurrency = useSelector(state=>state.currency.pickedcurrency)
  // ##### 24-02-22 Komarov
  const pickedcurrencyFromState = useSelector(
    (state) => state.currency.pickedcurrency
  );
  // ##### 24-07-25 Komarov
  const pickedcurrency = pickedcurrencyFromState || JSON.parse(localStorage.getItem("currency")).pickedcurrency;

  // console.log('HOTELSEARCH',search_data )
  // const location = useLocation()
  const history = useHistory();

  // ##### 23-03-29 Komarov
  // const lang = useSelector(state=>state.locale.locale)
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(changeLang());
    // ##### 23-04-12 Komarov
  }, [dispatch]);

  // ##### 24-04-12 Komarov
  const redux_pax_data = useSelector((state) => state.paxchoice.pax);

  const addToHotelDetails = (e) => {
    // dispatch(getPaxInnerSearch(search_data.adults, search_data.children, search_data.rooms))
    e.preventDefault();

    // ##### 24-04-05 Komarov
    localStorage.setItem("selectedItemInfo", JSON.stringify(hotel));

    const params_string = makeParamsString({
      selected_currency:
        localStorage.getItem("current_currency") || pickedcurrency,
      title: hotel.eng_hotel_name || "",
      // ##### 24-04-19 Komarov
      // cityName: hotel.eng_city_name || "",
      cityName: cityName || "",
      start: search_data.start || "",
      end: search_data.end || "",
      id: hotel.hotel_id,
      city_id: search_data.city_id || hotel.city_id,
      adults: search_data.adults || redux_pax_data.adults || "Not set",
      // ##### 24-05-28 Komarov: 0 в логическом выражении с использованием || превращается в false, и так как значение children по умолчанию у нас 0, и этот 0 нужен, я его преобразовываю в строку
      children:
        search_data.children ||
        (!isNaN(redux_pax_data.children) &&
          typeof redux_pax_data.children !== "undefined" &&
          String(redux_pax_data.children)) ||
        "Not set",
      rooms: search_data.rooms || redux_pax_data.rooms || "Not set",
      // ##### 24-04-19 Komarov
      // hotel_name: hotel.eng_hotel_name || "",
      hotel_name: hotelName || "",
      hotel_id: hotel.hotel_id,
    });

    // ##### 24-02-29 Komarov: TODO: Убрать промокод из URL
    if (isWhiteLabelSet) {
      const route_query = `?${params_string}`;

      history.push(
        `/${locale}/ref${company_cipher}/hotel_details/${route_query}`
      );
    } else if (promoCode) {
      // ##### 24-08-05 Komarov
      const route_query = `?${params_string}`;

      history.push(`/${locale}/hotel_details/${route_query}`);
    } else {
      const route_query = `?${params_string}`;

      history.push(`/${locale}/hotel_details/${route_query}`);
    }
  };

  return (
    <button
      data-component="HotelBookButton"
      type="submit"
      className={
        (!!hotel.available_now && "availableButtonHotel") ||
        "onRequestButtonHotel"
      }
      onClick={addToHotelDetails}
    >
      {innerText}
    </button>
  );
};
