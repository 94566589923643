// ##### 23-09-26 Komarov
import React, { useState, useEffect } from "react";
import parse from "html-react-parser";
// ##### 24-04-23 Komarov
import { useMessages } from "../../../helpers/messages";

// ##### 23-09-14 Komarov
// import './OfflineSummary.css'

export const OfflineSummary = ({ wait }) => {
  // ##### 24-06-27 Komarov
  /* useEffect(() => {
    window.scrollTo(0, 0);
  }, []); */
  const [hidden, setHidden] = useState("Hidden");
  // ##### 24-04-23 Komarov
  const OfflineBookingFormMessage = useMessages("OfflineBookingFormMessage");

  useEffect(() => {
    setTimeout(Show, wait);
    // ##### 23-04-12 Komarov
  }, [wait]);

  const Show = () => {
    setHidden("");
  };

  return (
    <h2 className={hidden} data-component="OfflineSummary">
      {
        // ##### 23-10-04 Komarov
        parse(OfflineBookingFormMessage)
      }
    </h2>
  );
};
