import axios from "../helpers/public.axios";
import {
  GET_CURRENCY_REQ,
  GET_CURRENCY_RES,
  GET_CURRENCY_ERR,
} from "../constants";
// ##### 23-03-29 Komarov
import {
  GET_PICKEDCURRENCY_REQ,
  GET_PICKEDCURRENCY_RES /* ,GET_PICKEDCURRENCY_ERR */,
} from "../constants";
// ##### 23-03-31 Komarov
import { GET_CURRENCY_RES_SSR } from "../constants";

export const currencyResponse = (res) => ({
  type: GET_CURRENCY_RES,
  payload: res,
});

// ##### 24-03-21 Komarov
export const getCurrency = () => (dispatch) => {
  // ##### 24-03-21 Komarov
  process.env.NODE_ENV === "development" &&
    console.log("axios:src/Redux/actions/currency.js:getCurrency");

  dispatch({ type: GET_CURRENCY_REQ });

  // ##### 24-03-21 Komarov
  process.env.NODE_ENV === "development" &&
    console.log(
      "axios:src/Redux/actions/currency.js:getCurrency, url:",
      `${process.env.REACT_APP_SMART_URL}interface/classifier?classifier=currency`
    );
  axios
    .get(
      `${process.env.REACT_APP_SMART_URL}interface/classifier?classifier=currency`,
      {}
    )
    .then((res) => {
      dispatch(currencyResponse(res.data));
    })
    .catch((err) => {
      dispatch({ type: GET_CURRENCY_ERR, error: err });
    });
};

export const pickedCurrencyResponse = (res) => ({
  type: GET_PICKEDCURRENCY_RES,
  payload: res,
});

// ##### 24-03-21 Komarov
export const getPickedCurrencyResponse = (item) => (dispatch) => {
  dispatch({ type: GET_PICKEDCURRENCY_REQ });

  dispatch(pickedCurrencyResponse(item));
};

export const getCurrencySsr = () => (dispatch) => {
  // ##### 24-03-21 Komarov
  process.env.NODE_ENV === "development" &&
    console.log("axios:src/Redux/actions/currency.js:getCurrencySsr");

  // ##### 24-03-21 Komarov
  process.env.NODE_ENV === "development" &&
    console.log(
      "axios:src/Redux/actions/currency.js:getCurrencySsr, url:",
      `${process.env.REACT_APP_SMART_URL}interface/classifier?classifier=currency`
    );
  axios
    .get(
      `${process.env.REACT_APP_SMART_URL}interface/classifier?classifier=currency`,
      {}
    )
    .then((res) => {
      dispatch({
        type: GET_CURRENCY_RES_SSR,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({ type: GET_CURRENCY_ERR, error: err });
    });
};
