// ##### 23-10-23 Komarov
import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
// ##### 24-03-21 Komarov
import { DatePicker, Space, Select, InputNumber, Input, Alert } from "antd";
// ##### 23-10-23 Komarov
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
import parse from "html-react-parser";
import LodgingTypes from "../../StaticJsonData/LodgingTypes.json";
import { PlaceHolderStrings } from "../../Localization/placeholders.js";
import { SendForm } from "./SendForm.js";
// import cities from 'cities.json';
import CitiesTurkey from "../../StaticJsonData/CitiesTurkey.json";
// ##### 24-04-23 Komarov
import { useMessages } from "../../../../helpers/messages";
// import {getCode, getName} from 'country-list';
// ##### 23-09-14 Komarov
// import './SupplierPartnerFormCSS.css'

export const SupplierPartnerForm = ({ text }) => {
  const placeholder = PlaceHolderStrings();

  // console.log('CITIESTURKEY',CitiesTurkey)

  // ##### 23-10-04 Komarov: сохраняю начальные значения переменных для обязательных полей в переменные, чтобы сравнивать значения обязательных полей и так определять, изменились ли они.
  const countryInitialState = "";
  const cityInitialState = placeholder.placeHolderCity;
  const adrressInputInitialState = "";
  const accomTypeInitialState = "";
  const telInputInitialState = "";
  const propertyNameInitialState = undefined;
  const ownerNameInitialState = undefined;
  const positionInitialState = "";
  const emailInitialState = "";

  // ##### 23-10-04 Komarov
  const [accomType, setAccomType] = useState(accomTypeInitialState);
  const [country, setCountry] = useState(countryInitialState);
  const [city, setCity] = useState(cityInitialState);
  const [amountRooms, setAmountRooms] = useState(1);
  const [floorAmount, setFloorAmount] = useState(1);
  // ##### 23-10-04 Komarov
  const [renYear, setRenYear] = useState(""); // не обязательное поле
  const [telInput, setTelInput] = useState(telInputInitialState);
  const [adrressInput, setAddressInput] = useState(adrressInputInitialState);
  // ##### 24-03-21 Komarov
  // const [value1,setValue1] = useState(''); //free accomm refugees
  // const [value2,setValue2] = useState(''); //payable accomm for refugees
  // const [daysAmount,setDaysAmount] = useState(1); //amount of days that people accept free of charge for refugees
  // const [daysAmount_2,setDaysAmount_2] = useState(1); //amount of days that people accept at special rates for refugees
  const value1 = ""; //free accomm refugees
  const value2 = ""; //payable accomm for refugees
  const daysAmount = 1; //amount of days that people accept free of charge for refugees
  const daysAmount_2 = 1; //amount of days that people accept at special rates for refugees
  // ##### 23-10-04 Komarov
  const [propertyName, setPropertyName] = useState(propertyNameInitialState);
  const [ownerName, setOwnerName] = useState(ownerNameInitialState);
  const [position, setPosition] = useState(positionInitialState);
  const [website, setWebSite] = useState(""); // не обязательное поле
  const [email, setEmail] = useState(emailInitialState);
  const [list, setList] = useState();
  const [clicked, setClicked] = useState(false);
  // ##### 23-10-11 Komarov
  const [comments, setComments] = useState(); // не обязательное поле

  // ##### 23-10-04 Komarov: вынес вычисление значений для рендеринга (вызовы функций) в код перед рендерингом, иначе возникают ошибки при рендеринге компонентов, скрытых до нажатия кнопки.
  // ##### 24-04-23 Komarov
  const SupplierForm_PleaseChooseYourLocation = useMessages(
    "SupplierForm_PleaseChooseYourLocation"
  );
  const SupplierForm_PleaseChooseAccType = useMessages(
    "SupplierForm_PleaseChooseAccType"
  );
  const SupplierForm_Amountofrooms = useMessages("SupplierForm_Amountofrooms");
  const SupplierForm_AmountofFloors = useMessages(
    "SupplierForm_AmountofFloors"
  );
  const SupplierForm_YearRenovation = useMessages(
    "SupplierForm_YearRenovation"
  );
  const SupplierForm_YourPropertyName = useMessages(
    "SupplierForm_YourPropertyName"
  );
  const SupplierForm_ContactName = useMessages("SupplierForm_ContactName");
  const SupplierForm_ContactPosition = useMessages(
    "SupplierForm_ContactPosition"
  );
  const SupplierForm_PropertyWebsite = useMessages(
    "SupplierForm_PropertyWebsite"
  );
  const SupplierForm_Email = useMessages("SupplierForm_Email");
  const SupplierForm_Address = useMessages("SupplierForm_Address");
  const SupplierTel = useMessages("SupplierTel");
  // ##### 23-10-11 Komarov
  const Comments = useMessages("Comments");
  // const SupplierForm_ReadyToAcceptRefugees = useMessages("SupplierForm_ReadyToAcceptRefugees");
  // const SupplierForm_MaxHowManyDays = useMessages("SupplierForm_MaxHowManyDays");
  // const SupplierForm_ReadyToAcceptRates = useMessages("SupplierForm_ReadyToAcceptRates");
  const Submit = useMessages("Submit");

  // ##### 23-10-02 Komarov: отметки заполненности обязательных полей
  const [isCountrySet, setIsCountrySet] = useState("first rendered");
  const [isAccomTypeSet, setIsAccomTypeSet] = useState("first rendered");
  const [isCitySet, setIsCitySet] = useState("first rendered");
  const [isTelInputSet, setIsTelInputSet] = useState("first rendered");
  const [isAddressInputSet, setIsAddressInputSet] = useState("first rendered");
  const [isPropertyNameSet, setIsPropertyNameSet] = useState("first rendered");
  const [isOwnerNameSet, setIsOwnerNameSet] = useState("first rendered");
  const [isPositionSet, setIsPositionSet] = useState("first rendered");
  const [isEmailSet, setIsEmailSet] = useState("first rendered");
  const [captchaNotSolved, setCaptchaNotSolved] = useState(false);
  const captchaRef = useRef(null);
  const { user: currentUser } = useSelector((state) => state.auth);

  // ##### 23-10-03 Komarov: Функции для проверки правильности значения поля: если оно не отличается от начального значения, - поле заполнено неправильно и его значение отмечается ниже (в useEffect) не установленным
  // ##### 24-03-21 Komarov
  const isCountryRight = () => country !== countryInitialState;
  const isAccomTypeRight = () => accomType !== accomTypeInitialState;
  const isCityRight = () => city !== cityInitialState;
  const isTelInputRight = () => telInput !== telInputInitialState;
  const isAddressInputRight = () => adrressInput !== adrressInputInitialState;
  const isPropertyNameRight = () => propertyName !== propertyNameInitialState;
  const isOwnerNameRight = () => ownerName !== ownerNameInitialState;
  const isPositionRight = () => position !== positionInitialState;
  const isEmailRight = () => email !== emailInitialState;

  // ##### 24-03-21 Komarov: при изменении поля на значение, отличное от начального, устанавливаем отметку заполненности для соответствующего поля
  /* useEffect(() => {
      isCountryRight() && setIsCountrySet(true);
    }, [country]);
    useEffect(() => {
      isAccomTypeRight() && setIsAccomTypeSet(true);
    }, [accomType]);
    useEffect(() => {
      isCityRight() && setIsCitySet(true);
    }, [city]);
    useEffect(() => {
      isTelInputRight() && setIsTelInputSet(true);
    }, [telInput]);
    useEffect(() => {
      isAddressInputRight() && setIsAddressInputSet(true);
    }, [adrressInput]);
    useEffect(() => {
      isPropertyNameRight() && setIsPropertyNameSet(true);
    }, [propertyName]);
    useEffect(() => {
      isOwnerNameRight() && setIsOwnerNameSet(true);
    }, [ownerName]);
    useEffect(() => {
      isPositionRight() && setIsPositionSet(true);
    }, [position]);
    useEffect(() => {
      isEmailRight() && setIsEmailSet(true);
    }, [email]); */
  useEffect(() => {
    isCountryRight() && setIsCountrySet(true);
  }, [isCountryRight]);
  useEffect(() => {
    isAccomTypeRight() && setIsAccomTypeSet(true);
  }, [isAccomTypeRight]);
  useEffect(() => {
    isCityRight() && setIsCitySet(true);
  }, [isCityRight]);
  useEffect(() => {
    isTelInputRight() && setIsTelInputSet(true);
  }, [isTelInputRight]);
  useEffect(() => {
    isAddressInputRight() && setIsAddressInputSet(true);
  }, [isAddressInputRight]);
  useEffect(() => {
    isPropertyNameRight() && setIsPropertyNameSet(true);
  }, [isPropertyNameRight]);
  useEffect(() => {
    isOwnerNameRight() && setIsOwnerNameSet(true);
  }, [isOwnerNameRight]);
  useEffect(() => {
    isPositionRight() && setIsPositionSet(true);
  }, [isPositionRight]);
  useEffect(() => {
    isEmailRight() && setIsEmailSet(true);
  }, [isEmailRight]);

  // ##### 23-10-04 Komarov: функция для помечания полей обязательными для заполнения (она добавляет звёздочку к названию поля)
  const markReuired = (labelText) => `${labelText} *`;

  // ##### 23-08-30 Komarov
  const locale = useSelector((state) => state.language);
  // ##### 24-03-21 Komarov
  // const yesText = useMessages && useMessages("Yes") || 'Yes';
  // ##### 24-03-21 Komarov
  // const noText = useMessages && useMessages("No") || 'No';
  const { Option } = Select;
  const { TextArea } = Input;

  const selectCountry = (value) => {
    setCountry(value);
    setCity(placeholder.placeHolderCity);
  };

  const onChangeCity = (value) => {
    setCity(value);
  };

  const onSearchCity = (value) => {
    setCity(value);
  };

  const selectingAccomTypes = (value) => {
    setAccomType(value);
  };

  const SelectRoomAmount = (value) => {
    setAmountRooms(value);
  };

  const selectPostion = (value) => {
    setPosition(value);
  };

  const SelFloors = (value) => {
    setFloorAmount(value);
  };

  // ##### 24-03-21 Komarov
  const SelYear = (_date, dateString) => {
    setRenYear(dateString);
  };

  const SelNameProperty = (e) => {
    setPropertyName(e.target.value);
  };

  const SelOwnerName = (e) => {
    setOwnerName(e.target.value);
  };

  const SelTelephone = (e) => {
    setTelInput(e.target.value);
  };

  const SelAddress = (e) => {
    setAddressInput(e.target.value);
  };

  const InputEmail = (e) => {
    setEmail(e.target.value);
  };

  const SelWebSite = (e) => {
    setWebSite(e.target.value);
  };

  // ##### 24-03-21 Komarov
  /* const onChange1 = e => {
        console.log('radio1 checked', e.target.value);
        setValue1(e.target.value);
    }

    const onChange2 = e => {
          setValue2(e.target.value)
    }

    const SelDays = (value) =>{
        setDaysAmount(value)
    }

    // ##### 24-03-21 Komarov
    const SelDays_2 = (value) =>{
        setDaysAmount_2(value)
    } */

  // ##### 23-10-11 Komarov
  const AddComments = (e) => {
    setComments(e.target.value);
  };

  const Complete = () => {
    // ##### 23-10-04 Komarov: при нажатии на кнопку "Submit" проверяем значения обязательных полей и, если значение не отличается от начального, устанавливаем признак заполненности этого поля в false, чтобы использовать этот признак для установки статуса соотв. поля на странице в error (поле становится обведено красной полоской). При этом функцию отправки электронного письма не выполняем, а выходим из неё (return).
    if (!isCountryRight()) {
      setIsCountrySet(false);
      return;
    }
    if (!isAccomTypeRight()) {
      setIsAccomTypeSet(false);
      return;
    }
    if (!isCityRight()) {
      setIsCitySet(false);
      return;
    }
    if (!isTelInputRight()) {
      setIsTelInputSet(false);
      return;
    }
    if (!isAddressInputRight()) {
      setIsAddressInputSet(false);
      return;
    }
    if (!isPropertyNameRight()) {
      setIsPropertyNameSet(false);
      return;
    }
    if (!isOwnerNameRight()) {
      setIsOwnerNameSet(false);
      return;
    }
    if (!isPositionRight()) {
      setIsPositionSet(false);
      return;
    }
    if (!isEmailRight()) {
      setIsEmailSet(false);
      return;
    }

    // ##### 24-03-21 Komarov: Если приложение работает онлайн, установлены соотв. переменные окружения, тогда проверяем успешное прохождение CAPTCHA, иначе действуем без учёта прохождения CAPTCHA
    if (
      process.env.REACT_APP_IS_RECAPTCHA_ONLINE &&
      process.env.REACT_APP_IS_RECAPTCHA_ONLINE === "true" &&
      process.env.REACT_APP_RECAPTCHA_SITE &&
      process.env.REACT_APP_RECAPTCHA_SERVER
    ) {
      // ##### 23-10-23 Komarov
      const token = captchaRef.current.getValue();
      captchaRef.current.reset();
      setCaptchaNotSolved(true);

      // ##### 23-10-20 Komarov
      axios
        .post(
          `${process.env.REACT_APP_URL}/token_check`,
          { token },
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        )
        .then((res) => {
          if (res.data === "Human 👨 👩") {
            setCaptchaNotSolved(false);
            const newList = {
              country: country,
              city: city,
              accomType: accomType,
              amountRooms: amountRooms,
              property_name: propertyName,
              owner_name: ownerName,
              position: position, ///new
              website: website,
              floorAmount: floorAmount,
              renYear: renYear,
              tel: telInput,
              // ##### 23-10-04 Komarov
              email: email,
              address: adrressInput,
              freeAccom: value1,
              freeAccomDays: daysAmount,
              discAccom: value2,
              discAccomDays: daysAmount_2,
              // ##### 23-10-11 Komarov
              comments: comments,
            };
            setList(newList);
            setClicked(true);
          } else {
            setCaptchaNotSolved(true);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      const newList = {
        country: country,
        city: city,
        accomType: accomType,
        amountRooms: amountRooms,
        property_name: propertyName,
        owner_name: ownerName,
        position: position, ///new
        website: website,
        floorAmount: floorAmount,
        renYear: renYear,
        tel: telInput,
        // ##### 23-10-04 Komarov
        email: email,
        address: adrressInput,
        freeAccom: value1,
        freeAccomDays: daysAmount,
        discAccom: value2,
        discAccomDays: daysAmount_2,
        // ##### 23-10-11 Komarov
        comments: comments,
      };
      setList(newList);
      setClicked(true);
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
  };

  // const Cities = cities.filter((item)=>{
  //     return item.country === 'UA'
  // })

  // console.log('CITIES','hi',Cities)

  return (
    <form
      className="supplierWrapper"
      onSubmit={onSubmit}
      data-component="SupplierPartnerForm"
    >
      <h3>{parse(text)}</h3>

      <div className="blocks">
        <span>
          {
            // ##### 23-10-04 Komarov
            markReuired(SupplierForm_PleaseChooseYourLocation)
          }
        </span>
        <Select
          //    defaultValue={TurkeyCitiesList.map((item,index,array)=>array[0].name)}
          onChange={selectCountry}
          placeholder={placeholder.placeHolderCountry}
          bordered={true}
          size="middle"
          style={{ minWidth: "5vw", marginRight: "2vw" }}
          // ##### 23-10-03 Komarov: если поле не заполнено, оно обводится красной полоской
          // ##### 23-11-08 Komarov: TODO: Взять перечень стран из Смарта
          status={!isCountrySet && "error"}
        >
          <Option value={"Moldova"} key={"MD"}>
            {"Moldova"}
          </Option>
          <Option value={"Turkey"} key={"TR"}>
            {"Turkey"}
          </Option>
          <Option value={"Ukraine"} key={"UA"}>
            {"Ukraine"}
          </Option>
        </Select>
        {/* ##### 23-10-03 Komarov: оповещение пользователя о необходимости заполнить это поле */}
        {!isCountrySet && (
          <Alert
            message={SupplierForm_PleaseChooseYourLocation}
            type="error"
            showIcon
            closable
            onClose={() => setIsCountrySet("first rendered")}
          />
        )}

        <Select
          // showSearch
          placeholder={placeholder.placeHolderCity}
          optionFilterProp="children"
          onChange={onChangeCity}
          // onSearch={onSearchCity}
          onSelect={onSearchCity}
          // defaultValue={city}
          // searchValue={city}
          value={city}
          style={{ minWidth: "10vw", marginRight: "2vw" }}
          // ##### 23-10-03 Komarov: если поле не заполнено, оно обводится красной полоской
          status={!isCitySet && "error"}
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {
            // ##### 24-03-21 Komarov
            //  cities&&cities.map((item)=>{
            //   if(country === item.country){
            CitiesTurkey?.map((item) => {
              if (country === item.country) {
                return (
                  <Option
                    // className='AvailableOptions'
                    value={item.name}
                    key={item.id}
                  >
                    {item.name}
                  </Option>
                );
              }
            })
            //   }
          }
        </Select>
        {/* ##### 23-10-03 Komarov: оповещение пользователя о необходимости заполнить это поле */}
        {!isCitySet && (
          <Alert
            message={SupplierForm_PleaseChooseYourLocation}
            type="error"
            showIcon
            closable
            onClose={() => setIsCitySet("first rendered")}
          />
        )}
      </div>

      <div className="blocks_2">
        <span>
          {
            // ##### 23-10-04 Komarov
            markReuired(SupplierForm_PleaseChooseAccType)
          }
        </span>
        <Select
          //    defaultValue={lodgingTypes[0]}
          onChange={selectingAccomTypes}
          bordered={true}
          placeholder={placeholder.placeHolderSelect}
          //    size='middle'
          style={{ minWidth: "5vw" }}
          // ##### 23-10-03 Komarov: если поле не заполнено, оно обводится красной полоской
          status={!isAccomTypeSet && "error"}
        >
          <>
            {
              // ##### 24-03-21 Komarov
              LodgingTypes?.map((item) => {
                if (item.lang === locale) {
                  return item.type.map((item1) => {
                    console.log("ITEM1", item1, "hi kate");
                    return (
                      <Option value={item1} key={item1}>
                        {item1}
                      </Option>
                    );
                  });
                }
              })
            }
          </>
        </Select>
        {/* ##### 23-10-03 Komarov: оповещение пользователя о необходимости заполнить это поле */}
        {!isAccomTypeSet && (
          <Alert
            message={SupplierForm_PleaseChooseAccType}
            type="error"
            showIcon
            closable
            onClose={() => setIsAccomTypeSet("first rendered")}
          />
        )}

        <span>
          {
            // ##### 23-10-04 Komarov
            SupplierForm_Amountofrooms
          }
        </span>
        <Space>
          <InputNumber
            min={1}
            max={100000}
            defaultValue={1}
            onChange={SelectRoomAmount}
          />
        </Space>
      </div>

      <div className="blocks_2">
        <span>
          {
            // ##### 23-10-04 Komarov
            SupplierForm_AmountofFloors
          }
        </span>
        <Space>
          <InputNumber
            min={1}
            max={100000}
            defaultValue={1}
            onChange={SelFloors}
          />
        </Space>

        <span>
          {
            // ##### 23-10-04 Komarov
            SupplierForm_YearRenovation
          }
        </span>
        <Space
          direction="vertical"
          style={{ background: "white", marginRight: "2vw" }}
        >
          <DatePicker
            size="middle"
            onChange={SelYear}
            picker="month"
            //  format={dateFormat}
            placeholder={placeholder.placeHolderMonth}
            bordered={false}
            className={"SupplierMonth"}
            //  disabledDate={disabledDate}
          />
        </Space>
      </div>

      <div className="blocks_3">
        <span>
          {
            // ##### 23-10-04 Komarov
            markReuired(SupplierForm_YourPropertyName)
          }
        </span>
        <TextArea
          rows={1}
          // ##### 23-10-16 Komarov
          // style={{ marginTop: '1vh', marginBottom: '1vh' }}
          style={{ marginTop: "0.5vh", marginBottom: "0.5vh" }}
          onChange={SelNameProperty}
          value={propertyName}
          // ##### 23-10-03 Komarov: если поле не заполнено, оно обводится красной полоской
          status={!isPropertyNameSet && "error"}
        />
        {/* ##### 23-10-03 Komarov: оповещение пользователя о необходимости заполнить это поле */}
        {!isPropertyNameSet && (
          <Alert
            message={SupplierForm_YourPropertyName}
            type="error"
            showIcon
            closable
            onClose={() => setIsPropertyNameSet("first rendered")}
          />
        )}

        <span>
          {
            // ##### 23-10-04 Komarov
            markReuired(SupplierForm_ContactName)
          }
        </span>
        <TextArea
          rows={1}
          // ##### 23-10-16 Komarov
          // style={{ marginTop: '1vh', marginBottom: '1vh' }}
          style={{ marginTop: "0.5vh", marginBottom: "0.5vh" }}
          onChange={SelOwnerName}
          value={ownerName}
          // ##### 23-10-03 Komarov: если поле не заполнено, оно обводится красной полоской
          status={!isOwnerNameSet && "error"}
        />
        {/* ##### 23-10-03 Komarov: оповещение пользователя о необходимости заполнить это поле */}
        {!isOwnerNameSet && (
          <Alert
            message={SupplierForm_ContactName}
            type="error"
            showIcon
            closable
            onClose={() => setIsOwnerNameSet("first rendered")}
          />
        )}
      </div>

      <div className="blocks_5">
        <span>
          {
            // ##### 23-10-04 Komarov
            markReuired(SupplierForm_ContactPosition)
          }
        </span>
        <Select
          onChange={selectPostion}
          bordered={true}
          placeholder={placeholder.placeHolderSelect}
          style={{ width: "13vw" }}
          // ##### 23-10-03 Komarov: если поле не заполнено, оно обводится красной полоской
          status={!isPositionSet && "error"}
        >
          <>
            {
              // ##### 24-03-21 Komarov
              LodgingTypes?.map((item) => {
                if (item.lang === locale) {
                  return item.ownership_types.map((item1) => {
                    return (
                      <Option value={item1} key={item1}>
                        {item1}
                      </Option>
                    );
                  });
                }
              })
            }
          </>
        </Select>
        {/* ##### 23-10-03 Komarov: оповещение пользователя о необходимости заполнить это поле */}
        {!isPositionSet && (
          <Alert
            message={SupplierForm_ContactPosition}
            type="error"
            showIcon
            closable
            onClose={() => setIsPositionSet("first rendered")}
          />
        )}

        <span>
          {
            // ##### 23-10-04 Komarov
            SupplierForm_PropertyWebsite
          }
        </span>
        <Input
          style={{ width: "80%", background: "white" }}
          onChange={SelWebSite}
          value={website}
        />

        <span>
          {
            // ##### 23-10-04 Komarov
            markReuired(SupplierForm_Email)
          }
        </span>
        <Input
          style={{ width: "80%", background: "white" }}
          onChange={InputEmail}
          value={email}
          // ##### 23-10-03 Komarov: если поле не заполнено, оно обводится красной полоской
          status={!isEmailSet && "error"}
        />
        {/* ##### 23-10-03 Komarov: оповещение пользователя о необходимости заполнить это поле */}
        {!isEmailSet && (
          <Alert
            message={SupplierForm_Email}
            type="error"
            showIcon
            closable
            onClose={() => setIsEmailSet("first rendered")}
          />
        )}
      </div>

      <div className="blocks_3">
        <span>
          {
            // ##### 23-10-04 Komarov
            markReuired(SupplierForm_Address)
          }
        </span>
        <TextArea
          // ##### 23-09-06 Komarov
          // rows={3}
          rows={1}
          // ##### 23-10-16 Komarov
          // style={{ marginTop: '1vh', marginBottom: '1vh' }}
          style={{ marginTop: "0.5vh", marginBottom: "0.5vh" }}
          onChange={SelAddress}
          value={adrressInput}
          // ##### 23-10-03 Komarov: если поле не заполнено, оно обводится красной полоской
          status={!isAddressInputSet && "error"}
        />
        {/* ##### 23-10-03 Komarov: оповещение пользователя о необходимости заполнить это поле */}
        {!isAddressInputSet && (
          <Alert
            message={SupplierForm_Address}
            type="error"
            showIcon
            closable
            onClose={() => setIsAddressInputSet("first rendered")}
          />
        )}

        <span>
          {
            // ##### 23-10-04 Komarov
            markReuired(SupplierTel)
          }
        </span>
        <Input
          style={{ width: "100%", background: "white" }}
          onChange={SelTelephone}
          value={telInput}
          // ##### 23-10-03 Komarov: если поле не заполнено, оно обводится красной полоской
          status={!isTelInputSet && "error"}
        />
        {/* ##### 23-10-03 Komarov: оповещение пользователя о необходимости заполнить это поле */}
        {!isTelInputSet && (
          <Alert
            message={SupplierTel}
            type="error"
            showIcon
            closable
            onClose={() => setIsTelInputSet("first rendered")}
          />
        )}
      </div>

      {/* ##### 23-10-04 Komarov - start: Закомментировал поля про беженцев */}
      {/* <div className="blocks_4">
           <span>
           {
              // ##### 23-09-26 Komarov
              SupplierForm_ReadyToAcceptRefugees
              } 
           </span>
           <Radio.Group
          //  ##### 23-09-05 Komarov
                // options={['Yes','No']}
                options={[yesText, noText]}
                onChange={onChange1}
                value={value1}
                optionType="button"
                buttonStyle="solid"
            />
            <span>
            {
              // ##### 23-09-26 Komarov
              SupplierForm_MaxHowManyDays
              } 
            </span>
            <Space>
               <InputNumber min={1} max={100000} defaultValue={1} onChange={SelDays} />
           </Space>
        </div>

        <div className="blocks_4">
           <span>
           {
              // ##### 23-09-26 Komarov
              SupplierForm_ReadyToAcceptRates
              } 
           </span>
           <Radio.Group
          //  ##### 23-09-05 Komarov
                // options={['Yes','No']}
                options={[yesText, noText]}
                onChange={onChange2}
                value={value2}
                optionType="button"
                buttonStyle="solid"
            />
             <span>
             {
              // ##### 23-09-26 Komarov
              SupplierForm_MaxHowManyDays
              } 
             </span>
            <Space>
                   <InputNumber min={1} max={100000} defaultValue={1} onChange={SelDays_2} />
            </Space>
        </div> */}
      {/* ##### 23-10-04 Komarov - end */}

      {/* ##### 23-10-16 Komarov */}
      {/* <div className='groupTravelblocks_3'> */}
      <div className="groupTravelFormComments">
        <span>
          {
            // ##### 23-10-04 Komarov
            Comments
          }
        </span>
        <TextArea
          // ##### 23-09-06 Komarov
          // rows={3}
          rows={1}
          // ##### 23-10-16 Komarov
          // style={{ marginTop: '1vh', marginBottom: '1vh' }}
          style={{ marginTop: "0.5vh", marginBottom: "0.5vh" }}
          onChange={AddComments}
          value={comments}
        />
      </div>
      {/* ##### 23-10-23 Komarov: TODO: взять перевод из Смарта */}
      {/* ##### 23-10-23 Komarov: Если пользователь не зарегистрирован, и в переменных окружения есть переменная REACT_APP_IS_RECAPTCHA_ONLINE=true, то отображаем тест reCAPTCHA */}
      {
        // ##### 24-03-21 Komarov
        !currentUser ||
          (currentUser &&
            parseInt(currentUser.user_id) === 1426 &&
            process.env.REACT_APP_IS_RECAPTCHA_ONLINE &&
            process.env.REACT_APP_IS_RECAPTCHA_ONLINE === "true" &&
            process.env.REACT_APP_RECAPTCHA_SITE &&
            process.env.REACT_APP_RECAPTCHA_SERVER && (
              <div className="groupTravelFormComments">
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_RECAPTCHA_SITE}
                  // onChange={handleRecaptchaChange}
                  ref={captchaRef}
                />
                {/* ##### 23-10-23 Komarov Komarov Komarov: TODO: взять перевод из Смарта */}
                {captchaNotSolved && (
                  <Alert variant={"danger"}>Please solve a CAPTCHA!</Alert>
                )}
              </div>
            ))
      }

      {/* ##### 24-03-21 Komarov */}
      <button type="submit" className="SupplierSubmitButton" onClick={Complete}>
        {
          // ##### 23-10-04 Komarov
          Submit
        }
      </button>
      {clicked === true ? <SendForm list={list} /> : null}
    </form>
  );
};
