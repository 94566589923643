// ##### 24-06-14 Komarov
import React, { useState, useContext } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import uniqueId from "lodash.uniqueid";
import moment from "moment";
import { MinRate } from "../MinRate";
import { useMessages } from "../../../../../helpers/messages";
import { ItemObjContext } from "./ItemObj";

// ##### 23-03-01 Komarov
export const DivTag = ({ className }) => {
  const { tariff, tour_name, pickedCurrency } = useContext(ItemObjContext);
  // ##### 24-04-23 Komarov
  const ViewDetailsButton = useMessages("ViewDetailsButton");

  // ##### 23-12-27 Komarov
  const locale = useSelector((state) => state.language);

  const location = useLocation();
  const history = useHistory();

  //фильтрую объект с тарифами, датами так, чтобы вывести первую встречающую дату каждого вида (к примеру, у меня пришло
  //в массиве 10 раз дата 2021-03-03, 3 раза дата 2021-03-04, 4 раза дата 2021-03-05 - я вывожу новый массив, где будет
  //объект в массиве, где первый раз встречается 2021-03-03, потом объект, где встречается 2021-03-04 первый раз, и т.д.)
  //это нужно, чтобы вывести список периодов для выбора
  const filtered_tour_dates = tariff.rate.filter(
    (item1, index, array) => array.findIndex((t) => t.date === item1.date) === index
  );

  // console.log('[FILTERED_TOUR_DATES] : ' , filtered_tour_dates)
  const [selection, setSelection] = useState(filtered_tour_dates[0].date);
  const [detailsList, setDetailsList] = useState([]);
  // const [detailsList, setdetailsList] = useState([{}]);
  // console.log('[ItemObj TARIFF]', tariff)
  // console.log('[ItemObj searchResults]', searchResults)
  // console.log('[ItemObj HISTORY]', history)
  function selectedPeriod(e) {
    // console.log('SELECTED : ' , e.target.value)
    setSelection(e.target.value);
  }

  const addToDetails = (e) => {
    e.preventDefault();

    const outline = {
      id: tariff.tour_id,
      date: selection,
    };

    setDetailsList([outline]);
    // console.log('TEST TEST', e.view.history)
    // console.log('[DETAILS LIST] : ' , detailsList)
    // ##### 23-11-09 Komarov
    // const route_query = `${location.search},tour_name=${tour_name},tour_id=${tariff.tour_id},selection=${selection}`
    const route_query = `${location.search}&tour_name=${tour_name}&tour_id=${tariff.tour_id}&selection=${selection}`;
    history.push(`/${locale}/tour_details/${route_query}`, [
      ...detailsList,
      outline,
    ]);
  };
  return (
    <div className={className} data-component="DivTag">
      <div className="availablePeriods">
        <div style={{ fontSize: "2vh", fontWeight: "bold" }}>
          Available dates :{" "}
        </div>
        <select
          value={selection}
          onChange={selectedPeriod}
          style={{ fontSize: "2vh", color: "#001959" }}
        >
          <>
            {
              // ##### 24-03-21 Komarov
              filtered_tour_dates?.map((filter) => {
                return (
                  <option
                    value={filter.date}
                    //  ##### 23-04-12 Komarov
                    key={uniqueId()}
                  >
                    {filter.date} --{" "}
                    {moment(filter.date)
                      .add(tariff.duration, "days")
                      .format("YYYY-MM-DD")}{" "}
                    ({tariff.duration} days)
                  </option>
                );
              })}
          </>
        </select>
      </div>

      <div className="minimumRate">
        <div style={{ fontSize: "10px", color: "grey", fontStyle: "italic" }}>
          {selection}
        </div>
        <MinRate
          selection={selection}
          datesArray={tariff.rate}
          pickedCurrency={pickedCurrency} />
        <button
          // ##### 24-03-21 Komarov
          type="submit"
          className="availableButtonSecondPage"
          onClick={addToDetails}
        >
          {
            // ##### 23-10-04 Komarov
            ViewDetailsButton}
        </button>
        <div style={{ fontSize: "10px", color: "grey", fontStyle: "italic" }}>
          {tariff.tour_id}
        </div>
      </div>
    </div>
  );
};
