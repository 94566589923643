// ##### 24-09-09 Komarov
import React, { useEffect, createContext, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { changeLang } from "../../../../../Redux/actions/locale";
import {
  getCurrency,
  // ##### 24-07-30 Komarov
  // getPickedCurrencyResponse,
} from "../../../../../Redux/actions/currency";
import { ValidateQuery } from "../../../Helpers/helper";
import { ContentPages } from "../../ContentPages";
// ##### 24-04-23 Komarov
import { useMessages } from "../../../../../helpers/messages";
// ##### 23-10-24 Komarov
import { HeaderWrapperMain } from "./HeaderWrapperMain";
// ##### 23-10-10 Komarov
export const TopMenuContext = createContext();

export const TopMenu = () => {
  // ##### 24-04-23 Komarov
  const Home_icon = useMessages("Home_icon");
  const Operations_icon = useMessages("Operations_icon");
  const Mail_icon = useMessages("Mail_icon");
  const Phone_icon = useMessages("Phone_icon");
  const ArkturFrontPageSloganMessage = useMessages(
    "ArkturFrontPageSloganMessage"
  );
  // ##### 23-12-27 Komarov
  const [paxListOpen, setPaxListOpen] = useState(false);
  const toggleContextPaxListOpen = (value) => setPaxListOpen(value);
  let path;
  const location = useLocation();
  if (location.search) {
    path = ValidateQuery(location);
  } else path = { selected_currency: "UAH", default: true };

  // console.log('[A_PATH]',path)
  // console.log('uniqueid',uniqueId());

  const dispatch = useDispatch();
  // ##### 23-06-19 Komarov
  // SetAppData('all');

  // ##### 24-07-30 Komarov
  /* useEffect(() => {
    dispatch(getPickedCurrencyResponse(path.selected_currency));
  }, []); */

  const lang = useSelector((state) => state.language);
  // ##### 24-07-25 Komarov
  const currencies = useSelector((state) => state.currency.currencies);
  const pagesFromState = useSelector((state) => state.pages.pages);

  // Думал, что язык работает и без этого запроса
  useEffect(() => {
    dispatch(changeLang(lang));
    // ##### 23-04-12 Komarov
  }, [lang, dispatch]);
  // Запрашиваем валюту из Смарта для сайта
  useEffect(() => {
    // ##### 24-07-25 Komarov
    (process.env.NODE_ENV === "development" &&
      currencies.length > 0) ||
      console.log(
        "axios:src/Components/Pages/PageComponents/ResponsiveHeader/Header.js:getCurrency()"
      );
    // dispatch(getCurrency());
    currencies.length > 0 ||
      dispatch(getCurrency());
    // ##### 23-04-12 Komarov
  }, [dispatch]);

  // ##### 24-07-25 Komarov
  const pages =
    (pagesFromState &&
      pagesFromState.length > 0) ||
    ContentPages(lang);

  // ##### 23-04-12 Komarov
  /* const pagesAllLanguagesFromState = useSelector(state => state.pages.pagesAllLanguages);
  const pagesFromState = pagesAllLanguagesFromState &&
    pagesAllLanguagesFromState.length > 0 &&
    pagesAllLanguagesFromState[lang]; */

  // ##### 23-04-08 Komarov
  // const pagesFromLocalStorage = useMessages();

  // const sitePageType = pagesFromLocalStorage.filter((item, index, array) => {
  // ##### 24-06-20 Komarov
  /* const sitePageType = Object.values(useMessages())
    .filter((item, index, array) => {
      if (item.sitepage_type_id !== null) {
        return (
          array.findIndex(
            (t) =>
              parseInt(t.sitepage_type_id) === parseInt(item.sitepage_type_id)
          ) === index
        );
      }
    })
    .map((item1) => {
      return {
        // ##### 24-03-21 Komarov
        sitepage_type_id: item1.sitepage_type_id,
        sitepage_type_name: item1.sitepage_type_name,
        sitepage_region_id: item1.sitepage_region_id,
        sitepage_region_name: item1.sitepage_region_name,
      };
    }); */

  // const sitePageRegion = pagesFromLocalStorage.filter((item, index, array) => {
  // ##### 24-06-20 Komarov
  /* const sitePageRegion = Object.values(useMessages())
    .filter((item, index, array) => {
      if (item.sitepage_region_id !== null) {
        return (
          array.findIndex(
            (t) =>
              parseInt(t.sitepage_region_id) ===
              parseInt(item.sitepage_region_id)
          ) === index
        );
      }
    })
    .map((item1) => {
      return {
        // ##### 24-03-21 Komarov
        sitepage_region_id: item1.sitepage_region_id,
        sitepage_region_name: item1.sitepage_region_name,
      };
    }); */

  // ##### 24-06-20 Komarov
  const sitePages = Object.values(useMessages());
  let sitePagesObj = {};
  for (const sitePage of sitePages) {
    if (!sitePagesObj[sitePage.sitepage_region_name]) {
      sitePagesObj[sitePage.sitepage_region_name] = {};
    }
    if (!sitePagesObj[sitePage.sitepage_region_name][sitePage.sitepage_type_name]) {
      sitePagesObj[sitePage.sitepage_region_name][sitePage.sitepage_type_name] = {
        sitepage_region_id: sitePage.sitepage_region_id,
        sitepage_region_name: sitePage.sitepage_region_name,
        sitepage_type_id: sitePage.sitepage_type_id,
        sitepage_type_name: sitePage.sitepage_type_name
      };
    }
  }

  // ##### 24-09-09 Komarov
  const topMenuContextValue = useMemo(() => ({
    // ##### 24-06-20 Komarov
    // sitePageType,
    // sitePageRegion,
    sitePagesObj,
    Mail_icon,
    Phone_icon,
    Home_icon,
    Operations_icon,
    ArkturFrontPageSloganMessage,
    pages,
    lang,
    path,
    location,
    paxListOpen,
    toggleContextPaxListOpen,
  }), [
    // ##### 24-09-11 Komarov
    // sitePageType,
    // sitePageRegion,
    sitePagesObj,
    Mail_icon,
    Phone_icon,
    Home_icon,
    Operations_icon,
    ArkturFrontPageSloganMessage,
    pages,
    lang,
    path,
    location,
    paxListOpen,
    toggleContextPaxListOpen,
  ]);

  return (
    <TopMenuContext.Provider
      value={topMenuContextValue}
    >
      <HeaderWrapperMain />
    </TopMenuContext.Provider>
  );
};
