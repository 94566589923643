// ##### 23-09-14 Komarov
import React, { useState, useEffect } from "react";
// ##### 24-02-28 Komarov
import { useSelector } from "react-redux";
import axios from "axios";
// ##### 24-03-21 Komarov
// import {useLocation} from 'react-router-dom'
// ##### 23-04-12 Komarov
import uniqueId from "lodash.uniqueid";
import { PayRotaryRegistration } from "../../Library/LiqPay/PayRotaryRegistration";
import { TermsConditions } from "../BookingForm/TermsConditions";
import { Checkbox } from "antd";

// ##### 24-03-21 Komarov
// import { ValidateQuery} from '../Helpers/helper';
// ##### 24-04-23 Komarov
import { useMessages } from "../../../helpers/messages";

// ##### 23-09-14 Komarov
// import './RegistrationRotaryCSS.css'
// import '../BookingForm/ClientDetailsCSS.css'

export const RegistrationRotary = () => {
  // ##### 24-05-31 Komarov
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  // ##### 24-04-23 Komarov
  const RotaryRegSummary = useMessages("RotaryRegSummary");
  const AgreeTermsCond = useMessages("AgreeTermsCond");

  // ##### 24-03-21 Komarov
  // const location = useLocation();
  // console.log('ROTARY_REGISTRATION',location)

  const [termsTicked, setTermsTicked] = useState(false);
  const [readTerms, setReadTerms] = useState(false);
  // ##### 23-03-29 Komarov
  const [clicked /* setClicked */] = useState(false);

  // ##### 24-03-21 Komarov
  /* let path ;
    if(location.search){
        path = ValidateQuery(location)
        } */
  // console.log('ROTARY_REGISTRATION',location)
  // console.log('ROTARY_REGISTRATION: path:',path)
  // ##### 24-02-28 Komarov
  const { user: currentUser } = useSelector((state) => state.auth);
  // const user_id = path.user_id
  const user_id = currentUser.user_id;
  const [userData, setUserData] = useState([]);

  const AgreedFunc = () => {
    setTermsTicked(!termsTicked);
  };

  const ReadTermsFunc = () => {
    setReadTerms(!readTerms);
  };

  const closeTermsConditions = () => {
    setReadTerms(false);
  };

  useEffect(() => {
    // ##### 24-03-21 Komarov
    const array = [];
    const ActionRQ = {
      username: process.env.REACT_APP_SMART_USERNAME,
      password: process.env.REACT_APP_SMART_PASSWORD,
      user_id: user_id,
      action: "GetRotaryUserRQ",
    };
    axios
      .post(
        `${process.env.REACT_APP_SMART_URL}interface/xmlsubj/`,
        JSON.stringify({ ActionRQ })
      )
      .then((response) => {
        // console.log('ROTARY_REGISTRATION: RESPONSE', response)
        // setUserData(response.data.client_data[0]) //data[0].data.client_data
        // ##### 24-03-21 Komarov
        for (const key in response.data[0].data.client_data[0]) {
          const obj = {
            form_key: key,
            form_value: response.data[0].data.client_data[0][key],
          };
          array.push(obj);
        }
        // setUserData(response.data[0].data.client_data[0])
        setUserData(array);
      })
      .catch((error) => {
        // ##### 24-03-21 Komarov
        console.log("[axios error]: ", error);
      });
    // ##### 23-04-12 Komarov
  }, [user_id]);
  // console.log('ROTARY_REGISTRATION: userData:',userData)

  return (
    <div
      style={{ marginTop: "3vh", marginBottom: "4vh" }}
      data-component="RegistrationRotary"
    >
      <h2
        style={{
          color: "darkblue",
          fontStyle: "Arial Narrow",
          fontSize: "35px",
          textAlign: "center",
          fontWeight: "bold",
          marginBottom: "4vh",
        }}
      >
        {
          // ##### 23-10-04 Komarov
          RotaryRegSummary
        }
      </h2>

      {userData.map((item) => {
        return (
          // ##### 23-10-04 Komarov
          <div key={uniqueId()} className="RegistrationForm">
            <div className="Form_Key">{item.form_key.toUpperCase()}</div>
            <div className="Form_Value">{item.form_value}</div>
          </div>
        );
      })}

      <div className="WrapperTermsConditions Rotary">
        <Checkbox
          onChange={AgreedFunc}
          defaultChecked={false}
          disabled={clicked === false ? false : true}
          style={{
            textAlign: "center",
            marginRight: "2vw",
            marginTop: "auto",
            marginBottom: "auto",
            border: "2px solid blue",
            borderRadius: "60px",
            background: "lightblue",
            padding: "10px",
          }}
        />
        <div
          className="TermsConditions Registration"
          onClick={ReadTermsFunc}
          //   ##### 24-03-21 Komarov
          onKeyDown={(e) => {
            if (e.key === "Enter" || e.key === " ") {
              ReadTermsFunc();
            }
          }}
        >
          {
            // ##### 23-10-04 Komarov
            AgreeTermsCond
          }
        </div>
      </div>
      {readTerms === true ? (
        <div style={{ marginLeft: "6vw" }}>
          <TermsConditions
            readTerms={readTerms}
            closeTermsConditions={closeTermsConditions}
          />
        </div>
      ) : null}
      {termsTicked === true ? (
        <PayRotaryRegistration userData={userData} />
      ) : null}
    </div>
  );
};
